import { type IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { Ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"
import type { RouteInformation } from "./Navigation"
import Navigation from "./Navigation"

interface Props {
	routes: Record<string, RouteInformation>
	icon: IconDefinition
	profileNav: boolean
	navOpen: Ref<boolean>
	toggleNav(): void
}

const buttonStyles = css`
	border: none;
	background: none;
	color: var(--color-tenet-darkgrey);
	transition: 0.2s;
	position: relative;
	z-index: 999;

	&:hover {
		color: var(--color-tenet-orange);
	}
`

const NavWithToggle: ReactiveComponent<Props> = (props) => {
	return () => (
		<nav style={props.navOpen.value ? { zIndex: 999 } : undefined}>
			<button type="button" class={buttonStyles} onClick={() => props.toggleNav()}>
				<Icon icon={props.icon} />
			</button>
			{props.navOpen.value && (
				<>
					<div
						class="backdrop"
						onClick={() => props.toggleNav()}
						onKeypress={(e) => e.key === "Enter" && props.toggleNav()}
						role="button"
						tabindex={0}
					/>
					<Navigation routes={props.routes} profileNav={props.profileNav} />
				</>
			)}
		</nav>
	)
}

export default defineComponent(NavWithToggle, {
	routes: requiredProp(Object),
	icon: requiredProp(Object),
	profileNav: requiredProp(Boolean),
	navOpen: requiredProp(Object),
	toggleNav: requiredProp(Function),
})
