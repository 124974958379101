import { css } from "vite-css-in-js"
import { ref } from "vue"
import { defineComponent, renderSlot, type NoProps, type ReactiveComponent } from "vue-utils"

const bladeStyles = css`
	--bladeWidth: 600px;

	position: fixed;
	inset: 0;
	z-index: 999;
	pointer-events: none;

	.blade-bg {
		position: absolute;
		inset: 0;
		background: rgba(0, 0, 0, 0.4);
		opacity: 0;
		transition: opacity 0.3s;
	}

	.blade-content {
		width: var(--bladeWidth);
		background: var(--color-white);
		position: absolute;
		top: 0;
		bottom: 0;
		right: calc(var(--bladeWidth) * -1);
		box-shadow: -10px 0 20px 10px rgba(var(--color-tenet-darkgrey-rgb), 0.2);
		transition: right 0.3s;
		padding: 2em;

		& [class^="tile"]:not(.tile-heading) {
			border: 1px solid rgba(var(--color-tenet-darkgrey-rgb), 0.3);
		}
	}

	&.active {
		pointer-events: all;

		.blade-bg {
			opacity: 1;
		}

		.blade-content {
			right: 0;
		}
	}
`

const activeClass = "active"

export type BladeState = "open" | "closed"

const bladeRef = ref<HTMLDivElement>()
const bladeState = ref<BladeState>("closed")

export function toggleBlade() {
	const blade = bladeRef.value as HTMLDivElement
	if (!blade) {
		return
	}

	if (blade.classList.contains(activeClass)) close(blade)
	else open(blade)
}

export function closeBlade() {
	const blade = bladeRef.value as HTMLDivElement
	if (!blade) {
		return
	}

	close(blade)
}

export function openBlade() {
	const blade = bladeRef.value as HTMLDivElement
	if (!blade) {
		return
	}

	open(blade)
}

function open(blade: HTMLDivElement) {
	bladeState.value = "open"
	setTimeout(() => {
		blade.classList.add(activeClass)
	}, 0)
}

function close(blade: HTMLDivElement) {
	blade.classList.remove(activeClass)

	setTimeout(() => {
		bladeState.value = "closed"
	}, 300)
}

const Blade: ReactiveComponent<NoProps> = (_, { slots }) => {
	return () => (
		<div class={bladeStyles} ref={bladeRef}>
			<div
				class="blade-bg"
				onClick={() => closeBlade()}
				onKeypress={(e) => e.key === "Enter" && closeBlade()}
				role="button"
				tabindex={0}
			></div>
			{bladeState.value == "open" && <div class="blade-content">{renderSlot(slots)}</div>}
		</div>
	)
}

export default defineComponent(Blade)
