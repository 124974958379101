import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import FileDisplay from "@/components/form/FileUpload/FileDisplay"
import Tile from "@/components/form/Tile"
import UserIndicator from "@/components/navigation/UserIndicator"
import { ContractSection, useContractContext } from "@/pages/contracts/context"
import { createContract } from "@/services/contractsService"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { faFlag } from "@fortawesome/free-regular-svg-icons"
import { faArrowLeft, faFlagCheckered, faMapPin } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { Alert, defineComponent, type AlertPopup, type ReactiveComponent } from "vue-utils"
import { validateContract } from "../../validation"

const reviewStyles = css`
	[class^="tile"] {
		width: 60%;
		margin-right: 0;
	}

	.stakeholders {
		display: flex;
		justify-content: flex-start;
		gap: 3em;
		padding: 0 0.5em;
		margin-bottom: 1rem;

		.user-indicator {
			width: 3rem;
			height: 3rem;
			font-size: 1.4rem;
		}

		span {
			text-align: center;
			font-size: 1.2rem;
		}
	}

	.milestones {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		position: relative;
		margin-bottom: 1rem;

		.milestone {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 5px;
				height: 100%;
				top: 3.7rem;
				background: var(--color-tenet-blue);
				left: calc(1.85rem - 3px);
			}

			&:last-child {
				&::before {
					content: none;
				}
			}

			header {
				display: flex;
				justify-content: start;
				align-items: center;

				.milestone-icon {
					width: 3.7rem;
					height: 3.7rem;
					display: grid;
					place-content: center;
					font-size: 1.6rem;
					border-radius: 100px;
					background: var(--color-tenet-blue);
					color: #fff;
					position: relative;
				}

				.milestone-heading {
					font-size: 1.15rem;

					p {
						margin-bottom: 0;
					}
				}
			}

			main {
				margin: 1rem 0 0 5.3rem;
				padding: 0.5rem 0.3rem 0;
				border-top: 1px solid var(--color-fill-darker);
				display: flex;
				flex-wrap: wrap;
				gap: 0.5em;

				& > div {
					flex: 1 1 100%;
					background: var(--color-white);
					padding: 0.5rem 0.8rem;
					border-radius: 0.3em;

					&:nth-child(2n) {
						background: var(--color-fill);
					}

					p,
					strong {
						display: block;
						margin-bottom: 0.2rem;
					}
				}

				.values {
					counter-reset: values;
					padding: 0;

					.value {
						padding: 0.8em 1em;
						display: flex;
						gap: 1.5em;
						border-radius: 0.3em;
						margin-bottom: 2em;

						&:last-child {
							margin-bottom: 0;
						}

						&::before {
							content: counter(values);
							counter-increment: values;
							display: grid;
							font-size: 1.2em;
							font-weight: 500;
							width: 35px;
							height: 35px;
							border: 1px solid;
							place-content: center;
							border-radius: 500px;
							flex-shrink: 0;
						}

						.value-content {
							flex: 100%;
							padding: 0.5rem 0.8rem;
							background: var(--color-white);
							border-radius: 0.3em;
						}
					}
				}
			}
		}
	}
`
const ReviewContractSection: ReactiveComponent = () => {
	const context = useContractContext()
	const contract = proxyProp(context, "contract")

	const popups = usePopups()

	function showCompleteString(completeString: string, title = "") {
		const popup: AlertPopup = {
			width: "60rem",
			okText: "Close",
			content: () => (
				<Alert title={title}>
					<p style={{ whiteSpace: "pre-wrap" }}>{completeString}</p>
				</Alert>
			),
		}

		void popups.showAlertPopup(popup)
	}

	async function saveContract(activate: boolean) {
		const result = validateContract(contract, context.client!.name, context.entryType)
		if (!context.parseValidationResult(result)) return

		const confirm = {
			content: () => (
				<Alert title="Confirm Save Contract">
					<p>Are you sure you want to save {activate && "and activate"} this contract?</p>
					{activate ? (
						<p>
							This will begin the contract and surveys will be sent out to the specified stakeholders once milestones
							have been reached.
						</p>
					) : (
						<p>No surveys will be sent until the contract is activated.</p>
					)}
				</Alert>
			),
			confirmText: "Yes",
			cancelText: "No",
		}

		if (!(await popups.confirm(confirm))) return

		if (activate) contract.active = true

		await createContract(contract)
		window.location.href = "/"
	}

	const valuesCount = computed(() => {
		return contract.milestones.flatMap((milestone) => milestone.milestoneWeightedValues).length
	})

	return () => (
		<div class={["d-flex flex-column align-items-center", reviewStyles]}>
			<Tile>
				<h4 class="mb-4 pb-2 border-2 border-bottom">About the Contract</h4>
				<div class="px-2">
					<h5>Supplier</h5>
					<p>{context.supplier!.name}</p>
					<hr />
					<h5>Client</h5>
					<p>{context.client!.name}</p>
					<hr />
					<h5>Contract Name</h5>
					<p>{contract.name}</p>
					<hr />
					<h5>Contract Description</h5>
					<p>
						{contract.description.length ? (
							<>
								{contract.description.length > 1000 ? (
									<>
										<span style={{ whiteSpace: "pre-wrap" }}>{contract.description.substring(0, 1000)}</span>
										<strong>...</strong>
										<br />
										<BootstrapButton
											color="primary"
											class="mt-2"
											onClick={() => showCompleteString(contract.description, "Contract Description")}
										>
											Read All
										</BootstrapButton>
									</>
								) : (
									contract.description
								)}
							</>
						) : (
							"Not supplied."
						)}
					</p>
					<hr />
					<h5>Contract Full Text</h5>
					<p>
						{contract.fullText.length ? (
							<>
								{contract.fullText.length > 1000 ? (
									<>
										<span style={{ whiteSpace: "pre-wrap" }}>{contract.fullText.substring(0, 1000)}</span>
										<strong>...</strong>
										<br />
										<BootstrapButton
											color="primary"
											class="mt-2"
											onClick={() => showCompleteString(contract.fullText, "Contract Full Text")}
										>
											Read All
										</BootstrapButton>
									</>
								) : (
									contract.fullText
								)}
							</>
						) : (
							"Not supplied."
						)}
					</p>
					<hr />
					<h5>Start Date</h5>
					<p>{contract.startDate.toGBDateString()}</p>
					<hr />
					<h5>Completion Date</h5>
					<p>{contract.completionDate.toGBDateString()}</p>
					<hr />
					<h5>Documents</h5>
					<>
						{!contract.filesForUpload || contract.filesForUpload.length === 0 ? (
							<p>No files have been added.</p>
						) : (
							<div class="file-upload-picker narrow">
								<FileDisplay files={contract.filesForUpload} />
							</div>
						)}
					</>
				</div>
			</Tile>

			<Tile>
				<h4 class="mb-4 pb-2 border-2 border-bottom">Stakeholders</h4>
				<div class="stakeholders">
					{context.stakeholders?.map((stakeholder) => (
						<div key={stakeholder.id} class="d-flex flex-column align-items-center gap-2">
							<UserIndicator
								class="user-indicator"
								firstName={stakeholder.firstName}
								lastName={stakeholder.lastName}
								showName={true}
							/>
							<span>
								{stakeholder.firstName} {stakeholder.lastName} <br />
								<small>{stakeholder.emailAddress}</small>
							</span>
						</div>
					))}
				</div>
			</Tile>

			<Tile>
				<h4 class="mb-4 pb-2 border-2 border-bottom">Milestones</h4>

				<div class="milestones">
					<div class="milestone">
						<header class="d-flex justify-start gap-4">
							<div class="milestone-icon">
								<Icon icon={faFlag} />
							</div>
							<div class="milestone-heading">
								<p class="fw-medium">Start</p>
								<p>{contract.startDate.toGBDateString()}</p>
							</div>
						</header>
					</div>
					{contract.milestones
						.sort((a, b) => a.reviewDate.compareTo(b.reviewDate))
						.map((milestone, milestoneIndex) => (
							<div class="milestone" key={milestone.guid}>
								<header class="d-flex justify-start gap-4">
									<div class="milestone-icon">
										<Icon icon={milestoneIndex === contract.milestones.length - 1 ? faFlagCheckered : faMapPin} />
									</div>
									<div class="milestone-heading">
										<strong>{milestone.name}</strong>
										<p>{milestone.reviewDate.toGBDateString()}</p>
									</div>
								</header>
								<main>
									<div>
										<strong>Minimum Response Percentage</strong>
										<div class="d-flex gap-4">
											<input
												type="range"
												class="form-range"
												min={10}
												max={100}
												value={milestone.minimumResponsePercentage}
												disabled
											/>
											<span style={{ flexShrink: "0" }}>{milestone.minimumResponsePercentage}%</span>
										</div>
									</div>
									<div>
										<p class="fw-medium">Values</p>
										{!milestone.milestoneWeightedValues || !milestone.milestoneWeightedValues.length ? (
											<p>No Values have been added for this Milestone.</p>
										) : (
											<ol class="values">
												{milestone.milestoneWeightedValues.map((weightedValue) => (
													<li class="value" key={weightedValue.guid}>
														<div class="value-content">
															<strong>
																{weightedValue.milestoneValue.name}{" "}
																{weightedValue.milestoneValue.industry && (
																	<small>({weightedValue.milestoneValue.industry.name})</small>
																)}
															</strong>
															<p class="mb-0">{weightedValue.milestoneValue.description}</p>
															<hr class="my-2" />
															<div class="pt-1">
																<strong>Why is this important to {context.client!.name}?</strong>
																<p>{weightedValue.comments}</p>
															</div>
															<hr class="my-2" />
															<div class="pt-1">
																<strong>Weight</strong>
																<div class="d-flex gap-4">
																	<input
																		type="range"
																		class="form-range"
																		min={10}
																		max={100}
																		value={weightedValue.weight}
																		disabled
																	/>
																	<span style={{ flexShrink: "0" }}>{weightedValue.weight}%</span>
																</div>
															</div>
															<div class="pt-3">
																<strong>Satisfactory Range</strong>
																<div class="d-flex gap-4">
																	<div class="double-range">
																		<input
																			type="range"
																			class="form-range"
																			min={10}
																			max={100}
																			value={weightedValue.minimumSatisfactoryScore}
																			disabled
																		/>
																		<input
																			type="range"
																			class="form-range"
																			min={10}
																			max={100}
																			value={weightedValue.maximumSatisfactoryScore}
																			disabled
																		/>
																	</div>
																	<span style={{ flexShrink: "0" }}>
																		{weightedValue.minimumSatisfactoryScore}% - {weightedValue.maximumSatisfactoryScore}
																		%
																	</span>
																</div>
															</div>
														</div>
													</li>
												))}
											</ol>
										)}
									</div>
								</main>
							</div>
						))}
				</div>
			</Tile>

			<div class="d-flex justify-between mt-5 w-100">
				<BootstrapButton
					color="secondary"
					style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
					onClick={() => context.changeSection(ContractSection.Milestones, false)}
				>
					<Icon icon={faArrowLeft} />
					Back
				</BootstrapButton>
				<div class="d-flex justify-end gap-2">
					<BootstrapButton
						color="dark"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => void saveContract(false)}
					>
						Save
					</BootstrapButton>
					{valuesCount.value > 0 && (
						<BootstrapButton
							color="primary"
							style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
							onClick={() => void saveContract(true)}
						>
							Save and Activate
						</BootstrapButton>
					)}
				</div>
			</div>
		</div>
	)
}

export default defineComponent(ReviewContractSection)
