import { SystemRole } from "@/enums"
import type { Company } from "@/models/Company"
import { toTitleCase } from "@/utils/toTitleCase"
import { RefQueries, defineComponent, useQueryRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import ManageCompany from "./manage-company"

const AddCompanyPage: ReactiveComponent = () => {
	const newName = useQueryRef("name", "", RefQueries.String)

	const newCompany: Company = {
		id: 0,
		guid: crypto.randomUUID(),
		name: toTitleCase(newName.value),
		address: {
			postcode: "",
			line1: "",
			line2: "",
			line3: "",
			city: "",
			region: "",
			country: "",
		},
		primaryContact: "",
		type: null,
		notes: "",
		brandingInformation: {
			logo: null,
			primaryColor: null,
			secondaryColor: null,
			logoFile: null,
		},
		industries: [],
		staff: null,
		associatedCompanies: null,
		values: null,
	}

	return () => (
		<AuthorizeRole role={SystemRole.SuperAdmin}>
			<BasicPage title="Add Company">
				<h1>Add a new Company</h1>
				<hr />
				<ManageCompany company={newCompany} />
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(AddCompanyPage)
