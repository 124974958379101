import type { Id, Industry } from "@/models"
import * as service from "@/services/industriesService"
import { arrayToMap } from "@/utils/arrayToMap"
import { toReactive } from "@vueuse/core"
import { defineStore } from "pinia"
import { toRef } from "vue"
import { ensureLoadingHasResult, piniaLoadableState } from "vue-utils"

async function loadIndustries() {
	const industries = await service.getIndustries()
	return arrayToMap(industries)
}

export const useIndustriesStore = defineStore("industries", {
	state: () => piniaLoadableState(loadIndustries),
	actions: {
		getIndustries(): Map<Id, Industry> {
			return ensureLoadingHasResult(this)
		},
		async createIndustry(industry: Industry) {
			const newIndustry = await service.createIndustry(industry)
			this.getIndustries().set(newIndustry.id, newIndustry)
		},
		async updateIndustry(industry: Industry) {
			const updatedIndustry = await service.updateIndustry(industry)
			this.getIndustries().set(updatedIndustry.id, updatedIndustry)
		},
		async deleteIndustry(industry: Id | Industry) {
			const id = typeof industry === "number" ? industry : industry.id
			await service.deleteIndustry(id)
			this.getIndustries().delete(id)
		},
	},
})

export function useIndustries(): Map<Id, Industry> {
	const store = useIndustriesStore()
	return toReactive(toRef(() => store.getIndustries()))
}
