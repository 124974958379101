import { LocalDate } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import type { GenericEntity, Nullable } from "./GenericEntity"
import type { MilestoneResponseValue } from "./MilestoneResponseValue"

export interface MilestoneResponse extends GenericEntity {
	responseDate: LocalDate
	assessmentScore: number
	responseValues: Nullable<MilestoneResponseValue[]>
}

export function mapApiMilestoneReponse(milestoneResponse: ApiTypeOf<MilestoneResponse>): MilestoneResponse {
	return {
		...milestoneResponse,
		responseDate: LocalDate.parse(milestoneResponse.responseDate),
	}
}
