import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "./BasicPage"

const NotFound: ReactiveComponent = () => {
	return () => (
		<BasicPage title="Not Found">
			<h1>Not Found</h1>
			<p>
				The page you requested could not be found.
				<br />
				<br />
				Please check the URL and try again, or contact an administrator.
			</p>
		</BasicPage>
	)
}

export default defineComponent(NotFound)
