import type { CompanyType } from "@/enums"
import {
	mapApiBrandingInfo,
	mapApiValue,
	type Address,
	type ApiTypeOf,
	type BrandingInfo,
	type GenericEntity,
	type INamed,
	type IUniqueIdentifier,
	type Industry,
	type Nullable,
	type Person,
	type Value,
} from "@/models"

export interface Company extends GenericEntity, INamed, IUniqueIdentifier {
	address: Address
	primaryContact: string
	type: Nullable<CompanyType>
	notes: Nullable<string>

	brandingInformation: BrandingInfo

	industries: Nullable<Industry[]>
	staff: Nullable<Person[]>

	associatedCompanies: Nullable<Company[]>

	values: Nullable<Value[]>
}

export function mapApiCompany(company: ApiTypeOf<Company>): Company {
	let associatedCompanies: Nullable<Company[]>
	try {
		associatedCompanies = company.associatedCompanies && company.associatedCompanies.map(mapApiCompany)
	} catch {
		associatedCompanies = null
	}

	return {
		...company,
		brandingInformation: mapApiBrandingInfo(company.brandingInformation),
		associatedCompanies,
		values: company.values?.map(mapApiValue) ?? [],
	}
}
