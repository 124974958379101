import type { SystemRole } from "@/enums"
import { User } from "@/models"
import { httpRequestJsonResponse } from "@/services/httpService"
import { toReactive } from "@vueuse/core"
import { defineStore } from "pinia"
import { computed, reactive, toRef, type ComputedRef } from "vue"
import { defineGlobals, ensureLoadingHasResult, piniaLoadableState } from "vue-utils"

async function getAccount(): Promise<User> {
	const user = await httpRequestJsonResponse<User>("/api/account")
	return reactive(new User(user))
}

export const useLoggedInUserStore = defineStore("logged-in-user", {
	state: () => piniaLoadableState(getAccount),
})

export function useLoggedInUser(): User {
	const store = useLoggedInUserStore()
	return toReactive(toRef(() => ensureLoadingHasResult(store)))
}

export function useAtLeastHasRole(role: SystemRole): ComputedRef<boolean> {
	const user = useLoggedInUser()
	return computed(() => user.atLeastHasRole(role))
}

defineGlobals({
	useLoggedInUser,
	useLoggedInUserStore,
})
