import Tile from "@/components/form/Tile"
import { proxyProp } from "@/utils/proxyProp"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const Notes: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	return () => (
		<Tile>
			<label for="companyNotes" class="tile-heading">
				Notes
			</label>
			<textarea id="companyNotes" v-model={company.notes} class="form-control" placeholder="Notes" rows={2}></textarea>
		</Tile>
	)
}

export default defineComponent(Notes)
