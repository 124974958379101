import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import type { Contract } from "@/models"
import { updateContract } from "@/services/contractsService"
import usePopups from "@/stores/popupsStore"
import { getEnumKeyByEnumValue } from "@/utils/getEnumKey"
import { proxyProp } from "@/utils/proxyProp"
import { faClose, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { Teleport, computed, provide, ref } from "vue"
import {
	Alert,
	FormTabs,
	FormTabsRenderingMethod,
	defineComponent,
	refSetter,
	requiredProp,
	type Component,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import { ContractContext, ContractContextInjectionKey, ContractSection } from "../context"
import { AboutContractSection, MilestonesSection, ResponsesSection, StakeholdersSection } from "../contract-sections"
import { validateContract } from "../validation"

interface Props {
	contract: Contract
}

type HiddenSections = ContractSection.Supplier | ContractSection.Client | ContractSection.Review

const ManageContract: ReactiveComponent<Props> = (props) => {
	const context = new ContractContext(props.contract, "edit")
	provide(ContractContextInjectionKey, context)

	const contract = proxyProp(context, "contract")

	const popups = usePopups()

	const sections: Record<Exclude<ContractSection, HiddenSections>, Component> = {
		[ContractSection.About]: AboutContractSection,
		[ContractSection.Stakeholders]: StakeholdersSection,
		[ContractSection.Milestones]: MilestonesSection,
		[ContractSection.Responses]: ResponsesSection,
	}

	const tabs = computed(() => {
		const tabs: FormTab[] = []

		Object.entries(sections).forEach(([section, component]) => {
			const contractSection = getEnumKeyByEnumValue(ContractSection, section)
			if (!contractSection) return

			tabs.push({
				id: contractSection.toLowerCase(),
				name: section,
				renderContent: component,
			})
		})

		return tabs
	})
	const tabId = ref(tabs.value[0].id)

	async function saveContract(activate: boolean) {
		if (!context.validateSection()) return

		const result = validateContract(contract, context.client!.name, "edit")
		if (!context.parseValidationResult(result)) return

		const confirm = {
			content: () => (
				<Alert title="Confirm Save Contract">
					<p>Are you sure you want to save {activate && "and activate"} this contract?</p>
					{activate ? (
						<p>
							This will begin the contract and surveys will be sent out to the specified stakeholders once milestones
							have been reached.
						</p>
					) : (
						<p>No surveys will be sent until the contract is activated.</p>
					)}
				</Alert>
			),
			confirmText: "Yes",
			cancelText: "No",
		}

		if (!contract.active && !(await popups.confirm(confirm))) return

		if (activate) contract.active = true

		await updateContract(context.contract)
		window.location.href = "/"
	}

	return () => (
		<>
			<FormTabs
				tabs={tabs.value}
				selectedTabId={tabId.value}
				setSelectedTab={refSetter(tabId)}
				content={FormTabsRenderingMethod.RenderActive}
			/>

			{tabId.value !== ContractSection.Responses.toLowerCase() && (
				<div class="d-flex justify-end mt-5 w-100 gap-2">
					<BootstrapButton
						color={contract.active ? "primary" : "dark"}
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => void saveContract(false)}
					>
						Save
					</BootstrapButton>
					{!contract.active && (
						<BootstrapButton
							color="primary"
							style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
							onClick={() => void saveContract(true)}
						>
							Save and Activate
						</BootstrapButton>
					)}
				</div>
			)}

			<Teleport to="body">
				<div class={["contract-error", { shown: context.errorMessages.length }]}>
					<div class="alert alert-danger">
						<div class="d-flex gap-2 align-items-center">
							<Icon icon={faExclamationTriangle} />
							<strong>Error</strong>
							<ul>
								{context.getLastErrors().map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</div>
						<button type="button" class="btn-close" onClick={() => context.closeErrors()}>
							<Icon icon={faClose} />
						</button>
					</div>
				</div>
			</Teleport>
		</>
	)
}

export default defineComponent(ManageContract, {
	contract: requiredProp(Object),
})
