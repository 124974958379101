import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import AuthorizeRole from "@/pages/AuthorizeRole"
import BasicPage from "@/pages/BasicPage"
import { fetchCompanyByPerson } from "@/services/companiesService"
import { HttpLoadingError } from "@/services/httpHelper"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { css } from "vite-css-in-js"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AddContractWizard from "./AddContractWizard"

const contractFormStyles = css`
	.section-nav {
		list-style: none;
		display: flex;
		justify-content: center;
		gap: 5em;
		font-size: 1.075em;

		li {
			position: relative;

			&::after {
				content: "\f0da";
				font: var(--fa-font-solid);
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: -2.5em;
				color: var(--color-tenet-black);
			}

			&:last-child {
				&::after {
					content: none;
				}
			}

			div {
				cursor: not-allowed;
				padding: 0.4em 1em;
			}
		}

		.visited {
			font-weight: 500;

			div {
				cursor: pointer;
			}
		}

		.active {
			color: var(--color-tenet-orange);

			div {
				cursor: default;
			}
		}
	}

	.section-title {
		text-align: center;
		margin: 1.5em 0 2em;
	}
`

const index: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const companyRef = useLoadableRef(loadCompany)

	async function loadCompany() {
		try {
			if (!loggedInUser.person) return null

			return await fetchCompanyByPerson(loggedInUser.person)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (companyRef.type !== "done") {
			return null
		}
		const company = companyRef.result

		return (
			<>
				<h1>Create a new Contract</h1>
				<hr />
				<AddContractWizard company={company} />
			</>
		)
	}

	return () => (
		<AuthorizeRole role={SystemRole.Supplier}>
			<BasicPage title="Add a Contract" class={contractFormStyles}>
				<ContentLoading stores={[companyRef]}>{renderContent()}</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(index)
