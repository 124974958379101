import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { ContentLoading } from "@/components/loading/StateLoading"
import { reactive } from "vue"
import { defineComponent, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import { getPeople } from "../../services/peopleService"
import BasicPage from "../BasicPage"
import PeopleTable from "./PeopleTable"

const PeoplePage: ReactiveComponent = () => {
	const peopleRef = useLoadableRef(async () => {
		const people = await getPeople()
		return reactive(people)
	})

	return () => (
		<BasicPage title="People">
			<ContentLoading stores={[peopleRef]}>
				<h1>People</h1>
				<hr />

				<Tile>
					<PeopleTable people={ensureLoadingHasResult(peopleRef)} />
				</Tile>

				<div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
					<TenetButton url="/people/add">Add a Person</TenetButton>
				</div>
			</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(PeoplePage)
