import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const footerStyles = css`
	text-align: center;
	padding: 1rem 2rem;
	height: 1rem;
	position: fixed;
	bottom: 0;
	left: 0;
	right: var(--scrollbar-width);
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	background: var(--color-fill);
	border-top: thin solid var(--color-tenet-darkgrey);
	font-weight: 400;
	box-shadow: 0 -4px 20px 4px rgba(var(--color-tenet-darkgrey-rgb), 0.3);
`

const AppFooter: ReactiveComponent = () => {
	const year = new Date().getFullYear()

	return () => <footer class={footerStyles}>&copy; Tenet Law {year}</footer>
}

export default defineComponent(AppFooter)
