import BootstrapButton from "@/components/form/BootstrapButton"
import { showErrorMessage } from "@/composition/useLoading"
import { useVueAuth } from "oidc-vue"
import { ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const LoginButton: ReactiveComponent = () => {
	const loggingIn = ref(false)
	const auth = useVueAuth()

	async function logIn() {
		loggingIn.value = true
		try {
			await auth.manager.signinRedirect()
		} catch (e) {
			console.error(e)
			void showErrorMessage("Failed to log in", "Unable to log in. Please try again later or contact an administrator")
		} finally {
			loggingIn.value = false
		}
	}

	return () => (
		<BootstrapButton color="primary" isSubmitting={loggingIn.value} onClick={() => void logIn()}>
			Log In
		</BootstrapButton>
	)
}

export default defineComponent(LoginButton)
