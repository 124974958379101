import type { INamed } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useGenericFiltering<T extends INamed>(options: {
	items: MaybeRef<T[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<T[]> {
	const { searchTerm: search, items } = options

	return computed(() => {
		const itemsArray = unref(items)
		const searchTerm = unref(search).toLowerCase()

		if (searchTerm.length === 0) {
			return itemsArray
		}

		return itemsArray.filter((item) => {
			return item.name.toLowerCase().includes(searchTerm)
		})
	})
}
