import Icon from "@/components/Icon"
import { closeBlade } from "@/components/form/Blade"
import { ContentLoading } from "@/components/loading/StateLoading"
import type { Id, Person } from "@/models"
import { getPeople, getStaffForCompany } from "@/services/peopleService"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { debounce } from "lodash"
import { css } from "vite-css-in-js"
import { computed, reactive, ref } from "vue"
import { defineComponent, optionalProp, requiredProp, useLoadableRef, type ReactiveComponent } from "vue-utils"
import SearchBar from "../../SearchBar"
import PeopleList from "./PeopleList"

interface Props {
	companyId?: Id
	includeUnassigned?: boolean
	existingPeople?: Person[]
	nameSingular?: string

	single: boolean
	name: string

	addPeople(people: Person[]): void
}

const staffStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: flex-end;

	.search-staff {
		display: flex;
		flex: 100%;
		margin-top: 2rem;
	}

	.staff-members {
		display: flex;
		flex: 100%;
		flex-wrap: wrap;
		gap: 2rem;
		margin-top: 1rem;
		max-height: 70vh;
		overflow: auto;

		.staff-member {
			flex: 0 1 calc((100% / 2) - 1rem);
			text-align: center;
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			align-items: center;
			font-size: 1.2em;
			gap: 0.5em;
			padding: 0.75rem 0.25rem 0.4rem;
			border-radius: 0.15rem;
			transition: background 0.2s;

			[class$="-UM"] {
				font-size: 1.2em;
				width: 2em;
				height: 2em;
				outline: 0.4rem solid transparent;
				transition: outline 0.1s;
			}

			small {
				width: 12rem;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				display: block;
			}

			&.active {
				font-weight: 400;

				[class$="-UM"] {
					outline-color: rgba(var(--color-tenet-blue-rgb), 0.4);
				}
			}

			&:hover {
				background: var(--color-fill);
			}
		}
	}
`

const SelectPeople: ReactiveComponent<Props> = (props) => {
	const people = ref<Person[]>(props.existingPeople ? [...props.existingPeople] : [])

	const peopleRef = useLoadableRef(async () => {
		const people = await (!props.companyId
			? getPeople()
			: getStaffForCompany(props.companyId, props.includeUnassigned ?? false))
		return reactive(people)
	})

	const personIds = computed(() => people.value.map((s) => s.id))

	const searchTerm = ref("")

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
		}
	})

	function selectPerson(person: Person) {
		if (!personIds.value.includes(person.id)) {
			if (props.single) {
				people.value = []
			}

			people.value.push(person)
			return
		}

		const index = personIds.value.indexOf(person.id)
		people.value.splice(index, 1)
	}

	function renderContent() {
		if (peopleRef.type !== "done") {
			return null
		}

		const persons = peopleRef.result

		return (
			<div class={staffStyles}>
				{persons.length === 0 && (
					<div class="w-100 text-left">
						<h4 class="mt-4">No available {props.name} could be found.</h4>
						<button type="button" class="btn btn-secondary mt-5" onClick={closeBlade}>
							<Icon icon={faArrowLeft} style={{ marginRight: ".5rem" }} />
							Back
						</button>
					</div>
				)}
				{persons.length > 0 && (
					<>
						<div class="search-staff">
							<SearchBar search={searchTerm.value} setSearch={updateSearch} />
						</div>

						<div class="staff-members">
							<PeopleList
								people={persons}
								searchTerm={searchTerm.value}
								selectedPersonIds={personIds.value}
								existingPeople={props.existingPeople ?? []}
								selectPerson={(person: Person) => selectPerson(person)}
							/>
						</div>
						<button type="button" class="btn btn-primary" onClick={() => props.addPeople(people.value)}>
							Select {props.single && props.nameSingular ? props.nameSingular : props.name}
						</button>
					</>
				)}
			</div>
		)
	}

	return () => <ContentLoading stores={[peopleRef]}>{renderContent()}</ContentLoading>
}

export default defineComponent(SelectPeople, {
	companyId: optionalProp(Number),
	includeUnassigned: optionalProp(Boolean),
	existingPeople: optionalProp(Array),
	nameSingular: optionalProp(String),

	single: requiredProp(Boolean),
	name: requiredProp(String),

	addPeople: requiredProp(Function),
})
