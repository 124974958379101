import type { User } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useUsersFiltering(options: {
	users: MaybeRef<User[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<User[]> {
	const { searchTerm: search, users } = options

	return computed(() => {
		const usersArray = unref(users)
		const searchTerm = unref(search).toLowerCase()
		if (searchTerm.length === 0) {
			return usersArray
		}

		return unref(users).filter((user) => {
			const name = `${user.firstName} ${user.lastName}`.toLowerCase()
			return name.includes(searchTerm) || user.emailAddress.includes(searchTerm)
		})
	})
}
