import { type User } from "@/models"
import {
	RequestMethod,
	httpJsonRequestJsonResponse,
	httpRequest,
	httpRequestJsonResponse,
} from "@/services/httpService"

export async function getUsers(): Promise<User[]> {
	return await httpRequestJsonResponse<User[]>("/api/users/")
}

export async function getUser(guid: string): Promise<User> {
	return await httpRequestJsonResponse<User>(`/api/users/${guid}`)
}

export async function createUser(user: User): Promise<User> {
	const newUser = { ...user }
	newUser.password = newUser.password && btoa(newUser.password)
	return await httpJsonRequestJsonResponse<User>("/api/users", newUser, RequestMethod.POST)
}

export async function updateUser(user: User): Promise<User> {
	return await httpJsonRequestJsonResponse<User>("/api/users", user, RequestMethod.PUT)
}

export async function resetPassword(user: User): Promise<void> {
	await httpRequest(`/api/users/reset-password/${user.guid}`, RequestMethod.POST)
}
