import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import BootstrapButton from "@/components/form/BootstrapButton"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { useLoading } from "@/composition/useLoading"
import { SystemRole } from "@/enums"
import type { Company } from "@/models"
import { ContractSection, useContractContext } from "@/pages/contracts/context"
import { searchCompanies, type SearchData } from "@/services/companiesService"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, watch } from "vue"
import { debounce, defineComponent, type ReactiveComponent } from "vue-utils"
import { validateSupplier } from "../../validation"

const SupplierSection: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const { runAction } = useLoading()

	const context = useContractContext()

	const contract = proxyProp(context, "contract")

	const searchTerm = ref<string>("")
	const suppliers = ref<Company[] | null>(null)

	const supplierTileStyles = css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 3rem;
		outline: 0;
		transition: outline 0.1s;
		cursor: pointer;

		&.active {
			outline: 5px solid rgba(var(--color-tenet-blue-rgb), 0.5);
		}
	`

	async function getSuppliers() {
		const search = searchTerm.value.trim()
		const client = !context.showClientSection ? context.client : null

		const searchData: SearchData = {
			search,
			company: client,
		}

		if (searchTerm.value) {
			suppliers.value = search ? await searchCompanies(searchData) : []
		} else {
			suppliers.value = null
		}
	}

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
		}
	})

	watch(searchTerm, () => void runAction(getSuppliers()))

	function selectSupplier(supplier: Company) {
		if (context.supplier && context.supplier.id == supplier.id) {
			return
		}
		contract.supplierId = supplier.id
		context.supplier = supplier
		contract.clientId = null
		context.client = null
		context.visitedSections = [ContractSection.Supplier]
	}

	function clearSupplier() {
		contract.supplierId = null
		context.supplier = null
		contract.clientId = null
		context.client = null
		context.visitedSections = [ContractSection.Supplier]
	}

	context.validate = () => {
		const errors = validateSupplier(contract)
		if (errors.length > 0) return errors

		return true
	}

	return () => (
		<>
			{context.supplier && (
				<>
					<div class="d-flex flex-fill justify-center items-center">
						<Tile class="mb-4 d-flex align-items-center justify-between" style={{ width: "25%", marginRight: 0 }}>
							<div>
								<p class="mb-2" style={{ fontSize: "1.05em" }}>
									Selected Supplier:
								</p>
								<h4 class="mb-2">{context.supplier.name}</h4>
							</div>
							<button class="btn btn-close" onClick={clearSupplier}></button>
						</Tile>
					</div>
				</>
			)}

			{!context.supplier && (
				<>
					<div class="d-flex flex-fill justify-center">
						<Tile class="mt-4" style={{ width: "45%", marginRight: 0 }}>
							<h3 class="mb-3">Search for a Supplier</h3>
							<SearchBar search={searchTerm.value} setSearch={updateSearch} />
						</Tile>
					</div>

					{suppliers.value && (
						<div class="d-flex flex-wrap mt-4" style={{ marginRight: "-1.5em" }}>
							{suppliers.value.length === 0 && (
								<Tile class="py-4 my-4">
									<h4 class="text-center">No Suppliers Found</h4>
									{loggedInUser.atLeastHasRole(SystemRole.SuperAdmin) && (
										<div class="d-flex justify-center items-center gap-4">
											<TenetButton url={`/companies/add?name=${searchTerm.value}`}>Add a Company</TenetButton>
											<span class="mt-3 fw-medium fs-5">or</span>
											<TenetButton url={`/companies/?tab=associate`}>Manage Associations</TenetButton>
										</div>
									)}
								</Tile>
							)}

							{suppliers.value.length > 0 &&
								suppliers.value.map((supplier) => (
									<Tile
										key={supplier.id}
										class={[supplierTileStyles, { active: context.supplier && context.supplier.id == supplier.id }]}
										style={{ flex: "0 1 calc(33.33% - 1.5em)" }}
										onClick={() => selectSupplier(supplier as Company)}
									>
										{supplier.name}
										<BootstrapButton color="primary">Select</BootstrapButton>
									</Tile>
								))}
						</div>
					)}
				</>
			)}

			{context.supplier && (
				<div class="d-flex justify-end">
					<BootstrapButton
						color="primary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.Client, true)}
					>
						Next
						<Icon icon={faArrowRight} />
					</BootstrapButton>
				</div>
			)}
		</>
	)
}

export default defineComponent(SupplierSection)
