import { SystemRole } from "@/enums"
import { User, type UserDetails } from "@/models"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import ManageUser from "./ManageUser"

const AddUserPage: ReactiveComponent = () => {
	const userDetails: UserDetails = {
		id: 0,
		guid: crypto.randomUUID(),
		firstName: "",
		lastName: "",
		emailAddress: "",
		role: SystemRole.Inactive,
		person: null,
		password: "",
	}
	const newUser = new User(userDetails)

	return () => (
		<BasicPage title="Add User">
			<h1>Add a new User</h1>
			<hr />
			<ManageUser user={newUser} />
		</BasicPage>
	)
}

export default defineComponent(AddUserPage)
