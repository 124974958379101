import { faChevronLeft, faChevronRight, faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import Icon from "../Icon"

interface Props {
	page: number
	totalPages: number
	pageSize?: number
	setPage(page: number): void
}

const pagingStyles = css`
	display: inline-flex;
	list-style: none;
	gap: 0.5rem;
	margin: 0;
	padding-left: 1rem;

	button {
		all: unset;

		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		text-align: center;
		min-width: 1.875rem;
		height: 2rem;
		padding: 0 0.125rem;
		background-color: var(--color-fill);
	}

	button:not(:disabled) {
		cursor: pointer;

		&:hover {
			color: var(--color-tenet-blue);
		}

		&[data-selected="true"] {
			background-color: var(--color-tenet-blue);
			color: white;
			font-weight: bold;
			cursor: initial;
		}
	}

	svg {
		font-size: 0.75rem;
	}
`

const Pagination: ReactiveComponent<Props> = (props) => {
	const pageButtons = computed(() => {
		const listSize = props.pageSize ?? 9
		const result: number[] = []
		for (let i = 0; i < props.totalPages * 2 && result.length < listSize; i++) {
			const page = i % 2 == 0 ? props.page + i / 2 : props.page - (i + 1) / 2
			if (page < 1 || page > props.totalPages) {
				continue
			}
			result.push(page)
		}
		result.sort((a, b) => a - b)
		if (result.length > 2 && result[0] !== 1) {
			result.shift()
			result.shift()
		}
		if (result.length > 2 && result[result.length - 1] !== props.totalPages) {
			result.pop()
			result.pop()
		}
		return result
	})

	function renderButton(page: number) {
		return (
			<button data-selected={String(props.page === page)} onClick={() => props.setPage(page)}>
				{page}
			</button>
		)
	}

	return () => (
		<ul class={pagingStyles}>
			<button onClick={() => props.setPage(props.page - 1)} disabled={props.page === 1}>
				<Icon icon={faChevronLeft} />
			</button>
			{props.totalPages > 1 && !pageButtons.value.includes(1) && (
				<>
					<li>{renderButton(1)}</li>
					<li>
						<button disabled>
							<Icon icon={faEllipsis} />
						</button>
					</li>
				</>
			)}
			{pageButtons.value.map((p) => (
				<li key={p}>{renderButton(p)}</li>
			))}
			{props.totalPages > 1 && !pageButtons.value.includes(props.totalPages) && (
				<>
					<li>
						<button disabled>
							<Icon icon={faEllipsis} />
						</button>
					</li>
					<li>{renderButton(props.totalPages)}</li>
				</>
			)}
			<button onClick={() => props.setPage(props.page + 1)} disabled={props.page === props.totalPages}>
				<Icon icon={faChevronRight} />
			</button>
		</ul>
	)
}

export default defineComponent(Pagination, {
	page: requiredProp(Number),
	totalPages: requiredProp(Number),
	pageSize: optionalProp(Number),
	setPage: requiredProp(Function),
})
