import type { ApiTypeOf } from "./ApiType"
import type { Nullable } from "./GenericEntity"
import { mapApiUploadedFile, type UploadedFile } from "./UploadedFile"

export interface BrandingInfo {
	logo: Nullable<UploadedFile>
	primaryColor: Nullable<string>
	secondaryColor: Nullable<string>

	logoFile: Nullable<File>
}

export function mapApiBrandingInfo(brandingInfo: ApiTypeOf<BrandingInfo>): BrandingInfo {
	return {
		...brandingInfo,
		logo: brandingInfo.logo && mapApiUploadedFile(brandingInfo.logo),
	}
}
