import Icon from "@/components/Icon"
import { MilestoneValueType } from "@/enums"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowLeft, faArrowRight, faInfo } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Transition, computed, ref } from "vue"
import { defineComponent, useOnInputNumber, type ReactiveComponent } from "vue-utils"
import { SurveySection, useSurveyContext, type SurveySectionTransitionName } from "../context"

const valueStyles = css`
	h1 {
		margin-bottom: 0.75rem !important;
	}

	h2 {
		font-size: 1.2em;
		font-weight: 300 !important;
		margin-bottom: 0;
	}

	h3 {
		font-size: 1.1em;
	}

	.separator {
		width: 5rem;
		height: 2px;
		background-color: var(--color-tenet-orange);
		margin: 1.4rem 0 2rem;
	}

	.info {
		display: inline-block;
		padding: 1rem;
		line-height: 0.8em;
		cursor: help;
		position: relative;
		top: -0.2rem;

		.info-circle {
			color: var(--color-tenet-blue);
			width: 1.2em;
			height: 1.2em;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 0.15em 0.1em 0.2em;
			border: 0.12em solid var(--color-tenet-blue);
			border-radius: 500px;
			font-size: 0.8em;

			svg {
				height: 100%;
			}
		}
	}

	.info-alert {
		position: absolute;
		z-index: 1;
		width: 85vw;
		left: 50%;
		margin-left: -42.5vw;
		margin-top: -0.2rem;
		font-size: 0.8em;
		pointer-events: none;
		cursor: help;

		@media screen and (min-width: 768px) {
			width: 60vw;
			margin-left: -30vw;
		}
	}

	label {
		width: 100%;
		display: block;
		margin-bottom: 1rem;
		font-weight: 400;

		.slider {
			margin: 0.5rem 0 1.5rem;
			display: flex;
			gap: 2rem;
			justify-content: space-between;
			align-items: center;
			font-weight: 300;

			input[type="range"] {
				width: 100%;
				-webkit-appearance: none;
				appearance: none;
				height: 20px;
				background: transparent;
				cursor: pointer;

				&::-webkit-slider-runnable-track {
					height: 10px;
					border-radius: 20px;
					background-color: #ccc;
				}

				&::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					height: 20px;
					width: 20px;
					border-radius: 20px;
					background-color: var(--color-tenet-blue);
					margin-top: -5px;
				}
			}
		}
	}
`

const SurveyValues: ReactiveComponent = () => {
	const context = useSurveyContext()

	const survey = proxyProp(context, "survey")
	const milestone = survey.milestone
	const milestoneValues = milestone.milestoneWeightedValues!

	const transitionName = ref<SurveySectionTransitionName>("slideLeft")

	const infoBoxRef = ref<HTMLDivElement>()

	function showInfoBox() {
		const infoBox = infoBoxRef.value as HTMLDivElement
		if (!infoBox) return

		infoBox.style.pointerEvents = "all"
		infoBox.classList.add("show")
	}

	function hideInfoBox() {
		const infoBox = infoBoxRef.value as HTMLDivElement
		if (!infoBox) return

		infoBox.style.pointerEvents = "none"
		infoBox.classList.remove("show")
	}

	const currentResponseValues = computed(() => {
		const responses = milestone.responses

		if (!responses) return []

		const currentValue = milestoneValues[context.valueIndex]
		const currentValueResponse = responses.find((response) => response.responseValues)

		if (!currentValueResponse) return []

		const currentValueResponseValues = currentValueResponse.responseValues!.filter(
			(responseValue) => responseValue.milestoneValue.id === currentValue.milestoneValue.id
		)

		return currentValueResponseValues ?? []
	})

	const ratingResponseValue = computed(() => {
		if (!currentResponseValues.value.length) return null
		return currentResponseValues.value.find((responseValue) => responseValue.type == MilestoneValueType.rating) ?? null
	})

	const confidenceResponseValue = computed(() => {
		if (!currentResponseValues.value.length) return null
		return (
			currentResponseValues.value.find((responseValue) => responseValue.type == MilestoneValueType.confidence) ?? null
		)
	})

	const ratingChanged = ref(false)
	const confidenceChanged = ref(false)

	return () => (
		<section>
			{milestoneValues.map((value, index) => (
				<Transition name={transitionName.value} key={value.id}>
					{index === context.valueIndex && (
						<div class={["section-inner", valueStyles]}>
							<h1>{value.milestoneValue.name}</h1>
							<h2>
								{value.milestoneValue.description}
								<div
									class="info"
									onMouseenter={showInfoBox}
									onMouseleave={hideInfoBox}
									onFocusin={showInfoBox}
									onFocusout={hideInfoBox}
								>
									<span class="info-circle">
										<Icon icon={faInfo} />
									</span>
								</div>
							</h2>
							<div
								class="alert alert-light info-alert fade"
								ref={infoBoxRef}
								onMouseenter={showInfoBox}
								onMouseleave={hideInfoBox}
								onFocusin={showInfoBox}
								onFocusout={hideInfoBox}
							>
								<h3>Why is this important to {survey.contract!.client!.name}?</h3>
								<p class="break-spaces mb-0">{value.comments}</p>
							</div>

							<div class="separator"></div>

							{ratingResponseValue.value && (
								<label>
									How would you rate the value above?
									<div class="slider">
										<input
											type="range"
											min={0}
											max={100}
											step={10}
											value={ratingResponseValue.value.value}
											onInput={useOnInputNumber((rating) => {
												ratingChanged.value = true
												ratingResponseValue.value!.value = rating
											})}
										/>
										{ratingResponseValue.value.value}%
									</div>
								</label>
							)}

							{confidenceResponseValue.value && (
								<label>
									How confident are you in your answer?
									<div class="slider">
										<input
											type="range"
											min={0}
											max={100}
											step={10}
											value={confidenceResponseValue.value.value}
											onInput={useOnInputNumber((confidence) => {
												confidenceChanged.value = true
												confidenceResponseValue.value!.value = confidence
											})}
										/>
										{confidenceResponseValue.value.value}%
									</div>
								</label>
							)}

							<div class="mt-5 position-relative" style={{ padding: "4rem 0" }}>
								{context.valueIndex > 0 ? (
									<button
										class="survey-button"
										onClick={() => {
											transitionName.value = "slideLeft"
											context.valueIndex--
										}}
									>
										<span class="icon">
											<Icon icon={faArrowLeft} />
										</span>
										<span class="text">Back</span>
									</button>
								) : (
									<button
										class="survey-button"
										onClick={() => {
											context.sectionTransitionName = "slideLeft"
											context.section = SurveySection.Intro
										}}
									>
										<span class="icon">
											<Icon icon={faArrowLeft} />
										</span>
										<span class="text">Back</span>
									</button>
								)}

								<Transition>
									{(context.visitedIndexes.includes(context.valueIndex) ||
										(ratingChanged.value && confidenceChanged.value)) && (
										<>
											{context.valueIndex < milestoneValues.length - 1 ? (
												<button
													class="survey-button"
													onClick={() => {
														transitionName.value = "slideRight"

														if (!context.visitedIndexes.includes(context.valueIndex)) {
															context.addIndex(context.valueIndex)
															ratingChanged.value = false
															confidenceChanged.value = false
														}

														context.valueIndex++
													}}
												>
													<span class="text">Next</span>
													<span class="icon">
														<Icon icon={faArrowRight} />
													</span>
												</button>
											) : (
												<button
													class="survey-button"
													onClick={() => {
														context.sectionTransitionName = "slideRight"
														context.section = SurveySection.Outro

														if (!context.visitedIndexes.includes(context.valueIndex)) {
															context.addIndex(context.valueIndex)
														}
													}}
												>
													<span class="text">Next</span>
													<span class="icon">
														<Icon icon={faArrowRight} />
													</span>
												</button>
											)}
										</>
									)}
								</Transition>
							</div>
						</div>
					)}
				</Transition>
			))}
		</section>
	)
}

export default defineComponent(SurveyValues)
