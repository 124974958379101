import { css } from "vite-css-in-js"
import { computed, type HTMLAttributes, type TableHTMLAttributes } from "vue"
import {
	castUnsafe,
	defineComponent,
	optionalProp,
	requiredProp,
	type Component,
	type ConsumedProps,
	type ReactiveComponent,
} from "vue-utils"
import type { JSX } from "vue/jsx-runtime"

export interface Props<T, C extends Record<string, TableColumn<T>>> {
	entities: T[]
	getKey(item: T): string | number
	columns: C
	rowAttributes?: (item: T) => HTMLAttributes
}

export interface TableColumn<T> {
	label: string
	size: string
	renderTh?: Component
	renderTd?: Component<{
		item: T
		index: number
	}>
	renderContent?: Component<{
		item: T
		index: number
	}>
}

const tableStyles = css`
	width: 100%;
	display: grid;
	border-radius: 0.25rem;

	th,
	td {
		display: flex;
		align-items: center;
		padding: 0.35rem 0.5rem;
	}

	& > thead,
	& > tbody {
		& > tr {
			& > th,
			& > td {
				border-bottom: var(--table-border);
				border-right: var(--table-border);
			}
		}
	}

	& > thead > tr:first-child > * {
		border-top: var(--table-border);
	}
	& > * > tr > *:first-child {
		border-left: var(--table-border);
	}

	& > thead > tr > th {
		background-color: var(--color-fill);
	}
	& > tbody > tr:nth-child(2n) > td {
		background-color: var(--color-fill);
	}
`

export const createColumnTable = <T, C extends Record<string, TableColumn<T>>>(): Component<
	Props<T, C>,
	TableHTMLAttributes
> => {
	const DataTable: ReactiveComponent<Props<T, C>, TableHTMLAttributes> = (props, { attrs }) => {
		const gridTemplates = computed(() =>
			Object.values(props.columns)
				.map((column) => column.size)
				.join(" ")
		)

		function renderRow(item: T) {
			const rowAttrs = props.rowAttributes?.(item) ?? {}
			return (
				<tr key={props.getKey(item)} {...rowAttrs}>
					{Object.keys(props.columns).map((key, i) => renderCell(key, i, item))}
				</tr>
			)
		}

		function renderCell(key: keyof C, index: number, item: T) {
			const column = props.columns[key]
			if (column.renderTd) {
				return <column.renderTd key={key} index={index} item={item} />
			}
			return <td key={key}>{!!column.renderContent && <column.renderContent index={index} item={item} />}</td>
		}

		function renderHeader(key: keyof C) {
			const column = props.columns[key]
			if (column.renderTh) {
				return <column.renderTh key={key} />
			}
			return <th key={key}>{column.label}</th>
		}

		return () => (
			<table class={[tableStyles, "grid-table"]} style={{ gridTemplateColumns: gridTemplates.value }} {...attrs}>
				<thead>
					<tr>{Object.keys(props.columns).map(renderHeader)}</tr>
				</thead>
				<tbody>{props.entities.map(renderRow)}</tbody>
			</table>
		)
	}
	return defineComponent(DataTable, {
		entities: requiredProp(Array),
		getKey: requiredProp(Function),
		columns: requiredProp(Object),
		rowAttributes: optionalProp(Function, Object),
	})
}

const ColumnTable =
	castUnsafe<
		<T, C extends Record<string, TableColumn<T>>>(props: ConsumedProps<Props<T, C>, TableHTMLAttributes>) => JSX.Element
	>(createColumnTable())
export default ColumnTable
