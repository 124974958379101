import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "./BasicPage"

const NotAllowed: ReactiveComponent = () => {
	return () => (
		<BasicPage title="Access Denied">
			<h1>Access Denied</h1>
			<p>
				You do not have permission to view this page.
				<br />
				<br />
				If this is incorrect, please contact an administrator.
			</p>
		</BasicPage>
	)
}

export default defineComponent(NotAllowed)
