import type { Survey } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { PieChart, easings, type AnimationDefinition, type PieChartData, type PieChartOptions } from "chartist"
import { onMounted, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	surveys: Survey[]
}

const SurveysChart: ReactiveComponent<Props> = (props) => {
	const surveys = proxyProp(props, "surveys")

	const chartBackgroundRef = ref<HTMLDivElement>()
	const chartRef = ref<HTMLDivElement>()
	const chartTooltipRef = ref<HTMLDivElement>()
	const chartDonutInnerRef = ref<HTMLDivElement>()

	const animationDuration = 1000

	const sentSurveys = surveys.filter((x) => x.sent)
	const completedSurveys = surveys.filter((x) => x.sent && x.submitted)
	const uncompletedSurveys = surveys.filter((x) => x.sent && !x.submitted)

	const labels: string[] = []
	const seriesData: number[] = []

	if (completedSurveys.length > 0) {
		labels.push("Completed Surveys")
		seriesData.push(completedSurveys.length)
	}
	if (uncompletedSurveys.length > 0) {
		labels.push("Awaiting Responses")
		seriesData.push(uncompletedSurveys.length)
	}

	const data: PieChartData = {
		series: seriesData,
	}

	const displayOptions: PieChartOptions = {
		donut: true,
		chartPadding: 20,
		showLabel: false,
	}

	function renderCharts() {
		const chartBackground = chartBackgroundRef.value as HTMLDivElement
		const chart = chartRef.value as HTMLDivElement
		if (chartBackground == null || chart == null) return

		renderChart(chartBackground, { series: [1] }, displayOptions, false)
		renderChart(chart, data, displayOptions, true)

		const donutInner = chartDonutInnerRef.value as HTMLDivElement
		if (donutInner == null) return

		const percentage = donutInner.querySelector("span")
		// eslint-disable-next-line no-unused-expressions
		percentage &&
			setTimeout(() => {
				percentage.classList.add("show")
			}, 400)
	}

	function renderChart(chart: HTMLDivElement, data: PieChartData, options: PieChartOptions, animate: boolean) {
		const piechart = new PieChart(chart, data, options)

		if (!animate) return

		piechart.on("created", (data) => {
			const tooltip = chartTooltipRef.value as HTMLDivElement
			const donutInner = chartDonutInnerRef.value as HTMLDivElement
			if (tooltip == null || donutInner == null) return
			let animating = false

			const series = data.svg.getNode().querySelectorAll<HTMLElement>(".ct-series")
			series.forEach((slice, index) => {
				const label = labels[index]
				const value = seriesData[index]
				const seriesClass = Array.from(slice.classList).find((x) => x.startsWith("ct-series-"))

				slice.addEventListener("mouseenter", () => {
					// eslint-disable-next-line no-unused-expressions
					seriesClass && tooltip.classList.add(seriesClass)
					tooltip.innerHTML = `<p class="fw-medium mb-0">${label}: ${value.toFixed()}</p>`
					tooltip.classList.add("show")

					const oldPercentage = donutInner.querySelector("span")
					if (animating || !seriesClass || !oldPercentage || oldPercentage.classList.contains(seriesClass)) return

					animating = true
					const newPercentageTemplate = document.createElement("template")
					newPercentageTemplate.innerHTML = `<span class="${seriesClass} fade">${percentage(value, sentSurveys.length)}%</span>`
					const newPercentage = newPercentageTemplate.content.children[0]
					donutInner.append(newPercentage)
					oldPercentage.classList.remove("show")
					setTimeout(() => {
						newPercentage.classList.add("show")
					}, 100)
					setTimeout(() => {
						oldPercentage.remove()
						animating = false
					}, 150)
				})

				slice.addEventListener("mousemove", (evt: MouseEvent) => {
					tooltip.style.left = `${evt.pageX + 15}px`
					tooltip.style.top = `${evt.pageY + 25}px`
				})

				slice.addEventListener("mouseleave", () => {
					tooltip.classList.remove("show")
					// eslint-disable-next-line no-unused-expressions
					seriesClass && tooltip.classList.remove(seriesClass)
				})
			})
		})

		piechart.on("draw", (data) => {
			if (data.type === "label") {
				const animationDefinition: Record<string, AnimationDefinition> = {
					opacity: {
						id: `anim-label${data.index}`,
						dur: animationDuration,
						from: 0,
						to: 1,
						easing: easings.easeInOutCubic,
						fill: "freeze",
					},
				}

				if (data.index !== 0) animationDefinition.opacity.begin = animationDuration * data.index - 200

				data.element.attr({
					opacity: 0,
				})

				data.element.animate(animationDefinition, false)
			}

			if (data.type === "slice") {
				const pathLength = data.element.getNode<SVGGeometryElement>().getTotalLength()

				data.element.attr({
					"stroke-dasharray": `${pathLength}px ${pathLength}px`,
				})

				const animationDefinition: Record<string, AnimationDefinition> = {
					"stroke-dashoffset": {
						id: `anim-label${data.index}`,
						dur: animationDuration,
						from: `${-pathLength}px`,
						to: "0px",
						easing: easings.easeInOutCubic,
						fill: "freeze",
					},
				}

				if (data.index !== 0) animationDefinition["stroke-dashoffset"].begin = animationDuration * data.index - 160

				data.element.attr({
					"stroke-dashoffset": `${-pathLength}px`,
				})

				data.element.animate(animationDefinition, false)
			}
		})
	}

	function percentage(value: number, total: number) {
		const percentage = Math.round((value / total) * 100)
		return isNaN(percentage) ? 0 : percentage
	}

	onMounted(renderCharts)

	return () => (
		<div class="chart">
			<h2 class="text-center">Survey Responses</h2>
			<div class="full-chart">
				<div class="ct-chart ct-square ct-chart-background" ref={chartBackgroundRef}></div>
				<div class="ct-chart ct-square ct-chart-display" ref={chartRef}></div>
				<div class="ct-tooltip fade" ref={chartTooltipRef}></div>
				<div class="ct-donut-inner" ref={chartDonutInnerRef}>
					<span class="ct-series-a fade">{percentage(completedSurveys.length, sentSurveys.length)}%</span>
				</div>
			</div>
			<div class="row chart-legend">
				<div class="col ct-series-d">
					<p>Total</p>
					{sentSurveys.length}
				</div>
				<div class="col ct-series-a">
					<p>Completed</p>
					{completedSurveys.length}
				</div>
				<div class="col ct-series-b">
					<p>Awaiting</p>
					{uncompletedSurveys.length}
				</div>
			</div>
		</div>
	)
}

export default defineComponent(SurveysChart, {
	surveys: requiredProp(Object),
})
