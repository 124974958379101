import type { Company } from "@/models"
import { setScrollbarWidth } from "@/utils/setScrollBehaviour"
import { computed, h, onMounted, provide, ref, watch } from "vue"
import {
	FormTabs,
	FormTabsRenderingMethod,
	RefQueries,
	defineComponent,
	optionalProp,
	refSetter,
	requiredProp,
	useQueryRef,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import CompanyForm from "./company-form"
import CompanyValues from "./company-values"
import { CompanyContext, CompanyContextInjectionKey } from "./context"

interface Props {
	company: Company
	edit?: boolean
}

const ManageCompany: ReactiveComponent<Props> = (props) => {
	const context = new CompanyContext(props.company)
	provide(CompanyContextInjectionKey, context)

	if (props.edit) context.edit = props.edit

	onMounted(setScrollbarWidth)

	const tabs = computed(() => {
		const tabs: FormTab[] = []

		tabs.push({
			id: "details",
			name: "Company Details",
			renderContent: CompanyForm,
		})

		if (context.edit) {
			tabs.push({
				id: "values",
				name: "Manage Values",
				renderContent: CompanyValues,
			})
		}

		return tabs
	})
	const tabQuery = useQueryRef("tab", tabs.value[0].id, RefQueries.String)
	const tabId = ref(tabQuery.value)

	watch(tabId, () => {
		tabQuery.value = tabId.value
	})

	return () => (
		<>
			{tabs.value.length > 1 ? (
				<FormTabs
					tabs={tabs.value}
					selectedTabId={tabId.value}
					setSelectedTab={refSetter(tabId)}
					content={FormTabsRenderingMethod.RenderActive}
				/>
			) : (
				h(tabs.value[0].renderContent!)
			)}
		</>
	)
}

export default defineComponent(ManageCompany, {
	company: requiredProp(Object),
	edit: optionalProp(Boolean),
})
