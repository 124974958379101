import type { Survey } from "@/models"
import { css } from "vite-css-in-js"
import { Transition, provide } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { SurveyContext, SurveyContextInjectionKey, SurveySection } from "../context"
import SurveyIntro from "./SurveyIntro"
import SurveyOutro from "./SurveyOutro"
import SurveyValues from "./SurveyValues"

interface Props {
	survey: Survey
}

const surveyStyles = css`
	position: absolute;
	inset: 0;
	overflow: hidden;

	section {
		position: absolute;
		width: 100%;
		height: 100%;
		margin-bottom: 5rem;
		text-wrap: balance;

		.section-inner {
			width: 85vw;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 72vh;
			overflow: hidden;
			overflow-y: auto;
			padding: 0 2.5vw;
			font-size: 0.8em;
		}

		h1 {
			margin-bottom: 2rem;
			color: var(--color-tenet-blue);
		}

		.survey-button {
			color: var(--color-tenet-blue);
			background-color: transparent;
			border: none;
			padding: 3rem;
			margin: -1rem -3rem 0;
			position: absolute;
			top: 0;

			&:first-child {
				left: 0;
			}

			&:last-child:not(:first-child) {
				right: 0;
			}

			.text {
				position: relative;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: -0.5rem;
					height: 2px;
					background-color: var(--color-tenet-orange);
					opacity: 0;
					transition: 0.3s;
				}

				& + .icon {
					margin-right: 0;
					margin-left: 1rem;
				}
			}

			.icon {
				position: relative;
				margin-right: 1rem;
				transition: 0.3s;
				left: 0;
			}

			&:hover,
			&:disabled {
				.text {
					&::after {
						opacity: 1;
						left: -0.5rem;
						right: -0.5rem;
					}

					& + .icon {
						left: 0.5rem;
					}
				}

				.icon {
					left: -0.5rem;
				}
			}

			&:disabled {
				cursor: wait;
			}
		}

		@media screen and (min-width: 768px) {
			.section-inner {
				width: 60vw;
				font-size: 1em;
			}
		}
	}
`

const SurveySections: ReactiveComponent<Props> = (props) => {
	const context = new SurveyContext(props.survey)
	provide(SurveyContextInjectionKey, context)

	return () => (
		<div class={surveyStyles}>
			{context.section === SurveySection.Intro && (
				<Transition name={context.sectionTransitionName}>
					<SurveyIntro />
				</Transition>
			)}

			{context.section === SurveySection.Values && (
				<Transition name={context.sectionTransitionName}>
					<SurveyValues />
				</Transition>
			)}

			{context.section === SurveySection.Outro && (
				<Transition name={context.sectionTransitionName}>
					<SurveyOutro />
				</Transition>
			)}
		</div>
	)
}

export default defineComponent(SurveySections, {
	survey: requiredProp(Object),
})
