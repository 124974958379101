import UserIndicator from "@/components/navigation/UserIndicator"
import type { Id, Person } from "@/models"
import { computed } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	people: Person[]
	searchTerm: string
	selectedPersonIds: Id[]
	existingPeople: Person[]
	selectPerson(person: Person): void
}

const PeopleList: ReactiveComponent<Props> = (props) => {
	const filteredPeople = computed(() =>
		props.people.filter((person) => {
			const fullName = `${person.firstName} ${person.lastName}`
			const lowerSearch = props.searchTerm.toLowerCase()

			return fullName.toLowerCase().includes(lowerSearch) || person.emailAddress.toLowerCase().includes(lowerSearch)
		})
	)

	const selectedPeople = computed(() =>
		filteredPeople.value.filter((person) => {
			const existingPersonIds = props.existingPeople.map((p) => p.id)
			return existingPersonIds.includes(person.id)
		})
	)

	const unSelectedPeople = computed(() =>
		filteredPeople.value.filter((person) => {
			const existingPersonIds = props.existingPeople.map((p) => p.id)
			return !existingPersonIds.includes(person.id)
		})
	)

	function renderPerson(person: Person) {
		return (
			<div
				class={["staff-member", { active: props.selectedPersonIds.includes(person.id) }]}
				key={person.id}
				onClick={() => props.selectPerson(person)}
				onKeypress={(e) => e.key === "Enter" && props.selectPerson(person)}
				role="button"
				tabindex={0}
			>
				<UserIndicator firstName={person.firstName} lastName={person.lastName} showName={true} />
				<span>
					{person.firstName} {person.lastName}
					<br />
					<small>{person.emailAddress}</small>
				</span>
			</div>
		)
	}

	return () => (
		<>
			{selectedPeople.value
				.sort((a, b) => {
					return a.lastName.localeCompare(b.lastName)
				})
				.map((person) => renderPerson(person))}

			{unSelectedPeople.value
				.sort((a, b) => {
					return a.lastName.localeCompare(b.lastName)
				})
				.map((person) => renderPerson(person))}
		</>
	)
}

export default defineComponent(PeopleList, {
	people: requiredProp(Array),
	searchTerm: requiredProp(String),
	selectedPersonIds: requiredProp(Array),
	existingPeople: requiredProp(Array),
	selectPerson: requiredProp(Function),
})
