import type { ValidationResult } from "@/components/form/BasicForm"
import SimpleForm from "@/components/form/SimpleForm"
import type { Company } from "@/models"
import { createCompany, updateCompany } from "@/services/companiesService"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"
import { Address, Branding, Industries, Name, Notes, PrimaryContact, Staff, Type } from "./FormFields"

const formStyles = css`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
`

async function create(company: Company) {
	await createCompany(company)
	window.location.href = "/companies"
}

async function edit(company: Company) {
	await updateCompany(company)
	window.location.href = "/companies"
}

function validateForm(company: Company): ValidationResult {
	if (!company.staff || company.staff.length === 0) {
		return "No Staff Members have been added. Please add a Staff Member to this Company."
	}
	if (!company.industries || company.industries.length === 0) {
		return "No Industries have been added. Please add an Industry to this Company."
	}

	return true
}

const CompanyForm: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	return () => (
		<SimpleForm
			class={formStyles}
			submit={{
				onClick: () => (context.edit ? edit(company) : create(company)),
				validate: () => validateForm(company),
				text: `${context.edit ? "Save" : "Add"} Company`,
			}}
		>
			<div class="row" style={{ display: "flex", flex: "100%" }}>
				<div class="col">
					<Name />
					<Address />
					<Staff />
					<Notes />
				</div>
				<div class="col">
					<Type />
					<PrimaryContact />
					<Industries />
					<Branding />
				</div>
			</div>
		</SimpleForm>
	)
}

export default defineComponent(CompanyForm)
