import ContractsTable from "@/components/ContractsTable"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { StateLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import type { Contract } from "@/models"
import { getContracts } from "@/services/contractsService"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { reactive } from "vue"
import { defineComponent, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"

const ContractsPage: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const contractsRef = useLoadableRef(async () => {
		const contracts = await getContracts()
		return reactive(contracts)
	})

	return () => (
		<BasicPage title="Contracts">
			<StateLoading stores={[contractsRef]}>
				<h1>Contracts</h1>
				<hr />

				<Tile>
					<ContractsTable contracts={ensureLoadingHasResult(contractsRef) as Contract[]} />
				</Tile>

				{loggedInUser.atLeastHasRole(SystemRole.Supplier) && (
					<div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
						<TenetButton url="/contracts/add">Add a Contract</TenetButton>
					</div>
				)}
			</StateLoading>
		</BasicPage>
	)
}

export default defineComponent(ContractsPage, {})
