import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import { reactive } from "vue"
import { defineComponent, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import UsersTable from "./UsersTable"
import { getUsers } from "./requests"

const Users: ReactiveComponent = () => {
	const usersRef = useLoadableRef(async () => {
		const users = await getUsers()
		return reactive(users)
	})

	return () => (
		<AuthorizeRole role={SystemRole.SuperAdmin}>
			<BasicPage title="Users">
				<ContentLoading stores={[usersRef]}>
					<h1>Users</h1>
					<hr />

					<Tile>
						<UsersTable users={ensureLoadingHasResult(usersRef)} />
					</Tile>

					<div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
						<TenetButton url="/users/add">Add a User</TenetButton>
					</div>
				</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(Users)
