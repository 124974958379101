import { ProfileRoutes } from "@/pages/navigation"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { computed, type HTMLAttributes } from "vue"
import { RouterLink } from "vue-router"
import { defineComponent, type NoProps, type ReactiveComponent } from "vue-utils"
import type { RouteInformation } from "./Navigation"

const ProfileNavigation: ReactiveComponent<NoProps, HTMLAttributes> = (_, { attrs }) => {
	const user = useLoggedInUser()

	const availableRoutes = computed(() => {
		const availableRoutes: Record<string, RouteInformation> = {}
		for (const url in ProfileRoutes) {
			const route = ProfileRoutes[url]
			if (!route.hasAccess || route.hasAccess(user)) {
				availableRoutes[url] = route
			}
		}
		return availableRoutes
	})

	return () => (
		<ul {...attrs}>
			{Object.entries(availableRoutes.value).map(([url, route]) => (
				<li key={url}>
					<RouterLink to={url} activeClass="nav-active-route">
						<span>{route.name}</span>
					</RouterLink>
				</li>
			))}
		</ul>
	)
}

export default defineComponent(ProfileNavigation)
