import ContractsTable from "@/components/ContractsTable"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { StateLoading } from "@/components/loading/StateLoading"
import type { Contract, Survey } from "@/models"
import { getStats } from "@/services/companiesService"
import { getContracts } from "@/services/contractsService"
import { getSurveys } from "@/services/surveyService"
import { css } from "vite-css-in-js"
import { defineComponent, reactive } from "vue"
import { ShadowContainer, ensureLoadingHasResult, useLoadableRef, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import ContractsChart from "./charts/ContractsChart"
import StatsChart from "./charts/StatsChart"
import SurveysChart from "./charts/SurveysChart"

const chartsStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 3em;
	margin: 2em 0 4em;

	.chart {
		flex: 0 1 calc((100% - 6em) / 3);
		display: flex;
		flex-direction: column;
	}
`

const DashboardPage: ReactiveComponent = () => {
	const surveysRef = useLoadableRef(async () => {
		const surveys = await getSurveys()
		return reactive(surveys)
	})

	const contractsRef = useLoadableRef(async () => {
		const contracts = await getContracts()
		return reactive(contracts)
	})

	const statsRef = useLoadableRef(async () => {
		const stats = await getStats()
		return reactive(stats)
	})

	return () => (
		<BasicPage title="Tenetech" plain>
			<StateLoading stores={[surveysRef, contractsRef, statsRef]}>
				<div class={chartsStyles}>
					<SurveysChart surveys={ensureLoadingHasResult(surveysRef) as Survey[]} />
					<ContractsChart contracts={ensureLoadingHasResult(contractsRef) as Contract[]} />
					<StatsChart stats={ensureLoadingHasResult(statsRef)} />
				</div>

				<ShadowContainer>
					<div class="d-flex align-items-center justify-content-between">
						<h2 style={{ margin: 0 }}>Active Contracts</h2>
						<TenetButton url="/contracts" small>
							View All
						</TenetButton>
					</div>

					<hr />
					<Tile>
						<ContractsTable contracts={ensureLoadingHasResult(contractsRef) as Contract[]} activeOnly />
					</Tile>
				</ShadowContainer>
			</StateLoading>
		</BasicPage>
	)
}

export default defineComponent(DashboardPage)
