import { ContentLoading } from "@/components/loading/StateLoading"
import { HttpLoadingError } from "@/services/httpHelper"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import { getPerson } from "../../services/peopleService"
import BasicPage from "../BasicPage"
import ManagePerson from "./ManagePerson"

const EditPersonPage: ReactiveComponent = () => {
	const router = useRouter()
	const personGuid = computed(() => router.currentRoute.value.params.id as string)
	const personRef = useLoadableRef(loadPerson)

	async function loadPerson() {
		try {
			return await getPerson(personGuid.value)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (personRef.type !== "done") {
			return null
		}
		const person = personRef.result

		return !person ? (
			<>
				<h1 class="mb-4">An error has occurred</h1>
				<p class="m-0">The person you requested could not be found.</p>
			</>
		) : (
			<>
				<h1 class="mb-4">
					Edit {person.firstName} {person.lastName}
				</h1>
				<hr />
				<ManagePerson person={person} edit />
			</>
		)
	}

	return () => (
		<BasicPage title="Edit Person">
			<ContentLoading stores={[personRef]}>{renderContent()}</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(EditPersonPage)
