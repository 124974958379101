import type { Milestone, MilestoneResponse } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useResponseFiltering(options: {
	milestones: MaybeRef<Milestone[]>
	responses: MaybeRef<MilestoneResponse[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<MilestoneResponse[]> {
	const { searchTerm: search, milestones, responses } = options

	return computed(() => {
		const responseArray = unref(responses)
		const searchTerm = unref(search).toLowerCase()

		if (searchTerm.length === 0) {
			return responseArray
		}

		return unref(responseArray).filter((response) => {
			const matchedMilestones = unref(milestones).filter((milestone) =>
				milestone.name.toLowerCase().includes(searchTerm)
			)
			const responseIds = matchedMilestones.flatMap(
				(milestone) => milestone.responses?.map((response) => response.id) ?? []
			)
			return responseIds.includes(response.id)
		})
	})
}
