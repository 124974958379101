import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import { HttpLoadingError } from "@/services/httpHelper"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import ManageUser from "./ManageUser"
import { getUser } from "./requests"

const EditUserPage: ReactiveComponent = () => {
	const router = useRouter()
	const userGuid = computed(() => router.currentRoute.value.params.id as string)
	const userRef = useLoadableRef(loadUser)

	async function loadUser() {
		try {
			return await getUser(userGuid.value)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (userRef.type !== "done") {
			return null
		}
		const user = userRef.result

		return !user ? (
			<>
				<h1 class="mb-4">An error has occurred</h1>
				<p class="m-0">The user you requested could not be found.</p>
			</>
		) : (
			<>
				<h1 class="mb-4">
					Edit {user.firstName} {user.lastName}
				</h1>
				<hr />
				<ManageUser user={user} edit />
			</>
		)
	}

	return () => (
		<AuthorizeRole role={SystemRole.SuperAdmin}>
			<BasicPage title="Edit Person">
				<ContentLoading stores={[userRef]}>{renderContent()}</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(EditUserPage)
