import SimpleForm from "@/components/form/SimpleForm"
import Tile from "@/components/form/Tile"
import { MilestoneValueType } from "@/enums"
import type { MilestoneResponse } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { ModalTitle, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	response: MilestoneResponse
	milestoneName: string
	close(): void
}

const tableStyles = css`
	[class^="tile"]:not(.tile-heading) {
		border: 1px solid rgba(var(--color-tenet-darkgrey-rgb), 0.3);
	}
`

const ResponseModal: ReactiveComponent<Props> = (props) => {
	const response = proxyProp(props, "response")

	const groupedValues = response.responseValues
		?.map((value, index, values) => {
			if (!(index % 2)) return null
			const linkedValues = values.filter((_) => _.milestoneValue.id === value.milestoneValue.id)
			if (!linkedValues.length) return null

			const milestoneValue = linkedValues[0].milestoneValue

			return {
				id: milestoneValue.id,
				name: milestoneValue.name,
				scores: linkedValues.map((_) => {
					return {
						value: _.value,
						type: _.type,
					}
				}),
			}
		})
		.filter((value, index, values) => value && values.map((_) => _?.id).indexOf(value?.id) === index)

	return () => (
		<>
			<ModalTitle title={props.milestoneName} />
			<hr />
			<SimpleForm
				cancel={{
					text: "Close",
					onClick: props.close,
				}}
				class={tableStyles}
			>
				<div class="row">
					<div class="col">
						<Tile>
							<label class="tile-heading">Response Date</label>
							<input class="form-control" type="date" value={response.responseDate} disabled />
						</Tile>
					</div>
					<div class="col">
						<Tile class="me-0">
							<label class="tile-heading">Assessment Score</label>
							<input class="form-control" type="text" value={response.assessmentScore + "%"} disabled />
						</Tile>
					</div>
				</div>
				{groupedValues &&
					groupedValues.map(
						(value) =>
							value && (
								<div class="row" key={value.id}>
									<div class="col">
										<Tile class="me-0">
											<label class="tile-heading">{value.name}</label>
											<hr class="mt-0" />
											{value.scores.map((score) => (
												<div class="my-3" key={score.type}>
													<p class="fw-normal mb-2">
														{score.type === MilestoneValueType.rating && "How would you rate the value above?"}
														{score.type === MilestoneValueType.confidence && "How confident are you in your answer?"}
														{score.type !== MilestoneValueType.rating &&
															score.type !== MilestoneValueType.confidence &&
															score.type}
													</p>
													<div class="d-flex gap-4">
														<input type="range" id="valueWeight" class="form-range" disabled value={score.value} />
														<span style={{ flexShrink: "0" }}>{score.value}%</span>
													</div>
												</div>
											))}
										</Tile>
									</div>
								</div>
							)
					)}
			</SimpleForm>
		</>
	)
}

export default defineComponent(ResponseModal, {
	response: requiredProp(Object),
	milestoneName: requiredProp(String),
	close: requiredProp(Function),
})
