import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import { HttpLoadingError } from "@/services/httpHelper"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import { getCompany } from "../../services/companiesService"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import ManageCompany from "./manage-company"

const EditCompanyPage: ReactiveComponent = () => {
	const router = useRouter()
	const companyGuid = computed(() => router.currentRoute.value.params.id as string)
	const companyRef = useLoadableRef(loadCompany)

	async function loadCompany() {
		try {
			return await getCompany(companyGuid.value)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (companyRef.type !== "done") {
			return null
		}
		const company = companyRef.result

		return !company ? (
			<>
				<h1 class="mb-4">An error has occurred</h1>
				<p class="m-0">The company you requested could not be found.</p>
			</>
		) : (
			<>
				<h1 class="mb-4">Edit {company.name}</h1>
				<hr />
				<ManageCompany company={company} edit />
			</>
		)
	}

	return () => (
		<AuthorizeRole role={SystemRole.SuperAdmin}>
			<BasicPage title="Edit Company">
				<ContentLoading stores={[companyRef]}>{renderContent()}</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(EditCompanyPage)
