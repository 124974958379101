import { getRolePriority, type SystemRole } from "@/enums"
import { type ApiTypeOf, type Id, type Nullable, type Person } from "."

export interface UserDetails {
	id: Id
	guid: string
	firstName: string
	lastName: string
	emailAddress: string
	role: SystemRole
	person: Nullable<Person>
	password: Nullable<string>
}

export class User implements UserDetails {
	id: Id
	guid: string
	firstName: string
	lastName: string
	emailAddress: string
	role: SystemRole
	person: Nullable<Person>
	password: Nullable<string>

	constructor(user: UserDetails) {
		this.id = user.id
		this.guid = user.guid
		this.firstName = user.firstName
		this.lastName = user.lastName
		this.emailAddress = user.emailAddress
		this.role = user.role
		this.person = user.person
		this.password = user.password
	}

	atLeastHasRole(role: SystemRole): boolean {
		const ownPriority = getRolePriority(this.role)
		const rolePriority = getRolePriority(role)

		return ownPriority >= rolePriority
	}
}

export function mapApiUser(user: ApiTypeOf<User>): User {
	return {
		...user,
	}
}
