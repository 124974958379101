import Icon from "@/components/Icon"
import Blade, { closeBlade, openBlade } from "@/components/form/Blade"
import BootstrapButton from "@/components/form/BootstrapButton"
import { SystemRole } from "@/enums"
import { type Milestone, type Nullable } from "@/models"
import { ContractSection, useContractContext } from "@/pages/contracts/context"
import { RequestMethod, httpRequestJsonResponse } from "@/services/httpService"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { faFlag } from "@fortawesome/free-regular-svg-icons"
import { faArrowLeft, faArrowRight, faFlagCheckered, faMapPin, faPlus } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import _ from "lodash"
import { css } from "vite-css-in-js"
import { Teleport, ref, toRaw } from "vue"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import { validateMilestone, validateMilestones } from "../../validation"
import ManageMilestone from "./ManageMilestone"

const milestoneStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	gap: 3rem;

	.milestone-line {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		width: 5px;
		background: var(--color-tenet-blue);
		transform: translateX(-50%);
		z-index: 0;
	}

	.milestone,
	.milestone-add {
		display: flex;
		gap: 2rem;
		justify-content: center;
		align-items: center;
		position: relative;

		p {
			font-size: 1.2rem;
			margin: 0;
			width: 9rem;
		}

		.milestone-icon {
			width: 80px;
			height: 80px;
			display: grid;
			place-content: center;
			font-size: 1.8em;
			border-radius: 100px;
			background: var(--color-tenet-blue);
			color: #fff;
		}

		&.editable {
			cursor: pointer;

			.milestone-icon {
				position: relative;

				&::after {
					content: "\f040";
					font: var(--fa-font-solid);
					color: var(--color-tenet-black);
					position: absolute;
					right: -1em;
					top: -0.2em;
					font-size: 1.2rem;
				}
			}
		}
	}

	.milestone-add {
		cursor: pointer;

		p {
			font-size: 1.1rem;
		}

		.milestone-icon {
			width: 40px;
			height: 40px;
			font-size: 1.3em;
		}
	}
`

const MilestonesSection: ReactiveComponent = () => {
	const context = useContractContext()
	const contract = proxyProp(context, "contract")

	const loggedInUser = useLoggedInUser()

	const editingMilestone = ref<Milestone | null>(null)

	const initialMilestones = ref<Milestone[]>([
		{
			id: 0,
			guid: crypto.randomUUID(),
			name: "End",
			assessmentScore: 0,
			milestoneWeightedValues: [],
			minimumResponsePercentage: 100,
			responses: [],
			reviewDate: contract.completionDate,
			status: "Active",
		},
	])

	const newMilestone = ref<Milestone>({
		id: -1,
		guid: crypto.randomUUID(),
		name: "",
		assessmentScore: 0,
		milestoneWeightedValues: [],
		minimumResponsePercentage: 100,
		responses: [],
		reviewDate: contract.startDate.isAfter(LocalDate.now()) ? contract.startDate : LocalDate.now(),
		status: "Active",
	})

	if (!contract.milestones.length) contract.milestones = initialMilestones.value as Milestone[]

	function editMilestone(guid: Nullable<string> = null) {
		const milestone = contract.milestones.find((milestone) => milestone.guid === guid) ?? newMilestone.value
		const rawMilestone = _.cloneDeep(toRaw(milestone))
		if (rawMilestone.id === -1) rawMilestone.guid = crypto.randomUUID()

		editingMilestone.value = rawMilestone
		openBlade()
	}

	function validate(milestone: Milestone) {
		const errors = validateMilestone(milestone, contract, context.client!.name)

		if (errors.length > 0) return errors

		return true
	}

	function saveMilestone(milestone: Milestone): string {
		// TODO - Permission based validation
		// if (!milestone.milestoneWeightedValues || milestone.milestoneWeightedValues.length === 0) {
		// 	return "No Values have been added. Please add a value to this Milestone."
		// }

		const result = validate(milestone)
		if (!context.parseValidationResult(result)) return ""

		if (milestone.id === -1) {
			milestone.id = 0
			contract.milestones.push(milestone)
		} else {
			const existingMilestone = contract.milestones.find((ms) => ms.guid === milestone.guid)
			if (existingMilestone) Object.assign(existingMilestone, milestone)
		}

		closeBlade()
		return ""
	}

	function onTriggerMilestoneClick(evt: MouseEvent, milestone: Milestone) {
		evt.preventDefault()
		evt.stopPropagation()

		void triggerMilestone(milestone)

		void usePopups().showAlertPopup(() => (
			<Alert title="Milestone Triggered">
				<p>
					The <em>{milestone.name}</em> Milestone has been triggered successfully.
				</p>
				<p>Survey emails have been sent to the specified Stakeholders.</p>
			</Alert>
		))

		return false
	}

	async function triggerMilestone(milestone: Milestone) {
		await httpRequestJsonResponse(`/api/surveys/send/${milestone.id}`, RequestMethod.POST)
	}

	context.validate = () => {
		const errors = validateMilestones(contract, context.client!.name)

		if (errors.length > 0) return errors

		return true
	}

	return () => (
		<>
			<div class={milestoneStyles}>
				{contract.milestones.length > 0 && (
					<>
						<div class="milestone-line"></div>
						<div class="milestone">
							<p>
								<strong>Start</strong>
								<br />
								{contract.startDate.toGBDateString()}
							</p>
							<div class="milestone-icon">
								<Icon icon={faFlag} />
							</div>
							<p>&nbsp;</p>
						</div>
						<div
							class="milestone-add"
							onClick={() => editMilestone()}
							onKeypress={(event) => event.key === "Enter" && editMilestone()}
							role="button"
							tabindex={0}
						>
							<p>&nbsp;</p>
							<div class="milestone-icon">
								<Icon icon={faPlus} />
							</div>
							<p>Add a Milestone</p>
						</div>
					</>
				)}
				{contract.milestones
					.sort((a, b) => a.reviewDate.compareTo(b.reviewDate))
					.map((milestone, milestoneIndex) => (
						<>
							<div
								class="milestone editable"
								key={milestone.name}
								onClick={() => editMilestone(milestone.guid)}
								onKeypress={(event) => event.key === "Enter" && editMilestone(milestone.guid)}
								role="button"
								tabindex={0}
							>
								<p>
									<strong>{milestone.name}</strong>
									<br />
									{milestone.reviewDate.toGBDateString()}
								</p>
								<div class="milestone-icon">
									<Icon icon={milestoneIndex === contract.milestones.length - 1 ? faFlagCheckered : faMapPin} />
								</div>
								<p>
									&nbsp;
									{loggedInUser.atLeastHasRole(SystemRole.SuperAdmin) && contract.active && (
										<BootstrapButton
											color="secondary"
											class="btn-sm"
											onClick={(evt: MouseEvent) => onTriggerMilestoneClick(evt, milestone)}
										>
											Trigger Milestone
										</BootstrapButton>
									)}
								</p>
							</div>

							{milestoneIndex < contract.milestones.length - 1 && (
								<div
									class="milestone-add"
									onClick={() => editMilestone()}
									onKeypress={(event) => event.key === "Enter" && editMilestone()}
									role="button"
									tabindex={0}
								>
									<p>&nbsp;</p>
									<div class="milestone-icon">
										<Icon icon={faPlus} />
									</div>
									<p>Add a Milestone</p>
								</div>
							)}
						</>
					))}
			</div>
			{context.entryType === "create" && (
				<div class="d-flex justify-between mt-5">
					<BootstrapButton
						color="secondary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.Stakeholders, false)}
					>
						<Icon icon={faArrowLeft} />
						Back
					</BootstrapButton>
					<BootstrapButton
						color="primary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.Review, true)}
					>
						Next
						<Icon icon={faArrowRight} />
					</BootstrapButton>
				</div>
			)}

			<Teleport to="body">
				<Blade>
					{editingMilestone.value && (
						<>
							<h2>{editingMilestone.value.id >= 0 ? "Edit" : "Add"} Milestone</h2>
							<ManageMilestone
								supplier={context.supplier!}
								client={context.client!}
								milestone={editingMilestone.value as Milestone}
								saveMilestone={saveMilestone}
							/>
						</>
					)}
				</Blade>
			</Teleport>
		</>
	)
}

export default defineComponent(MilestonesSection)
