import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import BootstrapButton from "@/components/form/BootstrapButton"
import ExistingFiles from "@/components/form/ExistingFiles/ExistingFiles"
import FileUpload from "@/components/form/FileUpload/FileUpload"
import Tile from "@/components/form/Tile"
import type { UploadedFile } from "@/models"
import { removeUploadedFile } from "@/services/contractsService"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { ref, watch } from "vue"
import { Required, defineComponent, refSetter, useOnInput, type ReactiveComponent } from "vue-utils"
import { ContractSection, useContractContext } from "../../context"
import { validateAbout } from "../../validation"
import UploadAdditionalFiles from "./UploadAdditionalFiles"

const AboutContractSection: ReactiveComponent = () => {
	const context = useContractContext()

	const contract = proxyProp(context, "contract")

	const documents = ref<File[]>(contract.filesForUpload ?? [])
	watch(documents, () => (contract.filesForUpload = documents.value))

	const uploadedDocuments = ref(contract.uploadedFiles ?? [])
	const uploadingAdditionalDocuments = ref(false)

	const formRef = ref<HTMLFormElement>()

	context.validate = () => {
		const form = formRef.value

		if (!form) return false
		if (!form.reportValidity()) return false

		const errors = validateAbout(contract, context.entryType)
		if (errors.length > 0) return errors

		return true
	}

	async function removeFile(file: UploadedFile) {
		const removedFile = await removeUploadedFile(contract, file)
		const index = uploadedDocuments.value.map((doc) => doc.id).indexOf(removedFile.id)
		uploadedDocuments.value.splice(index, 1)
	}

	return () => (
		<form
			ref={formRef}
			onSubmit={(e) => {
				e.preventDefault()
				e.stopImmediatePropagation()
				return false
			}}
		>
			<div class="row">
				<div class="col">
					<Tile>
						<label for="contractSupplier" class="tile-heading">
							<Required label="Supplier" />
						</label>
						<input type="text" id="contractSupplier" v-model={context.supplier!.name} class="form-control" disabled />
					</Tile>
					<Tile>
						<label for="contractName" class="tile-heading">
							<Required label="Contract Name" />
						</label>
						<input
							type="text"
							id="contractName"
							v-model={contract.name}
							placeholder="Contract Name"
							class="form-control"
							required
						/>
					</Tile>
					<Tile>
						<label for="contractDescription" class="tile-heading">
							Description
						</label>
						<textarea
							id="contractDescription"
							v-model={contract.description}
							class="form-control"
							placeholder="Description"
							rows={4}
						></textarea>
					</Tile>
					<Tile>
						<label for="contractFullText" class="tile-heading">
							Full Text
						</label>
						<textarea
							id="contractFullText"
							v-model={contract.fullText}
							class="form-control"
							placeholder="Full Text"
							rows={8}
						></textarea>
					</Tile>
				</div>
				<div class="col">
					<Tile>
						<label for="contractClient" class="tile-heading">
							<Required label="Client" />
						</label>
						<input type="text" id="contractClient" v-model={context.client!.name} class="form-control" disabled />
					</Tile>
					<Tile>
						<label for="contractStartDate" class="tile-heading">
							Contract Length
						</label>

						<div class="form-floating mb-3">
							<input
								type="date"
								id="contractStartDate"
								value={contract.startDate.toHTMLInput()}
								onChange={useOnInput((txt) => (contract.startDate = LocalDate.fromHtmlInput(txt)))}
								placeholder="Start Date *"
								class="form-control"
								required
							/>
							<label for="contractStartDate">Start Date *</label>
						</div>
						<div class="form-floating mb-3">
							<input
								type="date"
								id="contractCompletionDate"
								value={contract.completionDate.toHTMLInput()}
								onChange={useOnInput((txt) => (contract.completionDate = LocalDate.fromHtmlInput(txt)))}
								placeholder="Completion Date *"
								class="form-control"
								required
							/>
							<label for="contractCompletionDate">Completion Date *</label>
						</div>
					</Tile>
					<Tile>
						<label for="contractFileUpload" class="tile-heading">
							Documents
						</label>
						{context.entryType === "create" ? (
							<FileUpload
								id="contractFileUpload"
								onSelectionChanged={refSetter(documents)}
								initialFiles={contract.filesForUpload ?? []}
								multiple
							/>
						) : (
							<>
								<div class="d-flex flex-column align-items-center">
									<ExistingFiles
										files={(uploadedDocuments.value as UploadedFile[]) ?? []}
										remove={(file) => void removeFile(file)}
									/>
									<BootstrapButton
										color="primary"
										class="mt-2"
										onClick={() => (uploadingAdditionalDocuments.value = true)}
									>
										Upload {uploadedDocuments.value.length ? "another" : "a"} Document
									</BootstrapButton>
								</div>

								{uploadingAdditionalDocuments.value && (
									<Modal style={{ width: "56rem", height: "25rem" }}>
										<UploadAdditionalFiles
											cancel={() => (uploadingAdditionalDocuments.value = false)}
											onUploaded={(newFiles: UploadedFile[]) => {
												uploadedDocuments.value.push(...newFiles)
												uploadingAdditionalDocuments.value = false
											}}
										/>
									</Modal>
								)}
							</>
						)}
					</Tile>
				</div>
			</div>
			{context.entryType === "create" && (
				<div class="d-flex justify-between">
					<BootstrapButton
						color="secondary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.Client, false)}
					>
						<Icon icon={faArrowLeft} />
						Back
					</BootstrapButton>
					<BootstrapButton
						color="primary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.Stakeholders, true)}
					>
						Next
						<Icon icon={faArrowRight} />
					</BootstrapButton>
				</div>
			)}
		</form>
	)
}

export default defineComponent(AboutContractSection)
