import { SystemRole } from "@/enums"
import { AppRoutes, CreateRoutes } from "@/pages/navigation"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, type Ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import NavWithToggle from "./NavWithToggle"

const navigationStyles = css`
	display: flex;
	gap: 1rem;

	& > nav {
		position: relative;

		.backdrop {
			position: fixed;
			inset: 0;
		}
	}
`

const NavPanel: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const createNavOpen = ref(false)
	const profileNavOpen = ref(false)

	function toggleNav(navToToggle: Ref<boolean>, navToClose: Ref<boolean>) {
		navToToggle.value = !navToToggle.value
		navToClose.value = false
	}

	return () => (
		<div class={navigationStyles}>
			{loggedInUser.atLeastHasRole(SystemRole.Client) && (
				<NavWithToggle
					navOpen={createNavOpen}
					icon={faPlus}
					profileNav={false}
					routes={CreateRoutes}
					toggleNav={() => toggleNav(createNavOpen, profileNavOpen)}
				/>
			)}

			<NavWithToggle
				navOpen={profileNavOpen}
				icon={faBars}
				profileNav={true}
				routes={AppRoutes}
				toggleNav={() => toggleNav(profileNavOpen, createNavOpen)}
			/>
		</div>
	)
}

export default defineComponent(NavPanel)
