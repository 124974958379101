import type { Person } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import ManagePerson from "./ManagePerson"

const AddPersonPage: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const newPerson: Person = {
		id: 0,
		guid: crypto.randomUUID(),
		firstName: "",
		lastName: "",
		emailAddress: "",
		phoneNumber: "",
		notes: "",
		active: true,
		companyId: loggedInUser.person?.companyId ?? null,
	}

	return () => (
		<BasicPage title="Add Person">
			<h1>Add a new Person</h1>
			<hr />
			<ManagePerson person={newPerson} />
		</BasicPage>
	)
}

export default defineComponent(AddPersonPage)
