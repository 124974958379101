import StaticProgressBar from "@/components/StaticProgressBar"
import type { CompanyStats } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	stats: CompanyStats
}

const StatsChart: ReactiveComponent<Props> = (props) => {
	const stats = proxyProp(props, "stats")

	return () => (
		<div class="chart">
			<h2 class="text-center">At a glance</h2>
			<div class="d-flex flex-column justify-between flex-1 mt-3">
				<div class="bars">
					<StaticProgressBar
						title="Average Value Score"
						value={stats.averageValueScore}
						maximum={100}
						animate={true}
						duration={2500}
						delay={0}
						className="ct-series-a"
					/>
					<StaticProgressBar
						title="Average Value Confidence"
						value={stats.averageValueConfidence}
						maximum={100}
						animate={true}
						duration={2250}
						delay={250}
						className="ct-series-b"
					/>
					<StaticProgressBar
						title="Average Assessment Score"
						value={stats.averageAssessmentScore}
						maximum={100}
						animate={true}
						duration={2000}
						delay={500}
						className="ct-series-d"
					/>
				</div>

				<div class="row chart-legend">
					<div class="col ct-series-d">
						<p>Clients</p>
						{stats.totalClients}
					</div>
					<div class="col ct-series-b">
						<p>Industries</p>
						{stats.totalIndustries}
					</div>
					<div class="col ct-series-a">
						<p>Staff</p>
						{stats.totalStaff}
					</div>
				</div>
			</div>
		</div>
	)
}

export default defineComponent(StatsChart, {
	stats: requiredProp(Object),
})
