import type { Contract } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { LocalDate } from "@js-joda/core"
import { PieChart, easings, type AnimationDefinition, type PieChartData, type PieChartOptions } from "chartist"
import { onMounted, ref } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	contracts: Contract[]
}

const ContractsChart: ReactiveComponent<Props> = (props) => {
	const contracts = proxyProp(props, "contracts")

	const chartBackgroundRef = ref<HTMLDivElement>()
	const chartRef = ref<HTMLDivElement>()
	const chartTooltipRef = ref<HTMLDivElement>()

	const activeStatusContracts = contracts.filter((x) => x.active)
	const pendingContracts = contracts.filter((x) => !x.active)
	const closedContracts = activeStatusContracts.filter((x) => x.completionDate.isBefore(LocalDate.now()))
	const activeContracts = activeStatusContracts.filter((x) => !closedContracts.includes(x))

	const animationDuration = 2250

	const labels: string[] = []
	const seriesData: number[] = []

	if (activeContracts.length > 0) {
		labels.push("Active Contracts")
		seriesData.push(activeContracts.length)
	}
	if (pendingContracts.length > 0) {
		labels.push("Pending Contracts")
		seriesData.push(pendingContracts.length)
	}
	if (closedContracts.length > 0) {
		labels.push("Closed Contracts")
		seriesData.push(closedContracts.length)
	}

	const data: PieChartData = {
		series: seriesData,
	}

	const baseOptions: PieChartOptions = {
		donut: true,
		donutWidth: "100%",
		chartPadding: 20,
		showLabel: false,
	}

	const displayOptions: PieChartOptions = {
		...baseOptions,
		labelInterpolationFnc: (value: number) => {
			return Math.round((value / contracts.length) * 100) + "%"
		},
		labelDirection: "explode",
		labelOffset: 90,
		showLabel: true,
	}

	function renderCharts() {
		const chartBackground = chartBackgroundRef.value as HTMLDivElement
		const chart = chartRef.value as HTMLDivElement
		if (chartBackground == null || chart == null) return

		renderChart(chartBackground, { series: [1] }, baseOptions, false)
		renderChart(chart, data, displayOptions, true)
	}

	function renderChart(chart: HTMLDivElement, data: PieChartData, options: PieChartOptions, animate: boolean) {
		const piechart = new PieChart(chart, data, options)

		if (!animate) return

		piechart.on("created", (data) => {
			const tooltip = chartTooltipRef.value as HTMLDivElement
			if (tooltip == null) return

			const series = data.svg.getNode().querySelectorAll<HTMLElement>(".ct-series")
			series.forEach((slice, index) => {
				const label = labels[index]
				const value = seriesData[index]
				const seriesClass = Array.from(slice.classList).find((x) => x.startsWith("ct-series-"))

				slice.addEventListener("mouseenter", () => {
					seriesClass && tooltip.classList.add(seriesClass)
					tooltip.innerHTML = `<p class="fw-medium mb-0">${label}: ${value.toFixed()}</p>`
					tooltip.classList.add("show")
				})

				slice.addEventListener("mousemove", (evt: MouseEvent) => {
					tooltip.style.left = `${evt.pageX + 15}px`
					tooltip.style.top = `${evt.pageY + 25}px`
				})

				slice.addEventListener("mouseleave", () => {
					tooltip.classList.remove("show")
					seriesClass && tooltip.classList.remove(seriesClass)
				})
			})
		})

		piechart.on("draw", (data) => {
			if (data.type === "label") {
				const animationDefinition: Record<string, AnimationDefinition> = {
					opacity: {
						id: "anim-label" + data.index,
						dur: animationDuration,
						from: 0,
						to: 1,
						easing: easings.easeInOutCubic,
						fill: "freeze",
					},
				}

				//if (data.index !== 0) animationDefinition.opacity.begin = animationDuration * data.index - 200

				data.element.attr({
					opacity: 0,
				})

				data.element.animate(animationDefinition, false)
			}

			if (data.type === "slice") {
				const pathLength = data.element.getNode<SVGGeometryElement>().getTotalLength()

				data.element.attr({
					"stroke-dasharray": `${pathLength}px ${pathLength}px`,
				})

				const animationDefinition: Record<string, AnimationDefinition> = {
					"stroke-dashoffset": {
						id: "anim" + data.index,
						dur: animationDuration,
						from: -pathLength + "px",
						to: "0px",
						easing: easings.easeInOutCubic,
						fill: "freeze",
					},
				}

				//if (data.index !== 0) animationDefinition["stroke-dashoffset"].begin = animationDuration * data.index - 160

				data.element.attr({
					"stroke-dashoffset": -pathLength + "px",
				})

				data.element.animate(animationDefinition, false)
			}
		})
	}

	onMounted(renderCharts)

	return () => (
		<div class="chart">
			<h2 class="text-center">Contracts</h2>
			<div class="full-chart">
				<div class="ct-chart ct-square ct-chart-background" ref={chartBackgroundRef}></div>
				<div class="ct-chart ct-square ct-chart-display" ref={chartRef}></div>
				<div class="ct-tooltip fade" ref={chartTooltipRef}></div>
			</div>
			<div class="row chart-legend">
				<div class="col ct-series-d">
					<p>Total</p>
					{contracts.length}
				</div>
				<div class="col ct-series-a">
					<p>Active</p>
					{activeContracts.length}
				</div>
				<div class="col ct-series-b">
					<p>Pending</p>
					{pendingContracts.length}
				</div>
				<div class="col ct-series-c">
					<p>Closed</p>
					{closedContracts.length}
				</div>
			</div>
		</div>
	)
}

export default defineComponent(ContractsChart, {
	contracts: requiredProp(Object),
})
