import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import type { Person } from "@/models"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { usePersonFiltering } from "./filtering"

interface Props {
	people: Person[]
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const PeopleTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")

	const filteredPeople = usePersonFiltering({
		people: toRef(props, "people"),
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredPeople)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<ColumnTable
				getKey={(person) => person.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "auto",
						renderContent: ({ item: person }) => `${person.firstName} ${person.lastName}`,
					},
					email: {
						label: "Email",
						size: "auto",
						renderContent: ({ item: person }) => person.emailAddress,
					},
					phoneNumber: {
						label: "Phone Number",
						size: "auto",
						renderContent: ({ item: person }) => person.phoneNumber,
					},
					notes: {
						label: "Notes",
						size: "auto",
						renderContent: ({ item: person }) =>
							person.notes && (person.notes?.length > 100 ? person.notes?.substring(0, 100) + "..." : person.notes),
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: person }) => (
							<a href={`/people/edit/${person.guid}`} class="btn btn-secondary">
								<Icon icon={faEdit} /> <span>Edit</span>
							</a>
						),
					},
				}}
			/>

			<PagingControls entityName="People" />
		</>
	)
}

export default defineComponent(PeopleTable, {
	people: requiredProp(Array),
})
