import Icon from "@/components/Icon"
import Tile from "@/components/form/Tile"
import type { Industry } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { computed, ref, watch } from "vue"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const industriesStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;

	.industry {
		padding: 0 1em;
		border-radius: 500em;
		background: var(--color-tenet-blue);
		color: var(--color-white);
		font-weight: 400;
		cursor: pointer;
		transition: background 0.3s;
		display: flex;
		align-items: center;

		&:hover {
			background: var(--color-tenet-blue-darker);
		}
	}

	.addIndustry {
		position: relative;
		width: 35%;

		.industries-list {
			position: absolute;
			top: 100%;
			overflow: auto;
			width: 100%;
			border-bottom-left-radius: var(--bs-border-radius);
			border-bottom-right-radius: var(--bs-border-radius);
			border: var(--bs-border-width) solid var(--bs-border-color);
			z-index: 10;

			option {
				padding: 0.175rem 0.75rem;
			}
		}
	}
`

const Industries: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	const industries = ref<Industry[]>(company.industries ?? [])

	const industrySearch = ref<string | null>(null)
	const industryNameRef = ref<HTMLInputElement>()

	const matchedIndustries = computed(() => {
		if (industrySearch.value === null || industrySearch.value === "") {
			return []
		}
		const value = industrySearch.value.toLowerCase()
		const savedIndustries = Array.from(context.industries.values())
		return savedIndustries
			.filter(
				(i) =>
					!company.industries?.map((i) => i.id).includes(i.id) && i.name.toLowerCase().includes(value.toLowerCase())
			)
			.sort((a, b) => a.name.toLowerCase().indexOf(value) - b.name.toLowerCase().indexOf(value))
	})

	watch(industryNameRef, () => {
		const input = industryNameRef.value as HTMLInputElement
		if (input) {
			input.focus()
		}
	})

	return () => (
		<Tile>
			<label class="tile-heading">
				<Required label="Industries" />
			</label>
			<div class={industriesStyles}>
				{industries.value.map((industry) => (
					<span key={industry.name} class="industry">
						{industry.name}
						<button
							class="btn btn-transparent text-light ms-2 ps-2 pe-1"
							onClick={() => {
								const index = industries.value.indexOf(industry)
								industries.value.splice(index, 1)
							}}
						>
							<Icon icon={faClose} />
						</button>
					</span>
				))}
				{industrySearch.value === null ? (
					<span
						class="industry"
						onClick={() => (industrySearch.value = "")}
						onKeypress={(e) => e.key === "Enter" && (industrySearch.value = "")}
						role="button"
						tabindex={0}
						style={{ height: "38px" }}
					>
						<Icon icon={faPlus} />
					</span>
				) : (
					<div class="addIndustry">
						<div class="input-group">
							<input
								type="text"
								placeholder="Industry Name"
								v-model={industrySearch.value}
								class="form-control"
								ref={industryNameRef}
							/>
							<button type="button" class="btn btn-outline-danger" onClick={() => (industrySearch.value = null)}>
								<Icon icon={faClose} />
							</button>
						</div>
						{matchedIndustries.value.length > 0 && (
							<select
								size={matchedIndustries.value.length > 10 ? 10 : matchedIndustries.value.length}
								multiple={matchedIndustries.value.length === 1}
								class="industries-list"
								onKeypress={(e) => {
									e.preventDefault()
									if (e.key !== "Enter") {
										return
									}
									const industryId = (e.target as HTMLSelectElement).value
									const industry = matchedIndustries.value.find((i) => i.id === parseInt(industryId))
									if (!industry) {
										return
									}
									industries.value.push(industry)
									industrySearch.value = null
								}}
							>
								{matchedIndustries.value.map((industry) => (
									<option
										key={industry.id}
										value={industry.id}
										onDblclick={() => {
											industries.value.push(industry)
											industrySearch.value = null
										}}
									>
										{industry.name}
									</option>
								))}
							</select>
						)}
					</div>
				)}
			</div>
		</Tile>
	)
}

export default defineComponent(Industries)
