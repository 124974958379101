import type { UploadedFile } from "@/models"
import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import ExistingFileRow from "./ExistingFileRow"

interface Props {
	files: UploadedFile[]
	remove?(file: UploadedFile): void
}

const tableStyles = css`
	table-layout: auto;
	border-collapse: collapse;
	width: 100%;

	grid-template-columns: minmax(1em, 1fr) auto auto max-content max-content;

	td,
	th {
		padding: 0.35rem 0.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);

		display: flex;
		column-gap: 0.25rem;
		align-items: center;

		font-size: 1rem;

		svg {
			color: #333;
		}
	}

	th {
		padding: 0.2rem 0.5rem;
		background-color: var(--color-fill);
		font-weight: 500;
	}

	td {
		&:first-child {
			column-gap: 0.5rem;
			svg {
				font-size: 1.25rem;
			}
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&:nth-child(3) svg {
			color: var(--color-tenet-blue);
		}

		&:nth-child(4) {
			text-align: right;
			justify-content: end;
		}

		&:last-child {
			svg {
				color: white;
			}
		}
	}
`

const ExistingFiles: ReactiveComponent<Props> = (props) => {
	return () =>
		props.files.length === 0 ? (
			<p class="m-0">No files have been uploaded.</p>
		) : (
			<table class={["grid-table", tableStyles]}>
				<thead>
					<tr>
						<th>File</th>
						<th>Date</th>
						<th>Uploaded By</th>
						<th>Size</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{props.files.map((file) => (
						<ExistingFileRow key={file.guid} file={file} remove={props.remove} />
					))}
				</tbody>
			</table>
		)
}

export default defineComponent(ExistingFiles, {
	files: requiredProp(Array),
	remove: optionalProp(Function),
})
