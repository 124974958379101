/* eslint-disable new-cap */

import { GetColorName } from "hex-color-to-color-name"
import { css } from "vite-css-in-js"
import { ref, type InputHTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"

interface Props {
	onInput(hex: string): void
	initialValue?: string
}

const colorPickerStyles = css`
	padding: 0;
	min-height: 2.4rem;
	display: flex;

	input {
		padding: 0;
		display: flex;
		height: 2.4rem;
	}

	span {
		padding: 0 0.75rem;
		display: flex;
		align-items: center;
		gap: 0.6rem;

		small {
			font-size: 0.8em;
			color: var(--color-tenet-darkgrey);
			margin-top: 0.15em;
		}
	}
`

const ColorPicker: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs }) => {
	const colorInputRef = ref<string>(props.initialValue ?? "#000000")

	return () => (
		<label class={["form-control", colorPickerStyles]}>
			<input type="color" v-model={colorInputRef.value} onInput={useOnInput(props.onInput)} {...attrs} />
			<span>
				{GetColorName(colorInputRef.value)}
				<small>{colorInputRef.value}</small>
			</span>
		</label>
	)
}

export default defineComponent(ColorPicker, {
	onInput: requiredProp(Function),
	initialValue: optionalProp(String),
})
