import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import type { Milestone, MilestoneResponse } from "@/models"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useResponseFiltering } from "./filtering"

interface Props {
	milestones: Milestone[]
	responses: MilestoneResponse[]
	viewResponse(response: MilestoneResponse): void
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const ResponsesTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")

	const filteredResponses = useResponseFiltering({
		milestones: toRef(props, "milestones"),
		responses: toRef(props, "responses"),
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredResponses)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<ColumnTable
				getKey={(response) => response.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Milestone Name",
						size: "auto",
						renderContent: ({ item: response }) => {
							const responseMilestone = props.milestones.find((milestone) =>
								milestone.responses?.map((response) => response.id).includes(response.id)
							)
							return responseMilestone?.name ?? "Unknown"
						},
					},
					startDate: {
						label: "Response Date",
						size: "auto",
						renderContent: ({ item: response }) => response.responseDate.toGBDateString(),
					},
					assessmentScore: {
						label: "Assessment Score",
						size: "auto",
						renderContent: ({ item: response }) => response.assessmentScore + "%",
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: response }) => (
							<button class="btn btn-secondary" onClick={() => props.viewResponse(response)}>
								<Icon icon={faEye} /> <span>View</span>
							</button>
						),
					},
				}}
			/>

			<PagingControls entityName="Responses" />
		</>
	)
}

export default defineComponent(ResponsesTable, {
	milestones: requiredProp(Array),
	responses: requiredProp(Array),
	viewResponse: requiredProp(Function),
})
