import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import SimpleForm from "@/components/form/SimpleForm"
import type { Company, Id } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { computed, ref } from "vue"
import { ModalTitle, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	selectedCompany: Company
	companies: Company[]

	associate(companyId: Id, companies: Company[]): Promise<void>
	close(): void
}

const CompanyAssociations: ReactiveComponent<Props> = (props) => {
	const selectedCompany = proxyProp(props, "selectedCompany")
	const companies = proxyProp(props, "companies")

	const disassociatedFieldRef = ref<HTMLSelectElement>()
	const associatedFieldRef = ref<HTMLSelectElement>()

	const associatedCompanies = ref<Company[]>(selectedCompany.associatedCompanies ?? [])

	const disassociatedCompanies = computed(() => {
		const availableCompanies = [...companies]
		availableCompanies.splice(availableCompanies.map((company) => company.id).indexOf(selectedCompany.id), 1)

		if (!associatedCompanies.value) {
			return availableCompanies
		}

		return availableCompanies.filter(
			(company) => !associatedCompanies.value.map((associatedCompany) => associatedCompany.id).includes(company.id)
		)
	})

	function associate(all: boolean) {
		const field = disassociatedFieldRef.value as HTMLSelectElement

		if (!field) {
			return
		}

		let options = Array.from<HTMLOptionElement>(field.querySelectorAll("option"))
		if (!all) {
			options = options.filter((option) => option.selected)
		}

		const selectedCompanyIds = options.map((option) => parseInt(option.value))

		const selectedCompanies = companies.filter((company) => selectedCompanyIds.includes(company.id))
		selectedCompanies.forEach((company) => associatedCompanies.value.push(company))
	}

	function disassociate(all: boolean) {
		const field = associatedFieldRef.value as HTMLSelectElement

		if (!field) {
			return
		}

		let options = Array.from<HTMLOptionElement>(field.querySelectorAll("option"))
		if (!all) {
			options = options.filter((option) => option.selected)
		}

		const selectedCompanyIds = options.map((option) => parseInt(option.value))

		const selectedCompanies = associatedCompanies.value.filter((company) => selectedCompanyIds.includes(company.id))
		selectedCompanies.forEach((company) =>
			associatedCompanies.value.splice(associatedCompanies.value.indexOf(company), 1)
		)
	}

	return () => (
		<>
			<ModalTitle title={`Associate Companies with '${selectedCompany.name}'`} />
			<hr />
			<SimpleForm
				submit={{
					text: "Save",
					onClick: async () => {
						const selectedCompanies = companies.filter((company) =>
							associatedCompanies.value.find((associatedCompany) => associatedCompany.id == company.id)
						)
						await props.associate(selectedCompany.id, selectedCompanies)
					},
				}}
				cancel={{
					text: "Cancel",
					onClick: props.close,
				}}
			>
				<div class="row mt-2 mb-4">
					<div class="col d-flex flex-wrap flex-column">
						<p class="mb-2 fw-medium">Disassociated Companies</p>
						<select
							class="form-control flex-1"
							multiple
							ref={disassociatedFieldRef}
							onDblclick={(e) => {
								const option = e.target as HTMLOptionElement
								option.selected = true
								associate(false)
							}}
						>
							{disassociatedCompanies.value
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((company) => (
									<option key={company.id} value={company.id}>
										{company.name}
									</option>
								))}
						</select>
					</div>
					<div class="col col-1">
						<p class="mb-2">&nbsp;</p>
						<div class="d-flex flex-column gap-2 items-center my-5">
							<BootstrapButton color="light" style={{ width: "36px", height: "36px" }} onClick={() => associate(false)}>
								<Icon icon={faAngleRight} />
							</BootstrapButton>

							<BootstrapButton
								color="light"
								class="mb-3"
								style={{ width: "36px", height: "36px" }}
								onClick={() => disassociate(false)}
							>
								<Icon icon={faAngleLeft} />
							</BootstrapButton>

							<BootstrapButton color="light" style={{ width: "36px", height: "36px" }} onClick={() => associate(true)}>
								<Icon icon={faAngleDoubleRight} />
							</BootstrapButton>

							<BootstrapButton
								color="light"
								style={{ width: "36px", height: "36px" }}
								onClick={() => disassociate(true)}
							>
								<Icon icon={faAngleDoubleLeft} />
							</BootstrapButton>
						</div>
					</div>
					<div class="col d-flex flex-wrap flex-column">
						<p class="mb-2 fw-medium">Associated Companies</p>
						<select
							class="form-control flex-1"
							multiple
							ref={associatedFieldRef}
							onDblclick={(e) => {
								const option = e.target as HTMLOptionElement
								option.selected = true
								disassociate(false)
							}}
						>
							{associatedCompanies.value
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((company) => (
									<option key={company.id} value={company.id}>
										{company.name}
									</option>
								))}
						</select>
					</div>
				</div>
			</SimpleForm>
		</>
	)
}

export default defineComponent(CompanyAssociations, {
	selectedCompany: requiredProp(Object),
	companies: requiredProp(Array),

	associate: requiredProp(Function),
	close: requiredProp(Function),
})
