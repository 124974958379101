import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, requiredProp, useOnInput, type ReactiveComponent } from "vue-utils"
import Icon from "./Icon"

interface Props {
	search: string
	setSearch(search: string): void
}

const SearchBar: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class="flex items-center spacing-2 w-full">
			<div class="input-group">
				<input
					class="form-control"
					type="search"
					value={props.search}
					onInput={useOnInput(props.setSearch)}
					placeholder="Type to search..."
				/>
				<span class="input-group-text">
					<Icon icon={faSearch} />
				</span>
			</div>
		</div>
	)
}

export default defineComponent(SearchBar, {
	search: requiredProp(String),
	setSearch: requiredProp(Function),
})
