export * from "./BasicErrorPage"

import { createStateLoading, type Component } from "vue-utils"
import { BasicErrorPage } from "./BasicErrorPage"
import FullScreenLoading from "./FullScreenLoading"
import LoadingSpinner from "./LoadingSpinner"
import { PublicErrorPage } from "./PublicErrorPage"

const SimpleLoadingSpinner: Component = () => (
	<div class="flex h-full items-center justify-center">
		<LoadingSpinner />
	</div>
)

export const StateLoading = createStateLoading({
	LoadingComponent: FullScreenLoading as Component,
	ErrorComponent: BasicErrorPage,
})

export const ContentLoading = createStateLoading({
	LoadingComponent: SimpleLoadingSpinner,
	ErrorComponent: BasicErrorPage,
})

export const PublicContentLoading = createStateLoading({
	LoadingComponent: SimpleLoadingSpinner,
	ErrorComponent: PublicErrorPage,
})
