import type { FunctionalComponent } from "vue"
import type { ErrorComponentProps } from "vue-utils"
import ErrorMessage from "../ErrorMessage"
import BootstrapButton from "../form/BootstrapButton"

export const PublicErrorPage: FunctionalComponent<ErrorComponentProps> = ({ reload, error }) => (
	<div class="text-center" style="padding: 5em 1.5em; margin-bottom: 3em;">
		<ErrorMessage text="An Unexpected Error Has Occurred" />
		<br />
		<div>We're sorry, there was an error loading this page.</div>
		<div>Please check your internet connectivity and reload, or contact Tenet for more information.</div>

		<div>
			<BootstrapButton class="my-3" color="primary" onClick={() => void reload()}>
				<span style="padding: 0.25rem 1.5rem">Reload Page Content</span>
			</BootstrapButton>
		</div>
	</div>
)
