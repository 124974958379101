export enum SystemRole {
	SuperAdmin = "SuperAdmin",

	Supplier = "Supplier",

	Client = "Client",

	Inactive = "Inactive",
}

const rolePriorities: Record<SystemRole, number> = {
	[SystemRole.SuperAdmin]: 100,
	[SystemRole.Supplier]: 20,
	[SystemRole.Client]: 10,
	[SystemRole.Inactive]: 1,
}

/**
 * @returns A number value of the role's priority. Higher values indicate a higher level of role
 */
export function getRolePriority(role: SystemRole): number {
	return rolePriorities[role]
}
