import Tile from "@/components/form/Tile"
import { ContentLoading } from "@/components/loading/StateLoading"
import { type Company, type Milestone } from "@/models"
import { getCompanyValues } from "@/services/valuesService"
import { proxyProp } from "@/utils/proxyProp"
import { LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { reactive, ref } from "vue"
import {
	Required,
	defineComponent,
	ensureLoadingHasResult,
	requiredProp,
	useLoadableRef,
	useOnInput,
	type ReactiveComponent,
} from "vue-utils"
import MilestoneValues from "./milestone-values"

interface Props {
	supplier: Company
	client: Company
	milestone: Milestone
	saveMilestone(milestone: Milestone): string
}

const milestoneFormStyles = css`
	margin: 2rem 0;
	max-height: 80vh;
	padding-right: 1.5em;
	overflow-y: auto;

	& > [class^="tile"] {
		margin-right: 0;
	}
`

const ManageMilestone: ReactiveComponent<Props> = (props) => {
	const supplier = proxyProp(props, "supplier")
	const client = proxyProp(props, "client")
	const milestone = proxyProp(props, "milestone")

	const valuesRef = useLoadableRef(async () => {
		const values = await getCompanyValues(supplier.guid)
		return reactive(values)
	})

	const errorMessage = ref("")

	function saveMilestone() {
		const message = props.saveMilestone(milestone)
		errorMessage.value = message
	}

	return () => (
		<>
			<div class={milestoneFormStyles}>
				<Tile>
					<label for="milestoneName">
						<Required label="Name" />
					</label>
					<input
						type="text"
						id="milestoneName"
						v-model={milestone.name}
						class="form-control"
						placeholder="Milestone Name"
						required
					/>
				</Tile>
				<Tile>
					<label for="milestoneReviewDate">
						<Required label="Review Date" />
					</label>
					<input
						type="date"
						id="milestoneReviewDate"
						class="form-control"
						value={milestone.reviewDate.toHTMLInput()}
						onChange={useOnInput((txt) => (milestone.reviewDate = LocalDate.fromHtmlInput(txt)))}
						required
					/>
				</Tile>
				<Tile>
					<label for="milestoneMinResponses">
						<Required label="Minimum Required Responses (%)" />
					</label>
					<div class="d-flex gap-4 mt-2">
						<input
							type="range"
							id="milestoneReviewDate"
							class="form-range"
							min={10}
							step={10}
							v-model={milestone.minimumResponsePercentage}
							required
						/>
						<span>{milestone.minimumResponsePercentage}%</span>
					</div>
				</Tile>
				<Tile>
					<h4>Values</h4>
					<ContentLoading stores={[valuesRef]}>
						<MilestoneValues
							values={ensureLoadingHasResult(valuesRef)}
							milestone={milestone}
							companyName={client.name}
						/>
					</ContentLoading>
				</Tile>
			</div>
			<div class="d-flex justify-between align-items-start spacing-4">
				<p style={{ color: "var(--color-error)" }}>{errorMessage.value}</p>
				<button type="button" class="btn btn-primary flex-shrink-0" onClick={() => saveMilestone()}>
					{milestone.id >= 0 ? "Save" : "Add"} Milestone
				</button>
			</div>
		</>
	)
}

export default defineComponent(ManageMilestone, {
	supplier: requiredProp(Object),
	client: requiredProp(Object),
	milestone: requiredProp(Object),
	saveMilestone: requiredProp(Function),
})
