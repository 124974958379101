import type { SystemRole } from "@/enums"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { defineComponent, optionalProp, renderSlot, requiredProp, type ReactiveComponent } from "vue-utils"
import NotAllowed from "./NotAllowed"

interface Props {
	role: SystemRole
	silent?: boolean
}

const AuthorizeRole: ReactiveComponent<Props> = (props, { slots }) => {
	const user = useLoggedInUser()

	return () => {
		const hasPermission = user.atLeastHasRole(props.role)

		if (hasPermission) {
			return renderSlot(slots)
		}
		if (props.silent) {
			return null
		}
		return <NotAllowed />
	}
}

export default defineComponent(AuthorizeRole, {
	role: requiredProp(String),
	silent: optionalProp(Boolean),
})
