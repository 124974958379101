import FullScreenLoading from "@/components/loading/FullScreenLoading"
import usePopups from "@/stores/popupsStore"
import { Alert, LoadingError, createLoadingHook, type Component } from "vue-utils"

export const showErrorMessage = async (title: string, message: string) => {
	await usePopups().showAlertPopup(() => (
		<Alert title={title}>
			<p>{message}</p>
		</Alert>
	))
}

export const showError = async (e: Error) => {
	if (e instanceof LoadingError) {
		await showErrorMessage(e.title, e.message)
	} else {
		await showErrorMessage(
			"Unexpected Error",
			"An unexpected error occurred. Please try again or contact an administrator"
		)
		console.error(e)
	}
}

const { LoadingWrapper, useLoading } = createLoadingHook({
	loadingComponent: FullScreenLoading as Component,
	showError: showErrorMessage,
	coolInTimeMs: 100,
})

export { LoadingError, LoadingWrapper, useLoading }
