import BasicSelect from "@/components/form/BasicSelect"
import Tile from "@/components/form/Tile"
import { CompanyType } from "@/enums"
import { proxyProp } from "@/utils/proxyProp"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const Type: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	return () => (
		<Tile>
			<label for="companyType" class="tile-heading">
				<Required label="Type" />
			</label>
			<BasicSelect
				options={Object.values(CompanyType)}
				value={company.type}
				setValue={(type) => type && (company.type = type)}
				defaultText="Select Company Type"
				defaultProps={{ disabled: true }}
				class="form-select"
				required
			/>
		</Tile>
	)
}

export default defineComponent(Type)
