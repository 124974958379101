import ErrorMessage from "@/components/ErrorMessage"
import type { FunctionalComponent } from "vue"
import type { ErrorComponentProps } from "vue-utils"

export const SurveyError: FunctionalComponent<ErrorComponentProps> = ({ reload, error }) => (
	<div class="text-center position-relative" style="padding: 5em 1.5em; margin-bottom: 3em;">
		<ErrorMessage text="Unable to load Survey" style={{ fontWeight: "500" }} />
		<br />
		<div>{error.message}</div>
	</div>
)
