import { css } from "vite-css-in-js"
import type { FunctionalComponent, HTMLAttributes } from "vue"

interface Props extends HTMLAttributes {
	text: string
}

const errorMessageStyles = css`
	color: var(--color-error);
	font-size: 1.125em;
`

const ErrorMessage: FunctionalComponent<Props> = ({ text }) => <div class={errorMessageStyles}>{text}</div>

export default ErrorMessage
