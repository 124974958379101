import { AppAuthContext } from "@/authentication"
import type { ApiTypeOf } from "@/models"
import { defineGlobals } from "vue-utils"
import { sendHttpRequest } from "./httpHelper"

export enum RequestMethod {
	GET = "GET",
	POST = "POST",
	DELETE = "DELETE",
	PUT = "PUT",
}

export async function httpRequest(url: string, method: RequestMethod, requestOptions?: RequestInit): Promise<Response> {
	const requestInit: RequestInit = {
		method,
		...requestOptions,
	}

	let authToken: string | null = null
	try {
		authToken = await AppAuthContext.getBearerToken()
	} catch (e) {
		console.error("Failed to get auth token", e)
	}

	//If the user is not logged in (or getting the token fails), attempt the API call anyway
	//Best case scenario, it doesn't require authentication. Worst case scenario, the user gets an Unauthorized response
	if (authToken) {
		const headers = (requestInit.headers ?? {}) as Record<string, string>
		headers.Authorization = `Bearer ${authToken}`
		requestInit.headers = headers
	}

	if (url.startsWith("/") && window.location.host === "localhost:3000") {
		url = `http://localhost:5095${url}`
	}

	return await sendHttpRequest(url, requestInit)
}

export async function httpRequestJsonResponse<T>(
	url: string,
	method = RequestMethod.GET,
	requestOptions?: RequestInit
): Promise<ApiTypeOf<T>> {
	const result = await httpRequest(url, method, requestOptions)
	return (await result.json()) as ApiTypeOf<T>
}

export async function httpJsonRequest(url: string, content: unknown, method = RequestMethod.POST): Promise<Response> {
	return await httpRequest(url, method, {
		body: JSON.stringify(content),
		headers: {
			"content-type": "application/json",
		},
	})
}

export async function httpJsonRequestJsonResponse<T>(
	url: string,
	content: unknown,
	method = RequestMethod.POST
): Promise<ApiTypeOf<T>> {
	const result = await httpJsonRequest(url, content, method)
	return (await result.json()) as ApiTypeOf<T>
}

function serialiseVal<T>(value: T): string | number | boolean {
	if (value === null) {
		return "null"
	}

	if (value instanceof Date) {
		return value.toISOString()
	}

	switch (typeof value) {
		case "string":
			return value as string
		case "boolean":
			return value as boolean
		case "number":
			return value as number
		case "bigint":
			return value.toString()
		case "symbol":
			return value.toString()
	}
	return JSON.stringify(value)
}

export function sanitiseQueryParams<T extends object>(params: T): string {
	return Object.entries(params)
		.filter(([, value]) => value !== undefined)
		.map(([key, value]) => `${key}=${encodeURIComponent(serialiseVal(value))}`)
		.join("&")
}

defineGlobals({
	RequestMethod,
	httpRequest,
	httpJsonRequest,
	httpRequestJsonResponse,
	httpJsonRequestJsonResponse,
	sanitiseQueryParams,
})
