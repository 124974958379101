import SimpleForm from "@/components/form/SimpleForm"
import Tile from "@/components/form/Tile"
import type { Person } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { css } from "vite-css-in-js"
import { Required, defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import { createPerson, updatePerson } from "../../services/peopleService"

interface Props {
	person: Person
	edit?: boolean
}

const formStyles = css`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
`

async function submit(person: Person) {
	await createPerson(person)
	window.location.href = "/people"
}

async function edit(person: Person) {
	await updatePerson(person)
	window.location.href = "/people"
}

const ManagePerson: ReactiveComponent<Props> = (props) => {
	const person = proxyProp(props, "person")

	return () => (
		<>
			<SimpleForm
				class={formStyles}
				submit={{
					onClick: () => (props.edit ? edit(person) : submit(person)),
					text: `${props.edit ? "Save" : "Add"} Person`,
				}}
			>
				<div class="row" style={{ display: "flex", flex: "100%" }}>
					<div class="col">
						<Tile>
							<label for="personFirstName" class="tile-heading">
								<Required label="Name" />
							</label>
							<div class="row">
								<div class="col">
									<div class="form-floating">
										<input
											type="text"
											id="personFirstName"
											v-model={person.firstName}
											placeholder="First Name"
											class="form-control"
											required
										/>
										<label for="personFirstName">First Name</label>
									</div>
								</div>
								<div class="col">
									<div class="form-floating">
										<input
											type="text"
											id="personLastName"
											v-model={person.lastName}
											placeholder="Last Name"
											class="form-control"
											required
										/>
										<label for="personLastName">Last Name</label>
									</div>
								</div>
							</div>
						</Tile>
						<Tile>
							<label for="personEmail" class="tile-heading">
								<Required label="Email Address" />
							</label>
							<input
								type="email"
								id="personEmail"
								v-model={person.emailAddress}
								placeholder="Email Address"
								class="form-control"
								required
							/>
						</Tile>
					</div>
					<div class="col">
						<Tile>
							<label for="personPhone" class="tile-heading">
								Phone Number
							</label>
							<input
								type="tel"
								id="personPhone"
								v-model={person.phoneNumber}
								placeholder="Phone Number"
								class="form-control"
							/>
						</Tile>
						<Tile>
							<label for="personNotes" class="tile-heading">
								Notes
							</label>
							<textarea
								id="personNotes"
								v-model={person.notes}
								class="form-control"
								placeholder="Notes"
								rows={2}
							></textarea>
						</Tile>
					</div>
				</div>
			</SimpleForm>
		</>
	)
}

export default defineComponent(ManagePerson, {
	person: requiredProp(Object),
	edit: optionalProp(Boolean),
})
