import type { ApiTypeOf } from "./ApiType"
import type { GenericEntity, INamed } from "./GenericEntity"

export interface Industry extends GenericEntity, INamed {
	description: string
	active: boolean
}

export function mapApiIndustry(industry: ApiTypeOf<Industry>): Industry {
	return {
		id: industry.id,
		name: industry.name,
		description: industry.description,
		active: industry.active,
	}
}
