import { css } from "vite-css-in-js"
import { defineComponent, optionalProp, requiredProp, useLoadableRef, type ReactiveComponent } from "vue-utils"

import logo from "@/assets/logo.svg"
import { fetchCompanyByPerson } from "@/services/companiesService"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { reactive } from "vue"
import { StateLoading } from "../loading/StateLoading"
import NavPanel from "../navigation"

const headerStyles = css`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	letter-spacing: 0.0125rem;
	height: 4rem;
	font-weight: 500;
	z-index: 2;
	font-size: 1.25rem;
	justify-content: space-between;

	position: fixed;
	top: 0;
	left: 0;
	right: var(--scrollbar-width);
	padding: 1em 2em;
	margin-bottom: 0.5em;
	box-sizing: content-box;
	background: var(--color-white);
	border-color: var(--color-tenet-darkgrey) !important;
	transition:
		border 0.1s,
		box-shadow 0.1s;

	.logo {
		height: 100%;
		width: 200px;

		img {
			height: 100%;
			width: auto;
			object-fit: contain;
		}
	}

	& > * {
		flex: 0;
	}
`

interface Props {
	title: string
	showLogo?: boolean
}

const AppHeader: ReactiveComponent<Props> = (props) => {
	const loggedInUser = useLoggedInUser()

	const companyRef = useLoadableRef(async () => {
		if (!loggedInUser.person) {
			return
		}

		const company = await fetchCompanyByPerson(loggedInUser.person)
		return reactive(company)
	})

	function renderCompany() {
		if (companyRef.type !== "done") {
			return null
		}

		const company = companyRef.result
		if (!company) return null

		return (
			<span class="flex-grow-1" style={{ whiteSpace: "nowrap", fontSize: "0.9em", marginLeft: "0.5em" }}>
				<span style={{ fontSize: "0.8em", fontWeight: 400, display: "block" }}>Company:</span>
				{company.name}
			</span>
		)
	}

	return () => (
		<header class={headerStyles}>
			<div class="d-flex gap-4 align-items-center">
				<a href="/" class="logo">
					{props.showLogo ? (
						<img src={logo} alt="Tenet" />
					) : (
						<span class="flex-grow-1" style={{ whiteSpace: "nowrap" }}>
							{props.title}
						</span>
					)}
				</a>

				<StateLoading stores={[companyRef]}>{renderCompany()}</StateLoading>
			</div>

			<NavPanel />
		</header>
	)
}

export default defineComponent(AppHeader, {
	title: requiredProp(String),
	showLogo: optionalProp(Boolean),
})
