import Icon from "@/components/Icon"
import type { ValidationResult } from "@/components/form/BasicForm"
import BasicSelect from "@/components/form/BasicSelect"
import Blade, { closeBlade, toggleBlade } from "@/components/form/Blade"
import SelectPeople from "@/components/form/PersonSelect/SelectPeople"
import SimpleForm from "@/components/form/SimpleForm"
import Tile from "@/components/form/Tile"
import UserIndicator from "@/components/navigation/UserIndicator"
import { SystemRole } from "@/enums"
import type { Person, User } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { proxyProp } from "@/utils/proxyProp"
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Teleport, ref } from "vue"
import { Required, defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import { createUser, updateUser } from "./requests"

interface Props {
	user: User
	edit?: boolean
}

const formStyles = css`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
`

const staffStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;

	.addStaff {
		border-radius: 500em;
		background: var(--color-tenet-blue);
		color: var(--color-white);
		width: 50px;
		height: 50px;
		font-size: 1.2em;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`

async function submit(user: User) {
	await createUser(user)
	window.location.href = "/users"
}

async function edit(user: User) {
	await updateUser(user)
	window.location.href = "/users"
}

function addPerson(people: Person[], user: User) {
	user.person = people[0] ?? null
	closeBlade()
}

const ManageUser: ReactiveComponent<Props> = (props) => {
	const user = proxyProp(props, "user")

	const confirmPasswordRef = ref<HTMLInputElement>()

	const existingPeople: Person[] = []
	if (user.person) existingPeople.push(user.person)

	function validateForm(user: User): ValidationResult {
		if (!props.edit) {
			const confirmPassword = confirmPasswordRef.value as HTMLInputElement
			if (!confirmPassword || confirmPassword.value !== user.password) {
				return "Passwords do not match."
			}
		}

		if (user.role !== SystemRole.SuperAdmin && user.role !== SystemRole.Inactive) {
			if (user.person === null) {
				return `Users with the '${formatEnum(user.role)}' role must have a Person assigned.`
			}
		}

		return true
	}

	return () => (
		<>
			<SimpleForm
				class={formStyles}
				submit={{
					onClick: () => (props.edit ? edit(user) : submit(user)),
					validate: () => validateForm(user),
					text: `${props.edit ? "Save" : "Add"} User`,
				}}
			>
				<div class="row" style={{ display: "flex", flex: "100%" }}>
					<div class="col">
						<Tile>
							<label for="userFirstName" class="tile-heading">
								<Required label="Name" />
							</label>
							<div class="row">
								<div class="col">
									<div class="form-floating">
										<input
											type="text"
											id="userFirstName"
											v-model={user.firstName}
											placeholder="First Name"
											class="form-control"
											required
										/>
										<label for="userFirstName">First Name</label>
									</div>
								</div>
								<div class="col">
									<div class="form-floating">
										<input
											type="text"
											id="userLastName"
											v-model={user.lastName}
											placeholder="Last Name"
											class="form-control"
											required
										/>
										<label for="userLastName">Last Name</label>
									</div>
								</div>
							</div>
						</Tile>
						{props.edit ? (
							<Tile>
								<label for="userEmail" class="tile-heading">
									<Required label="Email Address" />
								</label>
								<input
									type="email"
									id="userEmail"
									v-model={user.emailAddress}
									placeholder="Email Address"
									class="form-control"
									required
								/>
							</Tile>
						) : (
							<Tile>
								<label for="userPassword" class="tile-heading">
									<Required label="Password" />
								</label>
								<input
									type="password"
									id="userPassword"
									v-model={user.password}
									placeholder="Password"
									class="form-control"
									required
								/>
								<br />
								<label for="userConfirmPassword" class="tile-heading">
									<Required label="Confirm Password" />
								</label>
								<input
									type="password"
									id="userConfirmPassword"
									ref={confirmPasswordRef}
									placeholder="Confirm Password"
									class="form-control"
									required
								/>
							</Tile>
						)}
					</div>
					<div class="col">
						{!props.edit && (
							<Tile>
								<label for="userEmail" class="tile-heading">
									<Required label="Email Address" />
								</label>
								<input
									type="email"
									id="userEmail"
									v-model={user.emailAddress}
									placeholder="Email Address"
									class="form-control"
									required
								/>
							</Tile>
						)}
						<Tile>
							<label for="userRole" class="tile-heading">
								<Required label="Role" />
							</label>
							<BasicSelect
								options={Object.values(SystemRole)}
								value={user.role}
								setValue={(role) => (user.role = role)}
								getText={(role) => formatEnum(role)}
								defaultText="Select Role"
								defaultProps={{ disabled: true }}
								class="form-select"
								required
							/>
						</Tile>
						<Tile>
							<label for="userPerson" class="tile-heading">
								Person
							</label>
							<div class={staffStyles}>
								{user.person && (
									<UserIndicator
										key={user.person.id}
										firstName={user.person.firstName}
										lastName={user.person.lastName}
										style={{ width: "50px", height: "50px" }}
									/>
								)}
								<span
									class="addStaff"
									onClick={() => toggleBlade()}
									onKeypress={(e) => e.key === "Enter" && toggleBlade()}
									role="button"
									tabindex={0}
								>
									<Icon icon={!user.person ? faPlus : faPencil} />
								</span>
							</div>
						</Tile>
					</div>
				</div>
			</SimpleForm>

			<Teleport to="body">
				<Blade>
					<h2>Select Person</h2>
					<SelectPeople
						existingPeople={existingPeople}
						single={true}
						name="People"
						nameSingular="Person"
						addPeople={(people: Person[]) => addPerson(people, user)}
					/>
				</Blade>
			</Teleport>
		</>
	)
}

export default defineComponent(ManageUser, {
	user: requiredProp(Object),
	edit: optionalProp(Boolean),
})
