import { css } from "vite-css-in-js"
import { type ButtonHTMLAttributes } from "vue"
import { defineComponent, optionalProp, propsWithDefaults, renderSlot, type ReactiveComponent } from "vue-utils"

interface Props {
	url?: string
	isSubmitting?: boolean
	small?: boolean
}

const buttonStyles = css`
	margin-top: 1em;
	font-size: 1.4em;
	text-transform: uppercase;
	background-color: transparent;
	border-color: var(--color-tenet-orange);
	padding: 0.3em 3em;
	box-shadow: 0 2px 20px 4px rgba(var(--color-tenet-black-rgb), 0.25);
	background-color: var(--color-white);

	&.small {
		margin-top: 0;
		font-size: 1.2em;
		padding: 0.3em 2em;
	}

	&:hover {
		background-color: var(--color-fill);
		border-color: var(--color-tenet-orange-darker);
	}
`

const classList = ["btn", "inline-flex", "justify-center", "items-center", "spacing-2", buttonStyles]

const TenetButton: ReactiveComponent<Props, ButtonHTMLAttributes> = (initialProps, { slots, attrs }) => {
	const props = propsWithDefaults(initialProps, {
		isSubmitting: false,
	})

	if (props.small) classList.push("small")

	return () =>
		props.url ? (
			<a href={props.url} class={classList} {...attrs}>
				{renderSlot(slots, "default")}
			</a>
		) : (
			<button class={classList} disabled={attrs.disabled || props.isSubmitting} {...attrs}>
				{props.isSubmitting && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />}
				{renderSlot(slots, "default")}
			</button>
		)
}

export default defineComponent(TenetButton, {
	url: optionalProp(String),
	isSubmitting: optionalProp(Boolean),
	small: optionalProp(Boolean),
})
