import TenetButton from "@/components/form/TenetButton"
import { showErrorMessage } from "@/composition/useLoading"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import usePopups from "@/stores/popupsStore"
import { ref } from "vue"
import { Alert, defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import { resetPassword } from "../users/requests"

const ResetPassword: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()
	const submitting = ref(false)

	const popups = usePopups()

	async function reset() {
		submitting.value = true

		try {
			await resetPassword(loggedInUser)

			await popups.showAlertPopup(() => (
				<Alert title="Password Reset Requested">
					<p>You have successfully requested a password reset.</p>
					<p>Please check your email for further instructions.</p>
				</Alert>
			))
		} catch (e) {
			console.error(e)
			await showErrorMessage(
				"Failed to request password reset",
				"Unable to request password reset. Please try again later or contact an administrator"
			)
		} finally {
			submitting.value = false
		}
	}

	return () => (
		<BasicPage title="Reset Password">
			<h1>Reset Password</h1>
			<p>
				Please click the button below to request a password reset.
				<br />
				You will be sent an email with a link to update your password.
			</p>
			<TenetButton onClick={() => void reset()} isSubmitting={submitting.value} class="mb-3">
				Request Password Reset
			</TenetButton>
		</BasicPage>
	)
}

export default defineComponent(ResetPassword)
