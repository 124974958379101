import AppFooter from "@/components/AppFooter"
import AppHeader from "@/components/titlebar/AppHeader"
import { LoadingWrapper } from "@/composition/useLoading"
import { setScrollBehaviour } from "@/utils/setScrollBehaviour"
import { css } from "vite-css-in-js"
import { onMounted, renderSlot, type HTMLAttributes } from "vue"
import {
	ShadowContainer,
	defineComponent,
	optionalProp,
	propsWithDefaults,
	requiredProp,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	title: string
	showLogo?: boolean
	plain?: boolean
}

const sharedPageStyles = css`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;

	@media print {
		display: contents;
		& > *:not(main) {
			display: none;
		}
		& > main {
			height: 100%;
			display: block;
			overflow: initial;
		}
	}
`

const mainContentStyles = css`
	padding: 7.5rem 2rem 6rem;
	height: 100vh;
	position: fixed;
	inset: 0;
	overflow-y: scroll;

	.vu-shadow-container {
		padding: 1.5rem 1.75rem;
	}
`

const BasicPage: ReactiveComponent<Props, HTMLAttributes> = (initialProps, { slots, attrs }) => {
	const props = propsWithDefaults(initialProps, {
		noPadding: false,
		showLogo: true,
		plain: false,
		scroll: true,
	})

	function PageContent() {
		if (props.plain) {
			return (
				<main class={mainContentStyles} {...attrs}>
					<div>{renderContent()}</div>
				</main>
			)
		}

		return (
			<main class={mainContentStyles}>
				<ShadowContainer class="print-contents w-full" {...attrs}>
					<div>{renderContent()}</div>
				</ShadowContainer>
			</main>
		)
	}

	onMounted(setScrollBehaviour)

	function renderContent() {
		return <LoadingWrapper>{renderSlot(slots, "default")}</LoadingWrapper>
	}

	return () => (
		<div class={sharedPageStyles}>
			<AppHeader title={props.title} showLogo={props.showLogo} />
			<PageContent />
			<AppFooter />
		</div>
	)
}

export default defineComponent(BasicPage, {
	title: requiredProp(String),
	showLogo: optionalProp(Boolean),
	plain: optionalProp(Boolean),
})
