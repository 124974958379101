import { mapApiValue, type Value } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"

export async function createValue(value: Value): Promise<Value> {
	const result = await httpJsonRequestJsonResponse<Value>("/api/values", value, RequestMethod.POST)
	return mapApiValue(result)
}

export async function updateValue(value: Value): Promise<Value> {
	const result = await httpJsonRequestJsonResponse<Value>("/api/values", value, RequestMethod.PUT)
	return mapApiValue(result)
}

export async function getCompanyValues(guid: string): Promise<Value[]> {
	const result = await httpRequestJsonResponse<Value[]>(`/api/values/company/${guid}`)
	return result.map(mapApiValue)
}
