import { mapApiContract, mapApiUploadedFile, type Contract, type UploadedFile } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"
import { convertToFormData } from "@/utils/mapFormData"

export async function getContracts(): Promise<Contract[]> {
	const result = await httpRequestJsonResponse<Contract[]>("/api/contracts")
	return result.map(mapApiContract)
}

export async function getContract(guid: string): Promise<Contract> {
	const result = await httpRequestJsonResponse<Contract>(`/api/contracts/${guid}`)
	return mapApiContract(result)
}

export async function createContract(contract: Contract): Promise<Contract> {
	return await saveContract(contract, RequestMethod.POST)
}

export async function updateContract(contract: Contract): Promise<Contract> {
	return await saveContract(contract, RequestMethod.PUT)
}

export async function uploadAdditionalFiles(contract: Contract, files: File[]) {
	const formData = new FormData()
	files.forEach((file) => formData.append("files", file))

	const uploadedFiles = await httpRequestJsonResponse<UploadedFile[]>(
		`/api/contracts/${contract.guid}/additional-files`,
		RequestMethod.PUT,
		{
			body: formData,
		}
	)

	return uploadedFiles.map(mapApiUploadedFile)
}

export async function removeUploadedFile(contract: Contract, file: UploadedFile) {
	const result = await httpJsonRequestJsonResponse<UploadedFile>(
		`/api/contracts/${contract.guid}/remove-file`,
		file,
		RequestMethod.DELETE
	)
	return mapApiUploadedFile(result)
}

async function saveContract(contract: Contract, method: RequestMethod): Promise<Contract> {
	const formData = convertToFormData(contract, "contractData")

	if (contract.filesForUpload && contract.filesForUpload.length > 0)
		contract.filesForUpload.forEach((file) => formData.append("files", file))

	const result = await httpRequestJsonResponse("/api/contracts", method, {
		body: formData,
	})

	return mapApiContract(result)
}
