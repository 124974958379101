import { css } from "vite-css-in-js"
import { computed, ref } from "vue"
import { defineComponent, optionalProp, propsWithDefaults, useTimeout, type ReactiveComponent } from "vue-utils"
import LoadingSpinner from "./LoadingSpinner"

const backgroundStyles = css`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	transition: background-color 0.5s;

	z-index: 3;
`

interface Props {
	hideTime?: number
	opacity?: number
}

const FullScreenLoading: ReactiveComponent<Required<Props>> = (initialProps) => {
	const props = propsWithDefaults(initialProps, {
		hideTime: 100,
		opacity: 0.1,
	})

	const visible = ref(false)
	useTimeout(props.hideTime, () => {
		visible.value = true
	})
	const usedOpacity = computed(() => (visible.value ? props.opacity : 0))

	return () => (
		<div class={backgroundStyles} style={{ backgroundColor: `rgba(0, 0, 0, ${usedOpacity.value})` }}>
			{visible.value && <LoadingSpinner />}
		</div>
	)
}

export default defineComponent<Props>(FullScreenLoading as ReactiveComponent<Props>, {
	hideTime: optionalProp(Number),
	opacity: optionalProp(Number),
})
