import type { Contract, Milestone } from "@/models"
import { LocalDate } from "@js-joda/core"
import type { ValidationResult } from "vue-utils"
import type { ContractEntryType } from "./context"

export function validateContract(
	contract: Contract,
	clientName: string,
	entryType: ContractEntryType
): ValidationResult {
	const errors: string[] = []

	errors.push(...validateSupplier(contract))
	errors.push(...validateClient(contract))
	errors.push(...validateAbout(contract, entryType))
	errors.push(...validateStakeholders(contract))
	errors.push(...validateMilestones(contract, clientName))

	if (errors.length > 0) {
		return errors
	}

	return true
}

export function validateSupplier(contract: Contract): string[] {
	const errors: string[] = []

	if (!contract.supplierId) {
		errors.push("No Supplier has been selected. Please select a Supplier.")
	}

	return errors
}

export function validateClient(contract: Contract): string[] {
	const errors: string[] = []

	if (!contract.clientId) {
		errors.push("No Client has been selected. Please select a Client.")
	}

	return errors
}

export function validateAbout(contract: Contract, entryType: ContractEntryType): string[] {
	const errors: string[] = []

	if (!contract.name || contract.name.trim() === "") {
		errors.push("Contract Name is required.")
	}

	if (entryType === "create") {
		if (contract.startDate.isBefore(LocalDate.now())) {
			errors.push("Start Date cannot be in the past.")
		}

		if (contract.completionDate.isBefore(LocalDate.now())) {
			errors.push("Completion Date cannot be in the past.")
		}
	}

	if (contract.startDate.isAfter(contract.completionDate)) {
		errors.push("Start Date cannot be after Completion Date.")
	}

	return errors
}

export function validateStakeholders(contract: Contract): string[] {
	const errors: string[] = []

	if (contract.stakeholderIds.length === 0) {
		errors.push("No Stakeholders have been selected. Please select a stakeholder before continuing.")
	}

	return errors
}

export function validateMilestone(milestone: Milestone, contract: Contract, clientName: string) {
	const errors: string[] = []

	if (!milestone.name || milestone.name.trim() === "") {
		errors.push("The Milestone Name is required.")
	}

	if (milestone.reviewDate.isBefore(contract.startDate)) {
		errors.push(`The Milestone Review Date (${milestone.reviewDate.toGBDateString()}) cannot be before the 
        Contract Start Date (${contract.startDate.toGBDateString()})`)
	}

	if (milestone.reviewDate.isAfter(contract.completionDate)) {
		errors.push(`The Milestone Review Date (${milestone.reviewDate.toGBDateString()}) cannot be after the 
        Contract Completion Date (${contract.completionDate.toGBDateString()})`)
	}

	milestone.milestoneWeightedValues?.forEach((value, index) => {
		const valueNumber = index + 1

		if (!value.comments || value.comments.trim() === "") {
			errors.push(
				`Value ${valueNumber} could not be saved. The Comments field (Why is this important to ${clientName}) is required.`
			)
		}

		if (value.weight == null) {
			errors.push(`Value ${valueNumber} could not be saved. The Weight field is required`)
		}

		if (value.minimumSatisfactoryScore == null || value.maximumSatisfactoryScore == null) {
			errors.push(`Value ${valueNumber} could not be saved. The Satisfactory Range is required`)
		}

		if (value.minimumSatisfactoryScore > value.maximumSatisfactoryScore) {
			errors.push(
				`Value ${valueNumber} could not be saved. The Minimum Satisfactory Score cannot be greater than ${value.maximumSatisfactoryScore}`
			)
		}
	})

	return errors
}

export function validateMilestones(contract: Contract, clientName: string): string[] {
	const errors: string[] = []

	contract.milestones.forEach((milestone) => {
		errors.push(...validateMilestone(milestone, contract, clientName))
	})

	return errors
}
