import { showErrorMessage } from "@/composition/useLoading"
import { useVueAuth } from "oidc-vue"
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import BasicPage from "../BasicPage"
import LogoutButton from "./LogoutButton"

const Logout: ReactiveComponent = () => {
	const router = useRouter()
	const auth = useVueAuth()
	const buttonRef = ref<HTMLElement | null>(null)

	async function logout() {
		try {
			await auth.manager.signoutRedirect()
		} catch (e) {
			console.error(e)
			await showErrorMessage(
				"Failed to log out",
				"Unable to log out. Please try again later or contact an administrator"
			)
		}
		await router.replace("/")

		localStorage.clear()
		location.reload()
	}

	onMounted(() => void logout())

	return () => (
		<BasicPage title="Log Out" plain>
			<p>Please click the button below to log out.</p>
			<LogoutButton ref={buttonRef} logout={() => logout} />
		</BasicPage>
	)
}

export default defineComponent(Logout)
