import { css } from "vite-css-in-js"
import type { HTMLAttributes } from "vue"
import { defineComponent, renderSlot, type NoProps, type ReactiveComponent } from "vue-utils"

const tileStyles = css`
	background: var(--color-white);
	display: inline-block;
	width: 100%;
	margin: 0 1.5rem 1.5rem 0;
	border-radius: 0.3em;
	padding: 0.75em 1em;
	box-shadow: 0 0.125em 0.25rem 0 rgba(var(--color-tenet-darkgrey-rgb), 0.3);
	break-inside: avoid;
	outline: 0;
	transition: outline 0.1s;

	&.active {
		outline: 5px solid rgba(var(--color-tenet-blue-rgb), 0.5);
	}

	.tile-heading {
		font-weight: 400;
		margin-bottom: 0.5em;
	}
`

const Tile: ReactiveComponent<NoProps, HTMLAttributes> = (_, { attrs, slots }) => {
	return () => (
		<div class={tileStyles} {...attrs}>
			{renderSlot(slots)}
		</div>
	)
}

export default defineComponent(Tile)
