import type { RouteInformation } from "@/components/navigation/Navigation"
import { SystemRole } from "@/enums"

export const AppRoutes: Record<string, RouteInformation> = {
	"/users": {
		name: "Users",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.SuperAdmin),
	},
	"/companies": {
		name: "Companies",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.SuperAdmin),
	},
	"/contracts": {
		name: "Contracts",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Client),
	},
	"/people": {
		name: "People",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Client),
	},
}

export const ProfileRoutes: Record<string, RouteInformation> = {
	"/reset-password": {
		name: "Reset Password",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Client),
	},
	"/logout": {
		name: "Log Out",
	},
}

export const CreateRoutes: Record<string, RouteInformation> = {
	"/contracts/add": {
		name: "Contract",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Supplier),
	},
	"/companies": {
		name: "Company",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.SuperAdmin),
	},
	"/people/add": {
		name: "Person",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.Client),
	},
	"/users/add": {
		name: "User",
		hasAccess: (user) => user.atLeastHasRole(SystemRole.SuperAdmin),
	},
}
