import { VueAuthPlugin } from "oidc-vue"
import { defineGlobals } from "vue-utils"

class AppAuthPlugin extends VueAuthPlugin {
	constructor() {
		super({
			clientId: "tenet",
			authority: "https://keycloak.submarine.gg/realms/Tenetech",
			redirectUri: window.location.origin,
			postLogoutRedirectUri: window.location.origin,
			storagePrefix: "tenetech_",
			sessionExpireLoginType: "popup",
		})
	}

	getKeycloakManageUrl() {
		const realmUrl = this.options.authority
		return `${realmUrl}/account?referrer=${encodeURIComponent(this.options.clientId)}&referrer_uri=${encodeURIComponent(
			window.location.href
		)}`
	}
}

export const AppAuthContext = new AppAuthPlugin()
defineGlobals({
	AppAuthContext,
})
