import Icon from "@/components/Icon"
import Tile from "@/components/form/Tile"
import type { Industry, Value } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Required, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

interface Props {
	value: Value
}

const industriesStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;

	.industry {
		padding: 0 1em;
		border-radius: 500em;
		border: 2px solid var(--color-tenet-lightgrey);
		color: var(--color-tenet-black);
		font-weight: 400;
		cursor: pointer;
		transition: all 0.1s;
		display: flex;
		align-items: center;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;

		&:hover {
			border-color: var(--color-tenet-blue-darker);
			background-color: var(--color-fill);
		}

		&.active {
			background-color: var(--color-tenet-blue);
			border-color: var(--color-tenet-blue);
			color: var(--color-white);

			&:hover {
				background-color: var(--color-tenet-blue-darker);
				border-color: var(--color-tenet-blue-darker);
			}
		}
	}
`

const Industries: ReactiveComponent<Props> = (props) => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	function toggleIndustry(industry: Industry) {
		const industryIds = props.value.industries.map((industry) => industry.id)
		const index = industryIds.indexOf(industry.id)
		const industries = [...props.value.industries]

		if (index >= 0) {
			industries.splice(index, 1)
		} else {
			industries.push(industry)
		}

		props.value.industries = industries
	}

	return () => (
		<Tile>
			<label class="tile-heading">
				<Required label="Industries" />
				<small>Click on an industry below to toggle it.</small>
			</label>
			<div class={industriesStyles}>
				{company.industries && (
					<>
						{company.industries.map((industry) => {
							const isActive = props.value.industries.map((industry) => industry.id).includes(industry.id)

							return (
								<span
									key={industry.name}
									class={["industry", { active: isActive }]}
									onClick={() => toggleIndustry(industry)}
									onKeypress={(event) => event.key == "Enter" && toggleIndustry(industry)}
									tabindex={0}
									role="button"
								>
									{isActive && <Icon icon={faCheck} class="me-2" />}
									{industry.name}
								</span>
							)
						})}
						<hr />
					</>
				)}
			</div>
		</Tile>
	)
}

export default defineComponent(Industries, {
	value: requiredProp(Object),
})
