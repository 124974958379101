import type { Id, Industry } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequest, httpRequestJsonResponse } from "./httpService"

export async function getIndustries(): Promise<Industry[]> {
	return await httpRequestJsonResponse<Industry[]>("/api/industries")
}

export async function createIndustry(industry: Industry): Promise<Industry> {
	return await httpJsonRequestJsonResponse<Industry>("/api/industries", industry, RequestMethod.POST)
}

export async function updateIndustry(industry: Industry): Promise<Industry> {
	return await httpJsonRequestJsonResponse<Industry>("/api/industries", industry, RequestMethod.PUT)
}

export async function deleteIndustry(industryId: Id): Promise<void> {
	await httpRequest(`/api/industries/${industryId}`, RequestMethod.DELETE)
}
