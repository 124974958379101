import { useVueAuth } from "oidc-vue"
import { defineComponent } from "vue"
import { useRouter } from "vue-router"
import type { ReactiveComponent } from "vue-utils"
import MainApp from "./MainApp"
import Popups from "./components/Popups"
import { StateLoading } from "./components/loading/StateLoading"
import { SystemRole } from "./enums"
import NotAllowed from "./pages/NotAllowed"
import LoginPage from "./pages/login"
import SurveyPage from "./pages/survey"
import { useLoggedInUserStore } from "./stores/loggedInUserStore"

const App: ReactiveComponent = () => {
	const userStore = useLoggedInUserStore()
	const { loggedIn } = useVueAuth()

	const router = useRouter()

	function renderContent() {
		if (router.currentRoute.value.path === "/logout") return <MainApp />

		if (userStore.type !== "done") return <NotAllowed />

		const user = userStore.result
		if (user.atLeastHasRole(SystemRole.Client)) {
			return <MainApp />
		}

		return <NotAllowed />
	}

	function renderMain() {
		if (router.currentRoute.value.path.startsWith("/survey/")) return <SurveyPage />

		if (!loggedIn.value) return <LoginPage />

		return <StateLoading stores={[userStore]}>{renderContent()}</StateLoading>
	}

	return () => (
		<>
			{renderMain()}
			<Popups />
		</>
	)
}

export default defineComponent(App)
