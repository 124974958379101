import { ref, shallowRef, type FormHTMLAttributes } from "vue"
import {
	ColumnLayout,
	defineComponent,
	optionalProp,
	renderSlot,
	requiredProp,
	type Component,
	type ReactiveComponent,
} from "vue-utils"
import type { BootstrapButtonType } from "./BootstrapButton"
import BootstrapButton from "./BootstrapButton"

export type ValidationResult = string | boolean | Promise<string | boolean>

export interface FormButton {
	id: string
	onSubmit: (event: Event) => void | Promise<void>
	validate?: () => ValidationResult
	validateForm?: boolean

	render: Component<{
		onClick: (e: UIEvent) => void
		disabled: boolean
		isSubmitting: boolean
	}>
}

interface Props {
	customErrorMessage?: string
	buttons: FormButton[]
}

export const basicFormButton = (
	options: Omit<FormButton, "render"> & { color: BootstrapButtonType; text: string }
): FormButton => {
	const { color, text, ...buttonOptions } = options
	return {
		render: (props) => (
			<BootstrapButton color={color} {...props}>
				{text}
			</BootstrapButton>
		),
		...buttonOptions,
	}
}

const BasicForm: ReactiveComponent<Props, FormHTMLAttributes> = (props, { attrs, slots }) => {
	const formRef = ref<HTMLFormElement>()
	const buttonSubmitting = shallowRef<FormButton | null>(null)
	const errorMessage = ref<string | null>(null)

	async function runSubmit(event: Event, button: FormButton) {
		buttonSubmitting.value = button
		try {
			await button.onSubmit(event)
		} catch (e) {
			errorMessage.value = (e as Error).message
			console.error(e)
		} finally {
			buttonSubmitting.value = null
		}
	}

	async function handleSubmit(event: Event, button: FormButton) {
		event.preventDefault()
		event.stopImmediatePropagation()
		if (buttonSubmitting.value !== null) {
			return
		}

		const formElement = formRef.value as HTMLFormElement
		if (button.validateForm !== false && formElement && !formElement.reportValidity()) {
			return
		}

		if (button.validate) {
			const validationResult = await button.validate()

			if (validationResult !== true) {
				if (typeof validationResult === "string") {
					errorMessage.value = validationResult
				}
				return
			}
		}

		errorMessage.value = ""
		await runSubmit(event, button)
	}

	return () => (
		<form
			ref={formRef}
			{...attrs}
			onSubmit={(e) => {
				e.preventDefault()
				e.stopImmediatePropagation()
				return false
			}}
		>
			{
				//https://stackoverflow.com/questions/895171/prevent-users-from-submitting-a-form-by-hitting-enter
			}
			<button type="submit" disabled style="display: none" aria-hidden="true" />

			{renderSlot(slots)}

			<div class="flex items-center spacing-4">
				<div class="w-full flex-1 text-right break-spaces" style={{ color: "var(--color-error)" }}>
					{props.customErrorMessage ?? errorMessage.value ?? ""}
				</div>
				<ColumnLayout spacing="1rem" columns={props.buttons.length}>
					{props.buttons.map((button) => (
						<button.render
							key={button.id}
							disabled={buttonSubmitting.value !== null}
							isSubmitting={buttonSubmitting.value !== null && buttonSubmitting.value.id === button.id}
							onClick={(e) => void handleSubmit(e, button)}
						/>
					))}
				</ColumnLayout>
			</div>
		</form>
	)
}

export default defineComponent(BasicForm, {
	customErrorMessage: optionalProp(String),
	buttons: requiredProp(Array),
})
