import { defineComponent, optionalProp, requiredProp, useOnInputNumber, type ReactiveComponent } from "vue-utils"
import Pagination from "./Pagination"

interface Props {
	/** Name of the entities which are being displayed in the table. Should be in plural form */
	entityName: string
	page: number
	pageSize: number
	totalPages: number
	totalItems: number
	setPage(page: number): void
	setPageSize?(pageSize: number): void
}

const TableControls: ReactiveComponent<Props> = (props) => {
	return () => (
		<div class="flex items-center justify-between" style={{ marginTop: "1rem" }}>
			<span class="flex items-center flex-wrap">
				Total {props.entityName} Found - <strong class="ms-2">{props.totalItems}</strong>
				<label class="flex-row items-center ms-3">
					<span>Items Per Page</span>
					<input
						type="number"
						min={1}
						max={24}
						step={1}
						style={{ width: "4rem", height: "2rem" }}
						value={props.pageSize}
						onChange={useOnInputNumber((size) => props.setPageSize?.(size))}
						disabled={!props.setPageSize}
						class="ms-2"
					/>
				</label>
				<Pagination page={props.page} setPage={props.setPage} totalPages={props.totalPages} />
			</span>
		</div>
	)
}

export default defineComponent(TableControls, {
	entityName: requiredProp(String),
	page: requiredProp(Number),
	pageSize: requiredProp(Number),
	totalPages: requiredProp(Number),
	totalItems: requiredProp(Number),
	setPage: requiredProp(Function),
	setPageSize: optionalProp(Function),
})
