import Icon from "@/components/Icon"
import { showErrorMessage } from "@/composition/useLoading"
import type { UploadedFile } from "@/models"
import usePopups from "@/stores/popupsStore"
import { generateDownloadLink } from "@/utils/downloadFile"
import { getFileIcon } from "@/utils/fileIcons"
import { proxyProp } from "@/utils/proxyProp"
import { faCalendar, faDownload, faTrashAlt, faUser } from "@fortawesome/free-solid-svg-icons"
import { ZoneId } from "@js-joda/core"
import { ref, toRef } from "vue"
import {
	Alert,
	defineComponent,
	formatFileSize,
	optionalProp,
	requiredProp,
	runWithMinimumDelay,
	type ConfirmPopup,
	type ReactiveComponent,
} from "vue-utils"
import BootstrapButton from "../BootstrapButton"

interface Props {
	file: UploadedFile
	remove?(file: UploadedFile): void
}

const ExistingFileRow: ReactiveComponent<Props> = (props) => {
	const file = proxyProp(props, "file")
	const uploadedBy = toRef(() => file.uploaded.user)
	const uploadedDate = toRef(() => file.uploaded.timeStamp.atZone(ZoneId.SYSTEM).toLocalDate())

	const popups = usePopups()

	const downloading = ref(false)
	const removing = ref(false)

	async function download() {
		if (downloading.value) {
			return
		}

		downloading.value = true

		try {
			const downloadLink = await runWithMinimumDelay(generateDownloadLink(file.guid), 750)
			window.open(downloadLink, "_blank", "noopener noreferrer")
		} catch (ex) {
			void showErrorMessage("Failed to download file", (ex as Error).message)
		} finally {
			downloading.value = false
		}
	}

	async function remove() {
		if (removing.value || !props.remove) {
			return
		}

		const confirm: ConfirmPopup = {
			width: "32rem",
			content: () => (
				<Alert title="Confirm Delete">
					<p>
						Are you sure you want to delete {file.name}?<br />
						This action cannot be undone.
					</p>
				</Alert>
			),
		}

		if (!(await popups.confirm(confirm))) {
			return
		}

		removing.value = true

		try {
			void props.remove(file)
		} catch (ex) {
			void showErrorMessage("Failed to remove file", (ex as Error).message)
			removing.value = false
		}
	}

	return () => (
		<tr key={file.guid}>
			<td>
				<Icon icon={getFileIcon(file.mimeType)} />
				<span>{file.name}</span>
			</td>
			<td title="Date Uploaded">
				<Icon icon={faCalendar} />
				<span>{uploadedDate.value.toGBDateString()}</span>
			</td>
			<td title="Uploaded By">
				<Icon icon={faUser} />
				<span>{uploadedBy.value ? `${uploadedBy.value.firstName} ${uploadedBy.value.lastName}` : "Unknown"}</span>
			</td>
			<td>{formatFileSize(file.fileSize)}</td>
			<td>
				<BootstrapButton
					color="success"
					title="Download File"
					isSubmitting={downloading.value}
					disabled={downloading.value}
					onClick={() => void download()}
				>
					{!downloading.value && <Icon icon={faDownload} />}
				</BootstrapButton>
				{props.remove && (
					<BootstrapButton
						color="danger"
						title="Remove File"
						isSubmitting={removing.value}
						disabled={removing.value}
						onClick={() => void remove()}
					>
						{!removing.value && <Icon icon={faTrashAlt} />}
					</BootstrapButton>
				)}
			</td>
		</tr>
	)
}

export default defineComponent(ExistingFileRow, {
	file: requiredProp(Object),
	remove: optionalProp(Function),
})
