import Icon from "@/components/Icon"
import Blade, { closeBlade, toggleBlade } from "@/components/form/Blade"
import BootstrapButton from "@/components/form/BootstrapButton"
import SelectPeople from "@/components/form/PersonSelect/SelectPeople"
import Tile from "@/components/form/Tile"
import UserIndicator from "@/components/navigation/UserIndicator"
import type { Person } from "@/models"
import { ContractSection, useContractContext } from "@/pages/contracts/context"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowLeft, faArrowRight, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Teleport, computed, watch } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { validateStakeholders } from "../../validation"

const tileStyles = css`
	display: inline-flex;

	&.centred {
		justify-content: center;
		align-items: center;

		.addStaff {
		}
	}
`

const staffStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.8em;

	.addStaff {
		border-radius: 500em;
		background: var(--color-tenet-blue);
		color: var(--color-white);
		width: 70px;
		height: 70px;
		font-size: 1.7em;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`

const StakeholdersSection: ReactiveComponent = () => {
	const context = useContractContext()

	const contract = proxyProp(context, "contract")

	const stakeholderCount = computed(() => (context.stakeholders && context.stakeholders.length) ?? 0)

	function addStakeholders(stakeholders: Person[]) {
		context.stakeholders = [...stakeholders]
		contract.stakeholderIds = [...stakeholders.map((stakeholder) => stakeholder.id)]
		closeBlade()
	}

	watch(stakeholderCount, () => {
		if (!stakeholderCount.value) {
			context.visitedSections = [
				ContractSection.Supplier,
				ContractSection.Client,
				ContractSection.About,
				ContractSection.Stakeholders,
			]
		}
	})

	context.validate = () => {
		const errors = validateStakeholders(contract)

		if (errors.length > 0) return errors

		return true
	}

	return () => (
		<>
			<Tile
				style={{ minHeight: "20rem" }}
				class={[tileStyles, { centred: !stakeholderCount.value }]}
				onClick={() => toggleBlade()}
				onKeypress={(e) => e.key === "Enter" && toggleBlade()}
				role="button"
				tabindex={0}
			>
				<div class={staffStyles}>
					{context.stakeholders?.map((person) => (
						<UserIndicator
							key={person.id}
							firstName={person.firstName}
							lastName={person.lastName}
							style={{ width: "70px", height: "70px", fontSize: "1.7em" }}
						/>
					))}
					<span class="addStaff">
						<Icon icon={!stakeholderCount.value ? faPlus : faPencil} />
					</span>
				</div>
			</Tile>

			{context.entryType === "create" && (
				<div class="d-flex justify-between">
					<BootstrapButton
						color="secondary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.About, false)}
					>
						<Icon icon={faArrowLeft} />
						Back
					</BootstrapButton>

					{stakeholderCount.value > 0 && (
						<BootstrapButton
							color="primary"
							style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
							onClick={() => context.changeSection(ContractSection.Milestones, true)}
						>
							Next
							<Icon icon={faArrowRight} />
						</BootstrapButton>
					)}
				</div>
			)}

			<Teleport to="body">
				<Blade>
					<h2>Select Stakeholders</h2>
					<SelectPeople
						companyId={context.client!.id}
						existingPeople={context.stakeholders ?? []}
						includeUnassigned={false}
						single={false}
						name="Stakeholders"
						addPeople={(stakeholders: Person[]) => addStakeholders(stakeholders)}
					/>
				</Blade>
			</Teleport>
		</>
	)
}

export default defineComponent(StakeholdersSection)
