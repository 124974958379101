import Tile from "@/components/form/Tile"
import { proxyProp } from "@/utils/proxyProp"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const Address: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	return () => (
		<Tile>
			<label for="companyAddressLine1" class="tile-heading">
				<Required label="Address" />
			</label>
			<div class="row">
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressLine1"
							v-model={company.address.line1}
							placeholder="Line 1 *"
							class="form-control"
							required
						/>
						<label for="companyAddressLine1">Line 1 *</label>
					</div>
				</div>
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressLine2"
							v-model={company.address.line2}
							placeholder="Line 2"
							class="form-control"
						/>
						<label for="companyAddressLine2">Line 2</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressLine3"
							v-model={company.address.line3}
							placeholder="Line 3"
							class="form-control"
						/>
						<label for="companyAddressLine3">Line 3</label>
					</div>
				</div>
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressCity"
							v-model={company.address.city}
							placeholder="City"
							class="form-control"
						/>
						<label for="companyAddressCity">City</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressRegion"
							v-model={company.address.region}
							placeholder="Region"
							class="form-control"
						/>
						<label for="companyAddressRegion">Region</label>
					</div>
				</div>
				<div class="col">
					<div class="form-floating mb-3">
						<input
							type="text"
							id="companyAddressCountry"
							v-model={company.address.country}
							placeholder="Country"
							class="form-control"
						/>
						<label for="companyAddressCountry">Country</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<div class="form-floating mb-2">
						<input
							type="text"
							id="companyAddressPostcode"
							v-model={company.address.postcode}
							placeholder="Postcode *"
							class="form-control"
							required
						/>
						<label for="companyAddressPostcode">Postcode *</label>
					</div>
				</div>
			</div>
		</Tile>
	)
}

export default defineComponent(Address)
