import type { User } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { RouterLink } from "vue-router"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import ProfileNavigation from "./ProfileNavigation"
import UserIndicator from "./UserIndicator"

export interface RouteInformation {
	name: string
	hasAccess?(user: User): boolean
}

interface Props {
	routes: Record<string, RouteInformation>
	profileNav: boolean
}

const dropdownStyles = css`
	position: absolute;
	right: -1rem;
	top: -1rem;
	background: var(--color-white);
	box-shadow: -4px 0 20px 4px rgba(var(--color-tenet-black-rgb), 0.2);
	padding: 0 3rem 0.75rem 0;
`

const navListStyles = css`
	list-style: none;
	flex: 1 1 0;
	overflow: auto;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	text-wrap: nowrap;
	min-width: 200px;

	a {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		padding: 0.25rem 0.5rem;
		padding-left: 1rem;
		color: var(--color--tenet-black);
		text-decoration: none;

		&.nav-active-route {
			color: var(--color-tenet-orange);
		}

		&:hover {
			color: var(--color-tenet-orange);
		}
	}
`

const Navigation: ReactiveComponent<Props> = (props) => {
	const user = useLoggedInUser()
	const availableRoutes = computed(() => {
		const availableRoutes: Record<string, RouteInformation> = {}
		for (const url in props.routes) {
			const route = props.routes[url]
			if (!route.hasAccess || route.hasAccess(user)) {
				availableRoutes[url] = route
			}
		}
		return availableRoutes
	})

	return () => (
		<div class={dropdownStyles} style={!props.profileNav ? { paddingTop: "3.125rem" } : undefined}>
			{props.profileNav && (
				<UserIndicator
					firstName={user.firstName}
					lastName={user.lastName}
					showName={true}
					style={{ margin: "1rem 0 0 1rem" }}
				/>
			)}

			<ul class={navListStyles} style={props.profileNav ? { marginTop: "1rem" } : undefined}>
				{Object.entries(availableRoutes.value).map(([url, route]) => (
					<li key={url}>
						<RouterLink to={url} activeClass="nav-active-route">
							<span>{route.name}</span>
						</RouterLink>
					</li>
				))}
			</ul>

			{props.profileNav && <ProfileNavigation class={navListStyles} style={{ marginTop: "1rem" }} />}
		</div>
	)
}

export default defineComponent(Navigation, {
	routes: requiredProp(Object),
	profileNav: requiredProp(Boolean),
})
