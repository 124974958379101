import type { Id, Person } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"

export async function getPeople(): Promise<Person[]> {
	return await httpRequestJsonResponse<Person[]>("/api/people")
}

export async function getPerson(guid: string): Promise<Person> {
	return await httpRequestJsonResponse<Person>(`/api/people/${guid}`)
}

export async function createPerson(person: Person): Promise<Person> {
	return await httpJsonRequestJsonResponse<Person>("/api/people", person, RequestMethod.POST)
}

export async function updatePerson(person: Person): Promise<Person> {
	return await httpJsonRequestJsonResponse<Person>("/api/people", person, RequestMethod.PUT)
}

export async function getStaffForCompany(companyId: Id, includeUnassigned: boolean): Promise<Person[]> {
	return await httpRequestJsonResponse<Person[]>(
		`/api/people/for-company/${companyId}?includeUnassigned=${includeUnassigned}`
	)
}
