import Icon from "@/components/Icon"
import { proxyProp } from "@/utils/proxyProp"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"
import BootstrapButton from "../BootstrapButton"
import FileIndicator from "./FileIndicator"

interface Props {
	files: File[]
	multiple?: boolean
	remove?(index: number): void
	triggerPicker?(): void
}

const FileDisplay: ReactiveComponent<Props> = (props) => {
	const files = proxyProp(props, "files")

	props

	return () => (
		<>
			<div>
				<ul>
					{files.map((file, i) => (
						<li key={file.name}>
							<FileIndicator file={file} remove={props.remove ? () => void props.remove?.(i) : undefined} />
						</li>
					))}
				</ul>
			</div>
			{props.triggerPicker && (
				<span class="flex justify-end">
					<BootstrapButton color="success" onClick={props.triggerPicker}>
						<Icon icon={faUpload} />
						{props.multiple ? "Add another file" : "Pick a different file"}
					</BootstrapButton>
				</span>
			)}
		</>
	)
}

export default defineComponent(FileDisplay, {
	files: requiredProp(Array),
	multiple: optionalProp(Boolean),
	remove: optionalProp(Function),
	triggerPicker: optionalProp(Function),
})
