import { mapApiSurvey, type ApiTypeOf, type Survey } from "@/models"
import { sendHttpRequest } from "@/services/httpHelper"
import { RequestMethod, httpRequestJsonResponse } from "@/services/httpService"

async function readJson<T>(url: string, requestInit?: RequestInit) {
	const result = await sendHttpRequest(url, requestInit)
	return (await result.json()) as ApiTypeOf<T>
}

export async function getSurvey(reference: string): Promise<Survey> {
	const result = await readJson<Survey>(`/api/surveys/${reference}`)
	return mapApiSurvey(result)
}

export async function getSurveys(): Promise<Survey[]> {
	const result = await httpRequestJsonResponse<Survey[]>(`/api/surveys`)
	return result.map(mapApiSurvey)
}

export async function submitSurvey(survey: Survey): Promise<Survey> {
	const requestInit: RequestInit = {
		method: RequestMethod.PUT,
		body: JSON.stringify(survey),
		headers: {
			"content-type": "application/json",
		},
	}
	const result = await readJson<Survey>(`/api/surveys/${survey.reference}`, requestInit)
	return mapApiSurvey(result)
}
