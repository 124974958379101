import { toReactive } from "@vueuse/core"
import { toRef } from "vue"

/**
 * Allows using reactive props as a const value, maintaining reactivity on reads & writes
 * @param getProp A function to return the prop vale
 * @returns A proxy which forwards all access to the returned value
 */
export function proxyProp<T extends object>(getProp: () => T): T

/**
 * Allows using reactive props as a const value, maintaining reactivity on reads & writes
 * @param props Props
 * @param key Property on props on which to obtain a proxy for
 * @returns A proxy which forwards all access to props[key]
 */
export function proxyProp<T extends object, K extends keyof T, V extends T[K] & object>(props: T, key: K): V

export function proxyProp<T extends object, K extends keyof T, V extends T[K] & object>(
	props: T | (() => T),
	key?: K
): V {
	if (key !== undefined) {
		return proxyProp<V>(() => (props as T)[key] as V)
	}
	const getValue = props as () => V
	return toReactive(toRef(getValue)) as V
}
