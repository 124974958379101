import { computed, type ButtonHTMLAttributes, type FormHTMLAttributes } from "vue"
import { defineComponent, optionalProp, renderSlot, type ReactiveComponent } from "vue-utils"
import BasicForm, { type FormButton, type ValidationResult } from "./BasicForm"
import BootstrapButton, { type Props as BootstrapButtonProps } from "./BootstrapButton"

interface Props {
	submit?: SimpleFormButton
	cancel?: SimpleFormButton

	customErrorMessage?: string
	validateForm?: boolean
}

type SimpleFormButton =
	| {
			text?: string
			onClick(event: Event): void | Promise<void>
			validate?: () => ValidationResult
			buttonProps?: Partial<BootstrapButtonProps & Omit<ButtonHTMLAttributes, keyof BootstrapButtonProps>>
	  }
	| ((event: Event) => void | Promise<void>)

const SimpleForm: ReactiveComponent<Props, FormHTMLAttributes> = (props, { slots, attrs }) => {
	const buttons = computed<FormButton[]>(() => {
		const buttons: FormButton[] = []
		if (props.cancel) {
			const cancel = typeof props.cancel === "function" ? { text: "Cancel", onClick: props.cancel } : props.cancel
			buttons.push({
				id: "cancel",
				onSubmit: (e) => cancel.onClick(e),
				validate: cancel.validate,
				validateForm: false,
				render: (props) => (
					<BootstrapButton color="secondary" {...props} {...(cancel.buttonProps ?? {})}>
						{cancel.text ?? "Cancel"}
					</BootstrapButton>
				),
			})
		}
		if (props.submit) {
			const submit = typeof props.submit === "function" ? { text: "Submit", onClick: props.submit } : props.submit
			buttons.push({
				id: "submit",
				onSubmit: (e) => submit.onClick(e),
				validate: submit.validate,
				validateForm: props.validateForm ?? true,
				render: (props) => (
					<BootstrapButton color="primary" {...props} {...(submit.buttonProps ?? {})}>
						{submit.text ?? "Submit"}
					</BootstrapButton>
				),
			})
		}
		return buttons
	})

	return () => (
		<BasicForm buttons={buttons.value} {...props} {...attrs}>
			{renderSlot(slots)}
		</BasicForm>
	)
}

export default defineComponent(SimpleForm, {
	submit: optionalProp(Function, Object),
	cancel: optionalProp(Function, Object),
	customErrorMessage: optionalProp(String),
	validateForm: optionalProp(Function),
})
