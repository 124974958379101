/* eslint-disable new-cap */

import BootstrapButton from "@/components/form/BootstrapButton"
import ColorPicker from "@/components/form/ColorPicker"
import FileUpload from "@/components/form/FileUpload/FileUpload"
import Tile from "@/components/form/Tile"
import usePopups from "@/stores/popupsStore"
import { proxyProp } from "@/utils/proxyProp"
import { shallowRef, watch } from "vue"
import { Alert, defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const Branding: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	const popups = usePopups()

	const logoFile = shallowRef<File[]>([])
	const logoMimeTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/svg+xml"]

	watch(logoFile, () => (company.brandingInformation.logoFile = logoFile.value[0] ?? null))

	async function removeCurrentLogo() {
		const confirm = popups.confirm({
			content: () => (
				<Alert title="Confirm Remove Logo">
					<p>Are you sure you want to remove the current logo from this Company?</p>
				</Alert>
			),
			confirmText: "Yes",
			cancelText: "No",
		})

		if (!(await confirm)) return

		company.brandingInformation.logo = null

		await popups.showAlertPopup(() => (
			<Alert title="Success">
				<p>The logo will be removed when you save the Company.</p>
			</Alert>
		))
	}

	return () => (
		<Tile>
			<label class="tile-heading w-100">Branding</label>
			<label for="companyLogo" class="mt-2 mb-1 fw-normal">
				Logo
			</label>
			{((company.brandingInformation.logo && company.brandingInformation.logo.fileData) ||
				logoFile.value.length > 0) && (
				<div class="d-flex items-center justify-between gap-4 mb-3">
					{company.brandingInformation.logo && company.brandingInformation.logo.fileData && (
						<div class="form-control w-50 d-flex flex-column justify-center">
							<div class="d-flex justify-between mb-2 items-center" style={{ height: "32px" }}>
								<p class="fw-medium mb-0">Current Logo:</p>
								<BootstrapButton color="danger" class="py-1" onClick={() => void removeCurrentLogo()}>
									Remove
								</BootstrapButton>
							</div>
							<div class="px-5">
								<img
									src={`data:${company.brandingInformation.logo.mimeType};base64,${company.brandingInformation.logo.fileData}`}
									alt=""
									style={{ height: "80px", width: "100%", objectFit: "contain" }}
								/>
							</div>
						</div>
					)}

					{logoFile.value.length > 0 && (
						<div class="form-control w-50 d-flex flex-column justify-center">
							<p class="fw-medium mb-2 d-flex items-center" style={{ height: "32px" }}>
								New Logo:
							</p>
							<div class="px-5">
								<img
									src={URL.createObjectURL(logoFile.value[0])}
									onLoad={(event: Event) => URL.revokeObjectURL((event.target as HTMLImageElement).src)}
									alt=""
									style={{ height: "80px", width: "100%", objectFit: "contain" }}
								/>
							</div>
						</div>
					)}
				</div>
			)}
			<FileUpload id="companyLogo" onSelectionChanged={refSetter(logoFile)} accept={logoMimeTypes} narrow />

			<label for="companyMainColor" class="mt-3 mb-1 fw-normal">
				Main Colour
			</label>
			<ColorPicker
				id="companyMainColor"
				onInput={(hex: string) => (company.brandingInformation.primaryColor = hex)}
				initialValue={company.brandingInformation.primaryColor ?? undefined}
			/>

			<label for="companySecondaryColor" class="mt-3 mb-1 fw-normal">
				Secondary Colour
			</label>
			<ColorPicker
				id="companySecondaryColor"
				onInput={(hex: string) => (company.brandingInformation.secondaryColor = hex)}
				initialValue={company.brandingInformation.secondaryColor ?? undefined}
			/>
		</Tile>
	)
}

export default defineComponent(Branding)
