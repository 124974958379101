import { ContentLoading } from "@/components/loading/StateLoading"
import BasicPage from "@/pages/BasicPage"
import { getContract } from "@/services/contractsService"
import { HttpLoadingError } from "@/services/httpHelper"
import { computed } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import ManageContract from "./ManageContract"

const EditContractPage: ReactiveComponent = () => {
	const router = useRouter()
	const contractGuid = computed(() => router.currentRoute.value.params.id as string)
	const contractRef = useLoadableRef(loadContract)

	async function loadContract() {
		try {
			return await getContract(contractGuid.value)
		} catch (e) {
			if (e instanceof HttpLoadingError && e.statusCode === 404) {
				return null
			}
			throw e
		}
	}

	function renderContent() {
		if (contractRef.type !== "done") {
			return null
		}
		const contract = contractRef.result

		return !contract ? (
			<>
				<h1 class="mb-4">An error has occurred</h1>
				<p class="m-0">The contract you requested could not be found.</p>
			</>
		) : (
			<>
				<h1 class="mb-4">Edit {contract.name}</h1>
				<hr />
				<ManageContract contract={contract} />
			</>
		)
	}

	return () => (
		<BasicPage title="Edit Contract">
			<ContentLoading stores={[contractRef]}>{renderContent()}</ContentLoading>
		</BasicPage>
	)
}

export default defineComponent(EditContractPage)
