import BasicSelect from "@/components/form/BasicSelect"
import SimpleForm from "@/components/form/SimpleForm"
import Tile from "@/components/form/Tile"
import type { Id, Industry, MilestoneWeightedValue, Value } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { computed, onMounted, ref } from "vue"
import {
	ModalTitle,
	Required,
	defineComponent,
	requiredProp,
	useOnInputNumber,
	type ReactiveComponent,
} from "vue-utils"

interface Props {
	value: MilestoneWeightedValue
	availableValues: Value[]
	companyName: string
	edit: boolean

	save(value: MilestoneWeightedValue, add: boolean): void
	close(): void
}

const EditValue: ReactiveComponent<Props> = (props) => {
	const value = proxyProp(props, "value")
	const availableValues = proxyProp(props, "availableValues")

	const availableIndustries = computed(() => {
		const industries = availableValues.flatMap((value) => value.industries)
		return [...new Set(industries.map((industry) => JSON.stringify(industry)))].map(
			(item) => JSON.parse(item) as Industry
		)
	})

	interface SelectOption {
		id: Id
		name: string
	}
	const industryOptions = computed(() => {
		return availableIndustries.value.map((industry) => {
			return {
				id: industry.id,
				name: industry.name,
			} as SelectOption
		})
	})

	const valueOptions = computed(() => {
		if (!value.milestoneValue.industry) {
			return []
		}

		const industry = value.milestoneValue.industry

		const values = availableValues.filter((v) => v.industries.map((i) => i.id).includes(industry.id))

		return values.map((v) => {
			return {
				id: v.id,
				name: v.name,
			} as SelectOption
		})
	})

	const industrySelected = ref(false)
	const valueSelected = ref(false)

	onMounted(() => {
		industrySelected.value = !!value.milestoneValue.industry
		valueSelected.value = !!value.milestoneValue.value
	})

	return () => (
		<>
			<ModalTitle title={`${props.edit ? "Edit" : "Add"} Value`} />
			<hr />
			<SimpleForm
				submit={{
					onClick: () => props.save(value, !props.edit),
					text: props.edit ? "Save" : "Add",
					buttonProps: { disabled: !value.milestoneValue.value },
				}}
				cancel={{
					onClick: props.close,
					text: "Close",
				}}
			>
				<div class="row">
					<div class="col">
						<Tile>
							<label for="valueIndustry" class="tile-heading">
								<Required label="Industry"></Required>
							</label>
							<BasicSelect
								options={industryOptions.value}
								value={value.milestoneValue.industry?.name ?? null}
								getValue={(industryOption: SelectOption) => industryOption.name}
								setValue={(industryOption) => {
									industryOption &&
										(value.milestoneValue.industry =
											availableIndustries.value.find((i) => i.id == industryOption.id) ?? null)

									value.milestoneValue.value = null
								}}
								defaultText="Select an industry"
								defaultProps={{ disabled: true }}
								class="form-select"
								id="valueIndustry"
								disabled={industrySelected.value}
							/>
						</Tile>
					</div>
					<div class="col">
						<Tile class="me-0">
							<label for="valueOption" class="tile-heading">
								<Required label="Value" />
							</label>
							<BasicSelect
								options={valueOptions.value}
								value={value.milestoneValue.value ?? null}
								getValue={(valueOption: SelectOption) => valueOption.name}
								setValue={(valueOption) => {
									if (!valueOption) {
										return
									}

									const selectedValue = availableValues.find((v) => v.id == valueOption.id)
									if (selectedValue) {
										value.milestoneValue.value = selectedValue
										value.milestoneValue.name = selectedValue.name
										value.milestoneValue.description = selectedValue.description
									}
								}}
								defaultText="Select a value"
								defaultProps={{ disabled: true }}
								class="form-select"
								id="valueOption"
								disabled={!value.milestoneValue.industry || valueSelected.value}
							/>
						</Tile>
					</div>
				</div>

				{value.milestoneValue.value && (
					<>
						<div class="row">
							<div class="col">
								<Tile class="me-0">
									<label id="valueComments" class="tile-heading">
										<Required label={`Why is this important to ${props.companyName}?`} />
									</label>
									<textarea
										id="valueComments"
										v-model={value.comments}
										class="form-control"
										placeholder="Comments"
										rows={2}
									></textarea>
								</Tile>
							</div>
						</div>

						<div class="row">
							<div class="col">
								<Tile class="me-0">
									<label id="valueWeight" class="tile-heading">
										<Required label="Weight" />
									</label>
									<div class="d-flex gap-4">
										<input type="range" id="valueWeight" class="form-range" min={10} step={10} v-model={value.weight} />
										<span style={{ flexShrink: "0" }}>{value.weight}%</span>
									</div>
								</Tile>
							</div>
							<div class="col">
								<Tile class="me-0">
									<label id="valueMinSatisfactory" class="tile-heading">
										<Required label="Satisfactory Range" />
									</label>
									<div class="d-flex gap-4">
										<div class="double-range">
											<input
												type="range"
												class="form-range"
												min={10}
												step={10}
												v-model={value.minimumSatisfactoryScore}
												onInput={useOnInputNumber((val, event) => {
													const target = event.target as HTMLInputElement

													if (target && val === 100) target.style.zIndex = "1"
													else target.style.zIndex = ""

													if (val <= value.maximumSatisfactoryScore) value.minimumSatisfactoryScore = val
													else value.minimumSatisfactoryScore = value.maximumSatisfactoryScore
												})}
											/>
											<input
												type="range"
												class="form-range"
												min={10}
												step={10}
												v-model={value.maximumSatisfactoryScore}
												onInput={useOnInputNumber((val, event) => {
													const target = event.target as HTMLInputElement

													if (target && val === 100) target.style.zIndex = "0"
													else target.style.zIndex = ""

													if (val >= value.minimumSatisfactoryScore) value.maximumSatisfactoryScore = val
													else value.maximumSatisfactoryScore = value.minimumSatisfactoryScore
												})}
											/>
										</div>
										<span style={{ flexShrink: "0" }}>
											{value.minimumSatisfactoryScore}% - {value.maximumSatisfactoryScore}%
										</span>
									</div>
								</Tile>
							</div>
						</div>
					</>
				)}
			</SimpleForm>
		</>
	)
}

export default defineComponent(EditValue, {
	value: requiredProp(Object),
	availableValues: requiredProp(Array),
	companyName: requiredProp(String),
	edit: requiredProp(Boolean),

	save: requiredProp(Function),
	close: requiredProp(Function),
})
