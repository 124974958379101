import Modal from "@/components/Modal"
import Tile from "@/components/form/Tile"
import type { MilestoneResponse } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { computed, ref } from "vue"
import { defineComponent, refSetter, type ReactiveComponent } from "vue-utils"
import { useContractContext } from "../../context"
import ResponseModal from "./ResponseModal"
import ResponsesTable from "./ResponsesTable"

const ResponsesSection: ReactiveComponent = () => {
	const context = useContractContext()

	const contract = proxyProp(context, "contract")

	const responses = contract.milestones.flatMap((milestone) => milestone.responses)

	const selectedResponse = ref<MilestoneResponse | null>(null)

	const selectedMilestoneName = computed(() => {
		if (!selectedResponse.value) return "Unknown"

		const responseMilestone = contract.milestones.find((milestone) =>
			milestone.responses?.map((response) => response.id).includes(selectedResponse.value!.id)
		)
		return responseMilestone?.name ?? "Unknown"
	})

	function closePopup() {
		selectedResponse.value = null
	}

	return () => (
		<>
			<Tile>
				{responses.length ? (
					<ResponsesTable
						responses={responses as MilestoneResponse[]}
						milestones={contract.milestones}
						viewResponse={refSetter(selectedResponse)}
					/>
				) : (
					<h5 class="mx-2 my-3">No Responses Found</h5>
				)}
			</Tile>

			{selectedResponse.value && (
				<Modal style={{ width: "64rem", maxHeight: "50rem" }} class="flex flex-col">
					<ResponseModal
						response={selectedResponse.value as MilestoneResponse}
						milestoneName={selectedMilestoneName.value}
						close={closePopup}
					/>
				</Modal>
			)}
		</>
	)
}

export default defineComponent(ResponsesSection)
