import type { Company } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function useCompanyFiltering(options: {
	companies: MaybeRef<Company[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<Company[]> {
	const { searchTerm: search, companies } = options

	return computed(() => {
		const companiesArray = unref(companies)
		const searchTerm = unref(search).toLowerCase()

		if (searchTerm.length === 0) {
			return companiesArray
		}

		return unref(companies).filter((company) => {
			return company.name.includes(searchTerm)
		})
	})
}
