import SimpleForm from "@/components/form/SimpleForm"
import { defineStore } from "pinia"
import { createPopups, renderSlot } from "vue-utils"

const usePopups = defineStore("popups", () => {
	return createPopups({
		AlertPopup: ({ okText, onClose }, { slots }) => (
			<SimpleForm cancel={{ text: okText, onClick: onClose }}>{renderSlot(slots)}</SimpleForm>
		),
		ConfirmPopup: ({ confirmText, cancelText, onSubmit, onCancel }, { slots }) => (
			<SimpleForm cancel={{ text: cancelText, onClick: onCancel }} submit={{ text: confirmText, onClick: onSubmit }}>
				{renderSlot(slots)}
			</SimpleForm>
		),
	})
})

export default usePopups
