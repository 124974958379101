import type { LocalDate } from "@js-joda/core"

/**
 * Merges all entries from the Source FormData into the Destination FormData, with an optional prefix (SourcePrefix_Key: Value)
 */
export function mergeFormData(source: FormData, destination: FormData, sourcePrefix?: string): FormData {
	if (sourcePrefix) sourcePrefix = `${sourcePrefix}_`

	for (const item of source.entries()) destination.append(sourcePrefix + item[0], item[1])

	return destination
}

function localDateToFormData(obj: object, formData: FormData, key: string): FormData | null {
	try {
		const localDate = obj as LocalDate
		const jsonDate = localDate.toJSON()
		formData.set(key, jsonDate)
		return formData
	} catch {
		return null
	}
}

function objectToFormData(obj: object, formData: FormData, parentKey: string): FormData {
	if (!obj) return formData

	const entries = Object.entries(obj)

	if (entries.length > 0) {
		for (const [key, val] of entries) {
			const fullKey = `${parentKey}[${key}]`

			if (Array.isArray(val)) {
				for (let i = 0; i < val.length; i++) {
					const arrayVal = val[i] as object
					formData = objectToFormData(arrayVal, formData, `${fullKey}[${i}]`)
				}
			} else if (typeof val === "object") {
				let objectFormData: FormData | null = null

				objectFormData = localDateToFormData(val as object, formData, fullKey)
				if (objectFormData) {
					formData = objectFormData
				} else {
					formData = objectToFormData(val as object, formData, fullKey)
				}
			} else {
				formData.set(fullKey, String(val))
			}
		}
	} else {
		formData.set(parentKey, String(obj) ?? "")
	}

	return formData
}

export function convertToFormData(obj: object, rootKey: string): FormData {
	let formData = new FormData()
	formData = objectToFormData(obj, formData, rootKey)
	return formData
}
