import Icon from "@/components/Icon"
import Modal from "@/components/Modal"
import type { Milestone, MilestoneWeightedValue, Value } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { faClose, faExclamationTriangle, faPlus } from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"
import { css } from "vite-css-in-js"
import { ref, toRaw } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import EditValue from "./EditValue"

interface Props {
	values: Value[]
	milestone: Milestone
	companyName: string
}

const milestoneValueStyles = css`
	.values {
		counter-reset: values;
		padding: 0;

		.value {
			padding: 0.8em 1em;
			display: flex;
			gap: 1.5em;
			border-radius: 0.3em;
			margin-bottom: 2em;

			&:nth-child(2n) {
				background-color: var(--color-fill);
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: counter(values);
				counter-increment: values;
				display: grid;
				font-size: 1.2em;
				font-weight: 500;
				width: 35px;
				height: 35px;
				border: 1px solid;
				place-content: center;
				border-radius: 500px;
				flex-shrink: 0;
			}

			&::after {
				content: "\f040";
				font: var(--fa-font-solid);
				color: var(--color-tenet-black-rgb);
				opacity: 0.6;
				transition: opacity 0.1s;
			}

			.value-content {
				flex: 100%;
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	.add-value {
		height: 3rem;
		border: 1px solid rgba(var(--color-tenet-black-rgb), 0.3);
		border-radius: 0.3em;
		display: grid;
		place-content: center;
		padding: 2em;

		span {
			background: var(--color-fill);
			width: 40px;
			height: 40px;
			display: grid;
			place-content: center;
			border-radius: 100px;
			font-size: 1.1em;
		}
	}
`

const MilestoneValues: ReactiveComponent<Props> = (props) => {
	const values = proxyProp(props, "values")
	const milestone = proxyProp(props, "milestone")

	const selectedWeightedValue = ref<MilestoneWeightedValue | null>(null)

	const editMode = ref(false)

	const newWeightedValue: MilestoneWeightedValue = {
		id: 0,
		guid: crypto.randomUUID(),
		weight: 10,
		comments: "",
		minimumSatisfactoryScore: 10,
		maximumSatisfactoryScore: 10,
		milestoneValue: {
			id: 0,
			name: "",
			description: "",
			industry: null,
			value: null,
		},
	}

	const errorMessages = ref<string[]>([])
	const showErrors = ref(false)

	if (!milestone.milestoneWeightedValues) milestone.milestoneWeightedValues = []

	function editWeightedValue(weightedValue: MilestoneWeightedValue, edit: boolean) {
		showErrors.value = false
		selectedWeightedValue.value = _.cloneDeep(toRaw(weightedValue))
		editMode.value = edit
	}

	function closePopup() {
		selectedWeightedValue.value = null
	}

	function saveWeightedValue(weightedValue: MilestoneWeightedValue, add: boolean) {
		if (!validateWeightedValue(weightedValue)) {
			showErrors.value = true
			return
		}

		if (add) {
			milestone.milestoneWeightedValues!.push(weightedValue)
		} else {
			const existingValue = milestone.milestoneWeightedValues!.find(
				(value) => value.guid === weightedValue.guid
			) as MilestoneWeightedValue
			Object.assign(existingValue, weightedValue)
		}

		closePopup()
	}

	function validateWeightedValue(weightedValue: MilestoneWeightedValue) {
		const errors = []

		if (!weightedValue.comments || weightedValue.comments.trim() === "") {
			errors.push(`The Comments field (Why is this important to ${props.companyName}) is required`)
		}

		if (weightedValue.weight == null) {
			errors.push("The Weight field is required")
		}

		if (weightedValue.minimumSatisfactoryScore == null || weightedValue.maximumSatisfactoryScore == null) {
			errors.push("The Satisfactory Range is required")
		}

		if (weightedValue.minimumSatisfactoryScore == null || weightedValue.maximumSatisfactoryScore == null) {
			errors.push(`The Minimum Satisfactory Score cannot be greater than ${weightedValue.maximumSatisfactoryScore}`)
		}

		errorMessages.value = errors

		return errorMessages.value.length === 0
	}

	return () => (
		<div class={milestoneValueStyles}>
			<p>Click on a value to edit its properties</p>

			{milestone.milestoneWeightedValues && (
				<ol class="values">
					{milestone.milestoneWeightedValues.map((weightedValue) => (
						<li class="value" key={weightedValue.guid}>
							<div
								class="value-content"
								onClick={() => editWeightedValue(weightedValue, true)}
								onKeypress={(event) => event.key === "Enter" && editWeightedValue(weightedValue, true)}
								tabindex={0}
								role="button"
							>
								<strong>
									{weightedValue.milestoneValue.name}{" "}
									{weightedValue.milestoneValue.industry && (
										<small>({weightedValue.milestoneValue.industry.name})</small>
									)}
								</strong>
								<p class="mb-0">{weightedValue.milestoneValue.description}</p>
								<hr class="my-2" />
								<div class="pt-1">
									<strong>Why is this important to {props.companyName}?</strong>
									<p>{weightedValue.comments}</p>
								</div>
								<hr class="my-2" />
								<div class="pt-1">
									<strong>Weight</strong>
									<div class="d-flex gap-4">
										<input type="range" class="form-range" min={10} max={100} value={weightedValue.weight} disabled />
										<span style={{ flexShrink: "0" }}>{weightedValue.weight}%</span>
									</div>
								</div>
								<div class="pt-3">
									<strong>Satisfactory Range</strong>
									<div class="d-flex gap-4">
										<div class="double-range">
											<input
												type="range"
												class="form-range"
												min={10}
												max={100}
												value={weightedValue.minimumSatisfactoryScore}
												disabled
											/>
											<input
												type="range"
												class="form-range"
												min={10}
												max={100}
												value={weightedValue.maximumSatisfactoryScore}
												disabled
											/>
										</div>
										<span style={{ flexShrink: "0" }}>
											{weightedValue.minimumSatisfactoryScore}% - {weightedValue.maximumSatisfactoryScore}%
										</span>
									</div>
								</div>
							</div>
						</li>
					))}
				</ol>
			)}

			<div
				class="add-value"
				onClick={() => editWeightedValue(newWeightedValue, false)}
				onKeypress={(event) => event.key === "Enter" && editWeightedValue(newWeightedValue, false)}
				tabindex={0}
				role="button"
			>
				<span>
					<Icon icon={faPlus} />
				</span>
			</div>

			{selectedWeightedValue.value && (
				<Modal style={{ width: "64rem" }} class="flex flex-col">
					<EditValue
						value={selectedWeightedValue.value}
						edit={editMode.value}
						availableValues={values}
						companyName={props.companyName}
						save={saveWeightedValue}
						close={closePopup}
					/>

					<div class={["contract-error", { shown: showErrors.value }]}>
						<div class="alert alert-danger">
							<div class="d-flex gap-2 align-items-center">
								<Icon icon={faExclamationTriangle} />
								<strong>Error</strong>
								<ul>
									{errorMessages.value.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							</div>
							<button type="button" class="btn-close" onClick={() => (showErrors.value = false)}>
								<Icon icon={faClose} />
							</button>
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default defineComponent(MilestoneValues, {
	values: requiredProp(Array),
	milestone: requiredProp(Object),
	companyName: requiredProp(String),
})
