import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import ColumnTable from "@/components/table/ColumnTable"
import { useGenericFiltering } from "@/composition/useGenericFiltering"
import { useTablePaging } from "@/composition/useTablePaging"
import type { Contract } from "@/models"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { debounce, defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	contracts: Contract[]
	activeOnly?: boolean
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const ContractsTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")

	const contracts = props.contracts.filter((_) =>
		props.activeOnly ? _.active && _.completionDate.isAfter(LocalDate.now()) : true
	)

	const filteredContracts = useGenericFiltering({
		items: toRef(contracts),
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredContracts)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<ColumnTable
				getKey={(contract) => contract.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "auto",
						renderContent: ({ item: contract }) => contract.name,
					},
					startDate: {
						label: "Start Date",
						size: "auto",
						renderContent: ({ item: contract }) => contract.startDate.toGBDateString(),
					},
					completionDate: {
						label: "Completion Date",
						size: "auto",
						renderContent: ({ item: contract }) => contract.completionDate.toGBDateString(),
					},
					assessmentScore: {
						label: "Assessment Score",
						size: "auto",
						renderContent: ({ item: contract }) => contract.assessmentScore + "%",
					},
					status: {
						label: "Status",
						size: "auto",
						renderContent: ({ item: contract }) => {
							if (!contract.active) return <span>Pending</span>
							if (contract.completionDate.isBefore(LocalDate.now()))
								return <span class="fw-medium text-danger">Closed</span>
							return <span class="fw-normal">Active</span>
						},
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: contract }) => (
							<a href={`/contracts/edit/${contract.guid}`} class="btn btn-secondary">
								<Icon icon={faEdit} /> <span>Edit</span>
							</a>
						),
					},
				}}
			/>

			<PagingControls entityName="Contracts" />
		</>
	)
}

export default defineComponent(ContractsTable, {
	contracts: requiredProp(Array),
	activeOnly: optionalProp(Boolean),
})
