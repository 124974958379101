import { MilestoneValueType } from "@/enums"
import type { MilestoneResponse, MilestoneResponseValue, Survey } from "@/models"
import { LocalDate } from "@js-joda/core"
import { shallowReactive, type InjectionKey } from "vue"
import { useService } from "vue-utils"

export enum SurveySection {
	Intro = "intro",
	Values = "values",
	Outro = "outro",
}

export type SurveySectionTransitionName = "slideLeft" | "slideRight"

export const SurveyContextInjectionKey = Symbol("SurveyContext") as InjectionKey<SurveyContext>

export function useSurveyContext(): SurveyContext {
	return useService(SurveyContextInjectionKey)
}

export class SurveyContext {
	private data = shallowReactive({
		section: SurveySection.Intro,
		sectionTransitionName: "slideRight" as SurveySectionTransitionName,
		valueIndex: 0,
		visitedIndexes: [] as number[],
	})

	constructor(public readonly survey: Survey) {
		this.survey = survey

		const milestone = survey.milestone
		const weightedValues = milestone.milestoneWeightedValues

		const responseValues: MilestoneResponseValue[] = []
		weightedValues!.forEach((weightedValue) => {
			responseValues.push({
				id: 0,
				value: 50,
				milestoneValue: weightedValue.milestoneValue,
				type: MilestoneValueType.rating,
			})

			responseValues.push({
				id: 0,
				value: 50,
				milestoneValue: weightedValue.milestoneValue,
				type: MilestoneValueType.confidence,
			})
		})

		const response: MilestoneResponse = {
			id: 0,
			assessmentScore: 0,
			responseDate: LocalDate.now(),
			responseValues,
		}

		milestone.responses = [response]
	}

	get section() {
		return this.data.section
	}
	set section(section: SurveySection) {
		this.data.section = section
	}

	get sectionTransitionName(): SurveySectionTransitionName {
		return this.data.sectionTransitionName
	}
	set sectionTransitionName(name: SurveySectionTransitionName) {
		this.data.sectionTransitionName = name
	}

	get valueIndex() {
		return this.data.valueIndex
	}
	set valueIndex(index: number) {
		this.data.valueIndex = index
	}

	get visitedIndexes(): number[] {
		return this.data.visitedIndexes
	}
	set visitedIndexes(indexes: number[]) {
		this.data.visitedIndexes = indexes
	}
	addIndex(index: number) {
		this.data.visitedIndexes.push(index)
	}
}
