import Icon from "@/components/Icon"
import Blade, { closeBlade, toggleBlade } from "@/components/form/Blade"
import SelectPeople from "@/components/form/PersonSelect/SelectPeople"
import Tile from "@/components/form/Tile"
import UserIndicator from "@/components/navigation/UserIndicator"
import type { Person } from "@/models"
import { proxyProp } from "@/utils/proxyProp"
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Teleport } from "vue"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const staffStyles = css`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5em;

	.addStaff {
		border-radius: 500em;
		background: var(--color-tenet-blue);
		color: var(--color-white);
		width: 50px;
		height: 50px;
		font-size: 1.2em;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
`

const Staff: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	function addStaff(staff: Person[]) {
		company.staff = [...staff]
		closeBlade()
	}

	return () => (
		<>
			<Tile>
				<label class="tile-heading">
					<Required label="Staff" />
				</label>
				<div class={staffStyles}>
					{company.staff?.map((person) => (
						<UserIndicator
							key={person.id}
							firstName={person.firstName}
							lastName={person.lastName}
							style={{ width: "50px", height: "50px" }}
						/>
					))}
					<span
						class="addStaff"
						onClick={() => toggleBlade()}
						onKeypress={(e) => e.key === "Enter" && toggleBlade()}
						role="button"
						tabindex={0}
					>
						<Icon icon={!company.staff || company.staff.length === 0 ? faPlus : faPencil} />
					</span>
				</div>
			</Tile>

			<Teleport to="body">
				<Blade>
					<h2>Select Staff Members</h2>
					<SelectPeople
						companyId={company.id}
						existingPeople={company.staff ?? []}
						includeUnassigned={true}
						single={false}
						name="Staff Members"
						addPeople={(staff: Person[]) => addStaff(staff)}
					/>
				</Blade>
			</Teleport>
		</>
	)
}

export default defineComponent(Staff)
