import { createRouter, createWebHistory, type RouteComponent, type RouteRecordRaw } from "vue-router"
import NotFound from "./NotFound"
import CompanyPage from "./company"
import AddCompanyPage from "./company/AddCompanyPage"
import EditCompaniesPage from "./company/EditCompaniesPage"
import ContractsPage from "./contracts"
import AddContractPage from "./contracts/add-contract"
import EditContractPage from "./contracts/edit-contract"
import DashboardPage from "./dashboard"
import LogoutPage from "./logout"
import PeoplePage from "./people"
import AddPersonPage from "./people/AddPersonPage"
import EditPersonPage from "./people/EditPersonPage"
import ResetPasswordPage from "./reset-password"
import SurveyPage from "./survey"
import UsersPage from "./users"
import AddUserPage from "./users/AddUserPage"
import EditUserPage from "./users/EditUserPage"

const simpleRoutes: Record<string, RouteComponent> = {
	"/": DashboardPage,
	"/logout": LogoutPage,
	"/users": UsersPage,
	"/users/add": AddUserPage,
	"/users/edit/:id": EditUserPage,
	"/people": PeoplePage,
	"/people/add": AddPersonPage,
	"/people/edit/:id": EditPersonPage,
	"/companies": CompanyPage,
	"/companies/add": AddCompanyPage,
	"/companies/edit/:id": EditCompaniesPage,
	"/contracts": ContractsPage,
	"/contracts/add": AddContractPage,
	"/contracts/edit/:id": EditContractPage,
	"/reset-password": ResetPasswordPage,
	"/survey/:reference": SurveyPage,
	"/:pathMatch(.*)*": NotFound,
}

const routes: RouteRecordRaw[] = [
	...Object.entries(simpleRoutes).map(([path, component]) => ({
		path,
		component,
		name: component.name,
	})),
]

const router = createRouter({
	routes: [...routes],
	history: createWebHistory(),
})

export default router
