import Icon from "@/components/Icon"
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import type { ButtonHTMLAttributes } from "vue"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	icon: IconDefinition
}

const buttonStyles = css`
	all: unset;
	font-size: 1.25rem;
	padding: 0.25rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	& > svg:hover {
		color: #556a80;
	}

	&:disabled > svg {
		color: #a0a0a0;
		cursor: not-allowed;
	}
`

const PlainIconButton: ReactiveComponent<Props, ButtonHTMLAttributes> = (props, { attrs }) => {
	return () => (
		<button class={buttonStyles} {...attrs}>
			<Icon icon={props.icon} />
		</button>
	)
}

export default defineComponent(PlainIconButton, {
	icon: requiredProp(Object),
})
