import { LocalDate } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import type { GenericEntity, INamed, IUniqueIdentifier, Nullable } from "./GenericEntity"
import { mapApiMilestoneReponse, type MilestoneResponse } from "./MilestoneResponse"
import type { MilestoneWeightedValue } from "./MilestoneWeightedValue"

export interface Milestone extends GenericEntity, INamed, IUniqueIdentifier {
	reviewDate: LocalDate
	assessmentScore: number
	minimumResponsePercentage: number
	status: string
	responses: Nullable<MilestoneResponse[]>
	milestoneWeightedValues: Nullable<MilestoneWeightedValue[]>
}

export function mapApiMilestone(milestone: ApiTypeOf<Milestone>): Milestone {
	return {
		...milestone,
		reviewDate: LocalDate.parse(milestone.reviewDate),
		responses: milestone.responses && milestone.responses.map(mapApiMilestoneReponse),
	}
}
