import SearchBar from "@/components/SearchBar"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { ContentLoading } from "@/components/loading/StateLoading"
import { SystemRole } from "@/enums"
import type { Company } from "@/models"
import { computed, reactive, ref, watch } from "vue"
import {
	FormTabs,
	FormTabsRenderingMethod,
	RefQueries,
	debounce,
	defineComponent,
	refSetter,
	useLoadableRef,
	useQueryRef,
	type FormTab,
	type ReactiveComponent,
} from "vue-utils"
import { getCompanies } from "../../services/companiesService"
import AuthorizeRole from "../AuthorizeRole"
import BasicPage from "../BasicPage"
import CompaniesTable from "./CompaniesTable"
import ManageAssociations from "./manage-associations"

const CompanyPage: ReactiveComponent = () => {
	const companiesRef = useLoadableRef(async () => {
		const companies = await getCompanies()
		return reactive(companies)
	})

	const searchTerm = useQueryRef("company", "", RefQueries.String)
	const filteredCompanies = computed(() => {
		if (!searchTerm.value || companiesRef.type !== "done") return

		const data = companiesRef.result
		const search = searchTerm.value.toLowerCase()

		return data
			.filter((company) => company.name.toLowerCase().includes(search))
			.sort((a, b) => a.name.toLowerCase().indexOf(search) - b.name.toLowerCase().indexOf(search))
	})

	const updateSearch = debounce((newSearch: string) => (searchTerm.value = newSearch))

	const tabs = computed(() => {
		const tabs: FormTab[] = []

		tabs.push({
			id: "search",
			name: "Search Companies",
			renderContent: () => (
				<>
					<div class="d-flex flex-fill justify-center">
						<Tile class="mt-4" style={{ width: "45%" }}>
							<h3 class="mb-3">Search for a Company</h3>
							<SearchBar search={searchTerm.value} setSearch={updateSearch} />
						</Tile>
					</div>

					{filteredCompanies.value && (
						<Tile>
							{filteredCompanies.value.length === 0 && (
								<div class="my-4">
									<h4 class="text-center">No Companies Found</h4>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<TenetButton url={`/companies/add?name=${searchTerm.value}`}>Add a Company</TenetButton>
									</div>
								</div>
							)}

							{filteredCompanies.value.length > 0 && (
								<CompaniesTable companies={filteredCompanies.value as Company[]} />
							)}
						</Tile>
					)}
				</>
			),
		})

		if (companiesRef.type === "done") {
			tabs.push({
				id: "associate",
				name: "Manage Associations",
				renderContent: () => <ManageAssociations companies={companiesRef.result as Company[]} />,
			})
		}

		return tabs
	})
	const tabQuery = useQueryRef("tab", tabs.value[0].id, RefQueries.String)
	const tabId = ref(tabQuery.value)

	watch(tabId, () => {
		tabQuery.value = tabId.value
	})

	return () => (
		<AuthorizeRole role={SystemRole.SuperAdmin}>
			<BasicPage title="Companies">
				<ContentLoading stores={[companiesRef]}>
					<h1>Companies</h1>
					<hr />
					<FormTabs
						tabs={tabs.value}
						selectedTabId={tabId.value}
						setSelectedTab={refSetter(tabId)}
						content={FormTabsRenderingMethod.RenderActive}
					/>
				</ContentLoading>
			</BasicPage>
		</AuthorizeRole>
	)
}

export default defineComponent(CompanyPage)
