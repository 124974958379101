export function setScrollBehaviour() {
	setScrollbarWidth()
	setHeaderScroll()
}

export function setScrollbarWidth() {
	const main = document.querySelector("main")
	if (main === null) return

	const scrollbarWidth = window.innerWidth - main.clientWidth
	document.querySelector("html")?.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`)
}

export function setHeaderScroll() {
	const main = document.querySelector("main")
	if (main === null) return

	main.addEventListener("scroll", () => {
		const classNames = ["shadow", "border-bottom"]
		const header = document.querySelector("header")
		if (header === null) return

		if (main.scrollTop >= 1) classNames.map((c) => header.classList.add(c))
		else classNames.map((c) => header.classList.remove(c))
	})
}
