import type { Person } from "@/models"
import { computed, unref, type ComputedRef, type MaybeRef } from "vue"

export function usePersonFiltering(options: {
	people: MaybeRef<Person[]>
	searchTerm: MaybeRef<string>
}): ComputedRef<Person[]> {
	const { searchTerm: search, people } = options

	return computed(() => {
		const personArray = unref(people)
		const searchTerm = unref(search).toLowerCase()

		if (searchTerm.length === 0) {
			return personArray
		}

		return unref(people).filter((person) => {
			const name = `${person.firstName} ${person.lastName}`
			return name.includes(searchTerm) || person.emailAddress.includes(searchTerm)
		})
	})
}
