import usePopups from "@/stores/popupsStore"
import { computed } from "vue"
import { defineComponent, Modal, type Popup, type ReactiveComponent } from "vue-utils"

const Popups: ReactiveComponent = () => {
	const popups = usePopups()
	const popup = computed(() => (popups.popups.length === 0 ? null : popups.popups[popups.popups.length - 1]))

	return () => {
		if (!popup.value) {
			return null
		}
		const Content = popup.value.content
		return (
			<Modal
				zIndex={3}
				style={{
					minWidth: `min(${popup.value.width ?? 28}rem, calc(100vw - 4rem))`,
					width: popup.value.width ?? "22.5%",
				}}
				onCancel={(e) => {
					if (popup.value && popup.value.closable) {
						popup.value.onClose?.()
						popups.closePopup(popup.value)
					} else {
						e.preventDefault()
					}
				}}
			>
				<Content close={() => popups.closePopup(popup.value as Popup)} popup={popup.value} />
			</Modal>
		)
	}
}

export default defineComponent(Popups)
