import { LocalDate } from "@js-joda/core"
import type { ApiTypeOf } from "./ApiType"
import { mapApiCompany, type Company } from "./Company"
import type { GenericEntity, INamed, IUniqueIdentifier, Id, Nullable } from "./GenericEntity"
import { mapApiMilestone, type Milestone } from "./Milestone"
import type { Person } from "./Person"
import { mapApiUploadedFile, type UploadedFile } from "./UploadedFile"

export interface Contract extends GenericEntity, INamed, IUniqueIdentifier {
	startDate: LocalDate
	completionDate: LocalDate
	description: string
	fullText: string
	assessmentScore: number
	active: boolean

	client: Nullable<Company>
	clientId: Nullable<Id>

	supplier: Nullable<Company>
	supplierId: Nullable<Id>

	uploadedFiles: Nullable<UploadedFile[]>

	stakeholderIds: Id[]
	stakeholders: Nullable<Person[]>

	milestones: Milestone[]

	filesForUpload: Nullable<File[]>
}

export function mapApiContract(contract: ApiTypeOf<Contract>): Contract {
	return {
		...contract,
		startDate: LocalDate.parse(contract.startDate),
		completionDate: LocalDate.parse(contract.completionDate),
		uploadedFiles: contract.uploadedFiles && contract.uploadedFiles.map(mapApiUploadedFile),
		milestones: contract.milestones && contract.milestones.map(mapApiMilestone),
		client: contract.client && mapApiCompany(contract.client),
		supplier: contract.supplier && mapApiCompany(contract.supplier),
	}
}
