import { css } from "vite-css-in-js"
import { computed, onMounted, ref, type HTMLAttributes } from "vue"
import { defineComponent, optionalProp, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	value: number
	maximum: number
	title: string
	className?: string
	animate?: boolean
	duration?: number
	delay?: number
}

const barStyles = css`
	margin: 1.4em 0 2.2em;

	h3 {
		font-size: 1.35em;
		margin-bottom: 0.6em;
	}

	.bar {
		width: calc(100% + 2em);
		overflow: hidden;
		position: relative;
		border-radius: 300px;
		padding: 1em;
		inset: -1em;
		margin-bottom: -2em;
		cursor: help;

		.bar-background {
			height: 1.1em;
			background-color: var(--color-chart-background);
			border-radius: 300px;
			box-shadow:
				inset 0 0 0 1px var(--color-chart-border),
				0 0 20px 0 rgba(var(--color-tenet-darkgrey-rgb), 0.3);
		}

		.bar-progress {
			position: absolute;
			inset: 1em;
			right: 100%;
			background: var(--color-tenet-black);
			border-radius: 300px;
		}
	}

	&.ct-series-a {
		.bar .bar-progress {
			background-color: var(--color-chart-green);
		}
	}

	&.ct-series-b {
		.bar .bar-progress {
			background-color: var(--color-chart-orange);
		}
	}

	&.ct-series-c {
		.bar .bar-progress {
			background-color: var(--color-chart-red);
		}
	}

	&.ct-series-d {
		.bar .bar-progress {
			background-color: var(--color-chart-blue);
		}
	}
`

const StaticProgressBar: ReactiveComponent<Props, HTMLAttributes> = (props, { attrs }) => {
	const progressBarRef = ref<HTMLDivElement>()
	const chartTooltipRef = ref<HTMLDivElement>()

	const fillAmount = computed(() => {
		return 100 - Math.round((props.value / props.maximum) * 100)
	})

	onMounted(() => {
		const progressBar = progressBarRef.value as HTMLDivElement
		if (progressBar == null) return

		if (props.animate) {
			setTimeout(() => {
				progressBar.style.right = `${fillAmount.value}%`
			}, props.delay ?? 0)
		} else {
			progressBar.style.right = `${fillAmount.value}%`
		}
	})

	function mouseEnter() {
		const tooltip = chartTooltipRef.value
		if (tooltip == null) return
		tooltip.classList.add("show")
	}

	function mouseLeave() {
		const tooltip = chartTooltipRef.value
		if (tooltip == null) return
		tooltip.classList.remove("show")
	}

	function mouseMove(evt: MouseEvent) {
		const tooltip = chartTooltipRef.value
		if (tooltip == null) return

		tooltip.style.left = `${evt.pageX + 15}px`
		tooltip.style.top = `${evt.pageY + 25}px`
	}

	return () => (
		<div class={[barStyles, props.className]} {...attrs}>
			<h3>{props.title}</h3>
			<div class="bar" onMouseenter={mouseEnter} onMouseleave={mouseLeave} onMousemove={mouseMove}>
				<div class="bar-background"></div>
				<div class="bar-progress" ref={progressBarRef} style={{ transition: `right ${props.duration ?? 0}ms` }}></div>
			</div>
			<div class={["ct-tooltip fade", props.className]} ref={chartTooltipRef}>
				<p class="fw-medium mb-0">
					{props.title}: {props.value}
				</p>
			</div>
		</div>
	)
}

export default defineComponent(StaticProgressBar, {
	value: requiredProp(Number),
	maximum: requiredProp(Number),
	title: requiredProp(String),
	className: optionalProp(String),
	animate: optionalProp(Boolean),
	duration: optionalProp(Number),
	delay: optionalProp(Number),
})
