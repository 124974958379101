import Icon from "@/components/Icon"
import { SystemRole } from "@/enums"
import type { Company, Contract, Nullable } from "@/models"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import "@fortawesome/fontawesome-free/css/solid.min.css"
import { faClose, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { LocalDate } from "@js-joda/core"
import { Teleport, computed, h, provide } from "vue"
import { defineComponent, requiredProp, type Component, type ReactiveComponent } from "vue-utils"
import { ContractContext, ContractContextInjectionKey, ContractSection } from "../context"
import {
	AboutContractSection,
	ClientSection,
	MilestonesSection,
	ReviewContractSection,
	StakeholdersSection,
	SupplierSection,
} from "../contract-sections"

interface Props {
	company: Nullable<Company>
}

type HiddenSections = ContractSection.Responses
type VisibleContractSections = Exclude<ContractSection, HiddenSections>

const AddContractWizard: ReactiveComponent<Props> = (props) => {
	const loggedInUser = useLoggedInUser()

	const client = computed(() => {
		if (props.company && loggedInUser.role == SystemRole.Client) {
			return props.company
		}

		return null
	})

	const supplier = computed(() => {
		if (props.company && loggedInUser.role == SystemRole.Supplier) {
			return props.company
		}

		return null
	})

	const contract: Contract = {
		id: 0,
		guid: crypto.randomUUID(),
		name: "",
		startDate: LocalDate.now(),
		completionDate: LocalDate.now().plusYears(1),
		assessmentScore: 0,
		description: "",
		fullText: "",
		active: false,
		clientId: client.value && client.value.id,
		supplierId: supplier.value && supplier.value.id,
		uploadedFiles: null,
		milestones: [],
		stakeholderIds: [],
		filesForUpload: [],
		client: client.value,
		supplier: supplier.value,
		stakeholders: null,
	}

	const context = new ContractContext(contract, "create")
	provide(ContractContextInjectionKey, context)

	const sections: Record<VisibleContractSections, Component> = {
		[ContractSection.Supplier]: SupplierSection,
		[ContractSection.Client]: ClientSection,
		[ContractSection.About]: AboutContractSection,
		[ContractSection.Stakeholders]: StakeholdersSection,
		[ContractSection.Milestones]: MilestonesSection,
		[ContractSection.Review]: ReviewContractSection,
	}

	if (!context.showSupplierSection) {
		context.section = ContractSection.Client
	}

	function renderBreadcrumbs() {
		const steps = Object.keys(sections)

		if (!context.showSupplierSection) {
			steps.shift()
		}

		const sectionIndex = steps.indexOf(context.section)

		return (
			<ul class="section-nav">
				{steps.map((section, index) => {
					const active = sectionIndex === index
					if (active) {
						context.addVisitedSection(section)
					}
					const visited = context.visitedSections.includes(section)

					return (
						<li key={section} class={{ active, visited }}>
							<div
								onClick={() => {
									if (!active && visited) {
										context.changeSection(section as ContractSection, index > sectionIndex)
									}
								}}
								onKeypress={(e) => e.key === "Enter" && e.target?.dispatchEvent(new Event("click"))}
								role="button"
								tabindex={0}
							>
								{section}
							</div>
						</li>
					)
				})}
			</ul>
		)
	}

	return () => (
		<>
			{renderBreadcrumbs()}
			<h2 class="section-title">{context.section}</h2>

			{h(sections[context.section as VisibleContractSections])}

			<Teleport to="body">
				<div class={["contract-error", { shown: context.errorMessages.length }]}>
					<div class="alert alert-danger">
						<div class="d-flex gap-2 align-items-center">
							<Icon icon={faExclamationTriangle} />
							<strong>Error</strong>
							<ul>
								{context.getLastErrors().map((error, index) => (
									<li key={index}>{error}</li>
								))}
							</ul>
						</div>
						<button type="button" class="btn-close" onClick={() => context.closeErrors()}>
							<Icon icon={faClose} />
						</button>
					</div>
				</div>
			</Teleport>
		</>
	)
}

export default defineComponent(AddContractWizard, {
	company: requiredProp(Object),
})
