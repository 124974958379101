import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import type { Company } from "@/models"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useCompanyFiltering } from "./filtering"

interface Props {
	companies: Company[]
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const CompaniesTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")

	const filteredCompanies = useCompanyFiltering({
		companies: toRef(props, "companies"),
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredCompanies)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<ColumnTable
				getKey={(company) => company.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "auto",
						renderContent: ({ item: company }) => company.name,
					},
					email: {
						label: "Primary Contact",
						size: "auto",
						renderContent: ({ item: company }) => company.primaryContact,
					},
					phoneNumber: {
						label: "Address",
						size: "auto",
						renderContent: ({ item: company }) =>
							company.address &&
							Object.values(company.address)
								.filter((v) => v && v !== "")
								.join(", "),
					},
					notes: {
						label: "Notes",
						size: "auto",
						renderContent: ({ item: company }) =>
							company.notes && (company.notes?.length > 100 ? company.notes?.substring(0, 100) + "..." : company.notes),
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: company }) => (
							<a href={`/companies/edit/${company.guid}`} class="btn btn-secondary">
								<Icon icon={faEdit} /> <span>Edit</span>
							</a>
						),
					},
				}}
			/>

			<PagingControls entityName="Companies" />
		</>
	)
}

export default defineComponent(CompaniesTable, {
	companies: requiredProp(Array),
})
