import "bootstrap/dist/css/bootstrap.css"
import "chartist/dist/index.scss"
import "vue-utils/dist/style.css"
import "./app.scss"
import "./fonts"

import "./utils/extensions"

import { isInPopup } from "oidc-vue"
import { createPinia } from "pinia"
import { createApp } from "vue"
import { AppAuthContext } from "./authentication"

import App from "./App"
import router from "./pages/routes"

AppAuthContext.options.router = router
await AppAuthContext.initialize()
if (!isInPopup()) {
	try {
		if (await AppAuthContext.needsSignIn()) {
			AppAuthContext.user = null
		}
	} catch (e) {
		console.error(e)
	}

	const app = createApp(App)
	app.use(createPinia())
	app.use(router)
	app.use(AppAuthContext)

	if (document.readyState === "loading") {
		await new Promise((resolve) => document.addEventListener("DOMContentLoaded", resolve))
	}
	app.mount("#app")
}
