import { Duration } from "@js-joda/core"
import { RouterView } from "vue-router"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { StateLoading } from "./components/loading/StateLoading"
import { useStoreRefresh } from "./composition/useStoreRefresh"
import { useIndustriesStore } from "./stores/industriesStore"

const MainApp: ReactiveComponent = () => {
	const industriesStore = useIndustriesStore()
	useStoreRefresh({ industries: industriesStore }, Duration.ofHours(12))

	return () => (
		<StateLoading stores={[industriesStore]}>
			<RouterView />
		</StateLoading>
	)
}

export default defineComponent(MainApp)
