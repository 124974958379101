import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import BootstrapButton from "@/components/form/BootstrapButton"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import { useLoading } from "@/composition/useLoading"
import { SystemRole } from "@/enums"
import type { Company } from "@/models"
import { ContractSection, useContractContext } from "@/pages/contracts/context"
import { searchCompanies, type SearchData } from "@/services/companiesService"
import { useLoggedInUser } from "@/stores/loggedInUserStore"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { ref, watch } from "vue"
import { debounce, defineComponent, type ReactiveComponent } from "vue-utils"
import { validateClient } from "../../validation"

const ClientSection: ReactiveComponent = () => {
	const loggedInUser = useLoggedInUser()

	const { runAction } = useLoading()

	const context = useContractContext()

	const contract = proxyProp(context, "contract")

	const searchTerm = ref<string>("")
	const clients = ref<Company[] | null>(null)

	async function getClients() {
		const search = searchTerm.value.trim()
		const supplier = context.supplier

		const searchData: SearchData = {
			search,
			company: supplier,
		}

		if (searchTerm.value) {
			clients.value = search ? await searchCompanies(searchData) : []
		} else {
			clients.value = null
		}
	}

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
		}
	})

	watch(searchTerm, () => void runAction(getClients()))

	function setClient(client: Company) {
		if (context.client && context.client.id == client.id) {
			return
		}
		contract.clientId = client.id
		context.client = client
		context.visitedSections = [ContractSection.Supplier, ContractSection.Client]
	}

	function clearClient() {
		contract.clientId = null
		context.client = null
		context.visitedSections = [ContractSection.Supplier, ContractSection.Client]
	}

	context.validate = () => {
		const errors = validateClient(contract)
		if (errors.length > 0) return errors

		return true
	}

	return () => (
		<>
			{context.client && (
				<>
					<div class="d-flex flex-fill justify-center items-center">
						<Tile class="mb-4 d-flex align-items-center justify-between" style={{ width: "25%", marginRight: 0 }}>
							<div>
								<p class="mb-2" style={{ fontSize: "1.05em" }}>
									Selected Client:
								</p>
								<h4 class="mb-2">{context.client.name}</h4>
							</div>
							<button class="btn btn-close" onClick={clearClient}></button>
						</Tile>
					</div>
				</>
			)}

			{!context.client && (
				<>
					<div class="d-flex flex-fill justify-center">
						<Tile class="mt-4" style={{ width: "45%", marginRight: 0 }}>
							<h3 class="mb-3">Search for a Client</h3>
							<SearchBar search={searchTerm.value} setSearch={updateSearch} />
						</Tile>
					</div>

					{clients.value && (
						<div class="d-flex flex-wrap mt-4" style={{ marginRight: "-1.5em" }}>
							{clients.value.length === 0 && (
								<Tile class="py-4 my-4">
									<h4 class="text-center">No Clients Found</h4>
									{loggedInUser.atLeastHasRole(SystemRole.SuperAdmin) && (
										<div class="d-flex justify-center items-center gap-4">
											<TenetButton url={`/companies/add?name=${searchTerm.value}`}>Add a Company</TenetButton>
											<span class="mt-3 fw-medium fs-5">or</span>
											<TenetButton url={`/companies/?tab=associate`}>Manage Associations</TenetButton>
										</div>
									)}
								</Tile>
							)}

							{clients.value.length > 0 &&
								clients.value.map((client) => (
									<Tile
										key={client.id}
										class={[
											"d-flex justify-between items-center gap-5",
											{ active: context.client && context.client.id == client.id },
										]}
										style={{ flex: "0 1 calc(33.33% - 1.5em)" }}
										onClick={() => setClient(client as Company)}
									>
										{client.name}
										<BootstrapButton color="primary">Select</BootstrapButton>
									</Tile>
								))}
						</div>
					)}
				</>
			)}

			<div class="d-flex justify-between">
				<BootstrapButton
					color="secondary"
					style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
					onClick={() => context.changeSection(ContractSection.Supplier, false)}
				>
					<Icon icon={faArrowLeft} />
					Back
				</BootstrapButton>
				{context.client && (
					<BootstrapButton
						color="primary"
						style={{ fontSize: "1.2em", padding: "0.5em 1em", marginBottom: "1.5rem" }}
						onClick={() => context.changeSection(ContractSection.About, true)}
					>
						Next
						<Icon icon={faArrowRight} />
					</BootstrapButton>
				)}
			</div>
		</>
	)
}

export default defineComponent(ClientSection)
