import Modal from "@/components/Modal"
import SearchBar from "@/components/SearchBar"
import BootstrapButton from "@/components/form/BootstrapButton"
import Tile from "@/components/form/Tile"
import type { Company, Id } from "@/models"
import { ref } from "vue"
import { debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { associateCompanies, type LinkData } from "../../../services/companiesService"
import CompanyAssociations from "./CompanyAssociations"

interface Props {
	companies: Company[]
}

const ManageAssociations: ReactiveComponent<Props> = (props) => {
	const selectedCompany = ref<Company | null>(null)
	const searchTerm = ref<string>("")

	async function associate(companyId: Id, associatedCompanies: Company[]) {
		const linkData: LinkData = {
			companyId,
			associatedCompanyIds: associatedCompanies.map((company) => company.id),
		}
		await associateCompanies(linkData)
		selectedCompany.value = null
	}

	function closePopup() {
		selectedCompany.value = null
	}

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
		}
	})

	return () => (
		<>
			<p class="mb-4">Click 'Manage' on a Company below to edit Company Associations.</p>

			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<div class="d-flex flex-wrap cursor-pointer mt-4" style={{ marginRight: "-1.5em" }}>
				{props.companies
					.filter((company) => company.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((company) => (
						<Tile
							key={company.id}
							class="d-flex justify-between items-center gap-5"
							style={{ flex: "0 1 calc(33.33% - 1.5em)" }}
							onClick={() => (selectedCompany.value = company)}
						>
							{company.name}
							<BootstrapButton color="primary">Manage</BootstrapButton>
						</Tile>
					))}
			</div>

			{selectedCompany.value && (
				<Modal style={{ width: "64rem" }} class="flex flex-col">
					<CompanyAssociations
						selectedCompany={selectedCompany.value as Company}
						companies={props.companies}
						associate={associate}
						close={closePopup}
					/>
				</Modal>
			)}
		</>
	)
}

export default defineComponent(ManageAssociations, {
	companies: requiredProp(Array),
})
