import { mapApiCompany, type Company, type CompanyStats, type Id, type Nullable, type Person } from "@/models"
import { RequestMethod, httpJsonRequestJsonResponse, httpRequestJsonResponse } from "@/services/httpService"
import { convertToFormData } from "@/utils/mapFormData"

export async function getCompanies(): Promise<Company[]> {
	const result = await httpRequestJsonResponse<Company[]>("/api/companies")
	return result.map(mapApiCompany)
}

export async function getCompany(guid: string): Promise<Company> {
	const result = await httpRequestJsonResponse<Company>(`/api/companies/${guid}`)
	return mapApiCompany(result)
}

export async function fetchCompanyByPerson(person: Person): Promise<Company> {
	const result = await httpJsonRequestJsonResponse<Company>(`/api/companies/by-person`, person, RequestMethod.POST)
	return mapApiCompany(result)
}

export async function createCompany(company: Company): Promise<Company> {
	const formData = convertToFormData(company, "companyData")

	if (company.brandingInformation.logoFile) formData.append("files", company.brandingInformation.logoFile)

	const result = await httpRequestJsonResponse<Company>("/api/companies", RequestMethod.POST, {
		body: formData,
	})

	return mapApiCompany(result)
}

export async function updateCompany(company: Company): Promise<Company> {
	const formData = convertToFormData(company, "companyData")

	if (company.brandingInformation.logoFile) formData.append("files", company.brandingInformation.logoFile)

	const result = await httpRequestJsonResponse<Company>("/api/companies", RequestMethod.PUT, {
		body: formData,
	})

	return mapApiCompany(result)
}

export async function associateCompanies(linkData: LinkData) {
	const result = await httpJsonRequestJsonResponse<Company>("/api/companies/associate", linkData, RequestMethod.POST)
	return mapApiCompany(result)
}

export async function searchCompanies(searchData: SearchData): Promise<Company[]> {
	const result = await httpJsonRequestJsonResponse<Company[]>("/api/companies/search", searchData, RequestMethod.POST)
	return result.map(mapApiCompany)
}

export async function getStats() {
	const result = await httpRequestJsonResponse<CompanyStats>("/api/companies/stats")
	return result
}

export interface SearchData {
	search: string
	company: Nullable<Company>
}

export interface LinkData {
	companyId: Id
	associatedCompanyIds: Id[]
}
