import FileUpload from "@/components/form/FileUpload/FileUpload"
import SimpleForm from "@/components/form/SimpleForm"
import type { UploadedFile } from "@/models"
import { uploadAdditionalFiles } from "@/services/contractsService"
import usePopups from "@/stores/popupsStore"
import { shallowRef } from "vue"
import { Alert, ModalTitle, defineComponent, refSetter, requiredProp, type ReactiveComponent } from "vue-utils"
import { useContractContext } from "../../context"

interface Props {
	cancel(): void
	onUploaded(newFiles: UploadedFile[]): void
}

const UploadAdditionalFiles: ReactiveComponent<Props> = (props) => {
	const uploadingFiles = shallowRef<File[]>([])
	const context = useContractContext()
	const popups = usePopups()

	async function validateForm() {
		return await popups.confirm(() => (
			<Alert title="Confirm File Upload">
				<p>
					You are about to upload {uploadingFiles.value.length} file{uploadingFiles.value.length !== 1 && "s"} to "
					{context.contract.name}".
					<br />
					Are you sure you want to do this?
				</p>
			</Alert>
		))
	}

	async function submit() {
		const newFiles = await uploadAdditionalFiles(context.contract, uploadingFiles.value)
		props.onUploaded(newFiles)
	}

	return () => (
		<>
			<ModalTitle title="Upload Additional Files" />
			<hr />
			<SimpleForm
				class="display-contents"
				submit={{
					text: "Upload",
					onClick: submit,
					validate: validateForm,
					buttonProps: { disabled: uploadingFiles.value.length === 0 },
				}}
				cancel={props.cancel}
			>
				<FileUpload class="flex-grow-1 mb-3" onSelectionChanged={refSetter(uploadingFiles)} multiple />
			</SimpleForm>
		</>
	)
}

export default defineComponent(UploadAdditionalFiles, {
	cancel: requiredProp(Function),
	onUploaded: requiredProp(Function),
})
