import type { ApiTypeOf } from "./ApiType"
import type { GenericEntity, INamed } from "./GenericEntity"
import { mapApiUserTimeStamp, type UserTimeStamp } from "./UserTimeStamp"

export interface UploadedFile extends GenericEntity, INamed {
	mimeType: string
	guid: string
	fileSize: number
	uploaded: UserTimeStamp
	fileData: string
}

export function mapApiUploadedFile(uploadedFile: ApiTypeOf<UploadedFile>): UploadedFile {
	return {
		...uploadedFile,
		uploaded: mapApiUserTimeStamp(uploadedFile.uploaded),
	}
}
