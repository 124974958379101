import type { FunctionalComponent } from "vue"
import type { ErrorComponentProps } from "vue-utils"
import ErrorMessage from "../ErrorMessage"
import BootstrapButton from "../form/BootstrapButton"

export const BasicErrorPage: FunctionalComponent<ErrorComponentProps> = ({ reload, error }) => (
	<div class="inline-flex flex-col" style="padding: 0.5rem">
		<ErrorMessage text="Error Loading System Data" />
		<br />
		<div>An unexpected error occurred trying to load system data.</div>
		<div>Please check your internet connectivity and reload, or contact an administrator</div>

		<div>
			<BootstrapButton class="my-3" color="primary" onClick={() => void reload()}>
				<span style="padding: 0.25rem 1.5rem">Reload Page Content</span>
			</BootstrapButton>
		</div>

		<small style="color: var(--color-info)">Error Details: {error.message}</small>
	</div>
)
