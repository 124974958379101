import { httpJsonRequestJsonResponse } from "@/services/httpService"
import { defineGlobals } from "vue-utils"

export async function generateDownloadLink(fileGuid: string): Promise<string> {
	return await httpJsonRequestJsonResponse<string>("/api/download/generate-link", fileGuid)
}

export async function downloadFile(fileGuid: string) {
	const downloadLink = await generateDownloadLink(fileGuid)
	window.open(downloadLink, "_blank", "noopener noreferrer")
}

defineGlobals({
	downloadFile,
})
