import type { Company, Contract, Nullable, Person } from "@/models"
import { reactive, shallowReactive, type InjectionKey } from "vue"
import { useService, type ValidationResult } from "vue-utils"

export enum ContractSection {
	Supplier = "Select a Supplier",
	Client = "Select a Client",
	About = "About the Contract",
	Stakeholders = "Stakeholders",
	Milestones = "Milestones",
	Review = "Review",
	Responses = "Responses",
}

export type ContractEntryType = "create" | "edit"

export const ContractContextInjectionKey = Symbol("ContractContext") as InjectionKey<ContractContext>

export function useContractContext(): ContractContext {
	return useService(ContractContextInjectionKey)
}

export class ContractContext {
	private data = shallowReactive({
		section: ContractSection.Supplier,
		visitedSections: [] as string[],
		showSupplierSection: true,
		showClientSection: true,
		client: null as Nullable<Company>,
		supplier: null as Nullable<Company>,
		stakeholders: null as Nullable<Person[]>,
		errorMessages: [] as string[],
		validate: (): ValidationResult => {
			return true
		},
	})

	private lastErrors: string[] = []

	constructor(
		public readonly contract: Contract,
		public readonly entryType: ContractEntryType
	) {
		if (contract.supplierId) {
			this.data.showSupplierSection = false
		}
		if (contract.clientId) {
			this.data.showClientSection = false
		}

		this.data.client = contract.client
		this.data.supplier = contract.supplier
		this.data.stakeholders = contract.stakeholders

		this.contract = shallowReactive(contract)
		this.contract.milestones = Object.assign(this.contract.milestones, reactive(this.contract.milestones))
	}

	get section(): ContractSection {
		return this.data.section
	}
	set section(section: ContractSection) {
		this.data.section = section
	}

	get visitedSections(): string[] {
		return this.data.visitedSections
	}
	set visitedSections(visitedSections: string[]) {
		this.data.visitedSections = visitedSections
	}

	get showSupplierSection(): boolean {
		return this.data.showSupplierSection
	}

	get showClientSection(): boolean {
		return this.data.showClientSection
	}

	get client(): Nullable<Company> {
		return this.data.client
	}

	set client(client: Nullable<Company>) {
		this.data.client = client
	}

	get supplier(): Nullable<Company> {
		return this.data.supplier
	}

	set supplier(supplier: Nullable<Company>) {
		this.data.supplier = supplier
	}

	get stakeholders(): Nullable<Person[]> {
		return this.data.stakeholders
	}

	set stakeholders(stakeholders: Nullable<Person[]>) {
		this.data.stakeholders = stakeholders
	}

	get errorMessages(): string[] {
		return this.data.errorMessages
	}

	set errorMessages(messages: string[]) {
		this.data.errorMessages = messages
	}

	set validate(validateFunction: () => ValidationResult) {
		this.data.validate = validateFunction
	}

	validateSection() {
		const validationResult = this.data.validate()
		return this.parseValidationResult(validationResult)
	}

	getLastErrors() {
		return this.lastErrors
	}

	parseValidationResult(validationResult: ValidationResult): boolean {
		this.closeErrors()

		if (validationResult == true) return true

		if (Array.isArray(validationResult)) {
			this.data.errorMessages = validationResult
		} else if (typeof validationResult === "string") {
			this.data.errorMessages.push(validationResult)
		}
		this.lastErrors = this.data.errorMessages

		return false
	}

	closeErrors() {
		this.data.errorMessages = []
	}

	changeSection(newSection: ContractSection, validate: boolean) {
		if (validate && !this.validateSection()) {
			return
		}

		this.data.section = newSection
	}

	addVisitedSection(visitedSection: string) {
		if (!this.data.visitedSections.includes(visitedSection)) {
			this.data.visitedSections.push(visitedSection)
		}
	}
}
