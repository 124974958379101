import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import ColumnTable from "@/components/table/ColumnTable"
import { useTablePaging } from "@/composition/useTablePaging"
import type { User } from "@/models"
import { formatEnum } from "@/utils/formatEnum"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { ref, toRef } from "vue"
import { debounce, defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"
import { useUsersFiltering } from "./filtering"

interface Props {
	users: User[]
}

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const UsersTable: ReactiveComponent<Props> = (props) => {
	const searchTerm = ref<string>("")

	const filteredUsers = useUsersFiltering({
		users: toRef(props, "users"),
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredUsers)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<ColumnTable
				getKey={(user) => user.id}
				class={["mt-3", tableStyles]}
				entities={pageItems.value}
				columns={{
					name: {
						label: "Name",
						size: "auto",
						renderContent: ({ item: user }) => `${user.firstName} ${user.lastName}`,
					},
					email: {
						label: "Email",
						size: "auto",
						renderContent: ({ item: user }) => user.emailAddress,
					},
					role: {
						label: "Role",
						size: "auto",
						renderContent: ({ item: user }) => formatEnum(user.role),
					},
					controls: {
						label: "",
						size: "max-content",
						renderContent: ({ item: user }) => (
							<a href={`/users/edit/${user.guid}`} class="btn btn-secondary">
								<Icon icon={faEdit} /> <span>Edit</span>
							</a>
						),
					},
				}}
			/>

			<PagingControls entityName="Users" />
		</>
	)
}

export default defineComponent(UsersTable, {
	users: requiredProp(Array),
})
