import Icon from "@/components/Icon"
import SearchBar from "@/components/SearchBar"
import Blade, { closeBlade, openBlade } from "@/components/form/Blade"
import BootstrapButton from "@/components/form/BootstrapButton"
import SimpleForm from "@/components/form/SimpleForm"
import TenetButton from "@/components/form/TenetButton"
import Tile from "@/components/form/Tile"
import ColumnTable from "@/components/table/ColumnTable"
import { useGenericFiltering } from "@/composition/useGenericFiltering"
import { useTablePaging } from "@/composition/useTablePaging"
import { mapApiValue, type Nullable, type Value } from "@/models"
import { createValue, updateValue } from "@/services/valuesService"
import { proxyProp } from "@/utils/proxyProp"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { css } from "vite-css-in-js"
import { Teleport, ref } from "vue"
import { Required, debounce, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"
import Industries from "./Industries"

const tableStyles = css`
	th:last-child,
	td:last-child {
		padding: 0.25rem;
		justify-content: end;

		button {
			font-size: 0.92rem;
		}
	}
`

const CompanyValues: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	const searchTerm = ref<string>("")

	const editingValue = ref<Nullable<Value>>(null)

	const filteredValues = useGenericFiltering({
		items: company.values ?? [],
		searchTerm,
	})

	const { page, pageItems, PagingControls } = useTablePaging(filteredValues)

	const updateSearch = debounce((newSearch: string) => {
		if (searchTerm.value !== newSearch) {
			searchTerm.value = newSearch
			page.value = 1
		}
	})

	const newValue: Value = {
		id: 0,
		name: "",
		description: "",
		industries: [],
		companyId: company.id,
		active: true,
	}

	function editValue(valueId: Nullable<number> = null) {
		const value = company.values?.find((value) => value.id === valueId) ?? newValue
		editingValue.value = mapApiValue(value)
		openBlade()
	}

	async function saveValue(value: Value) {
		if (value.id == 0) {
			await createValue(value)
		} else {
			await updateValue(value)
		}

		closeBlade()
		location.reload()
	}

	return () => (
		<>
			<SearchBar search={searchTerm.value} setSearch={updateSearch} />

			<Tile class="mt-3">
				{company.values && company.values.length > 0 && (
					<>
						<ColumnTable
							getKey={(value) => value.id}
							class={["mt-3", tableStyles]}
							entities={pageItems.value}
							columns={{
								name: {
									label: "Name",
									size: "auto",
									renderContent: ({ item: value }) => value.name,
								},
								description: {
									label: "Description",
									size: "auto",
									renderContent: ({ item: value }) => value.description,
								},
								industries: {
									label: "Industries",
									size: "auto",
									renderContent: ({ item: value }) =>
										value.industries.length > 0
											? value.industries.map((industry) => industry.name).join(", ")
											: "None Selected",
								},
								active: {
									label: "Active",
									size: "auto",
									renderContent: ({ item: value }) => (value.active ? "Yes" : "No"),
								},
								controls: {
									label: "",
									size: "max-content",
									renderContent: ({ item: value }) => (
										<BootstrapButton color="primary" onClick={() => editValue(value.id)}>
											<Icon icon={faEdit} /> <span>Edit</span>
										</BootstrapButton>
									),
								},
							}}
						/>

						<PagingControls entityName="Values" />
					</>
				)}

				{(!company.values || company.values.length === 0) && (
					<div class="my-4">
						<h4 class="text-center">No Values Found</h4>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<TenetButton onClick={() => editValue()}>Add a Value</TenetButton>
						</div>
					</div>
				)}
			</Tile>

			{company.values && company.values.length > 0 && (
				<div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
					<TenetButton onClick={() => editValue()}>Add a Value</TenetButton>
				</div>
			)}

			<Teleport to="body">
				<Blade>
					{editingValue.value && (
						<>
							<h2>{editingValue.value.id > 0 ? "Edit" : "Add"} Value</h2>

							<SimpleForm
								submit={{
									text: `${editingValue.value.id > 0 ? "Save" : "Add"} Value`,
									onClick: () => saveValue(editingValue.value as Value),
								}}
								style={{ marginTop: "2rem" }}
							>
								<Tile>
									<label for="valueName" class="tile-heading">
										<Required label="Name" />
									</label>
									<input
										type="text"
										id="valueName"
										v-model={editingValue.value.name}
										placeholder="Value Name"
										class="form-control"
										required
									/>
								</Tile>

								<Tile>
									<label for="valueDescription" class="tile-heading">
										<Required label="Description" />
									</label>
									<textarea
										id="valueDescription"
										v-model={editingValue.value.description}
										placeholder="Description"
										class="form-control"
										rows={6}
										required
									/>
								</Tile>

								<Industries value={editingValue.value} />

								<Tile>
									<label for="valueActive" class="tile-heading">
										Active
									</label>
									<label class="checkbox">
										<input type="checkbox" id="valueActive" v-model={editingValue.value.active} />
										<span>{editingValue.value.active ? "Yes" : "No"}</span>
									</label>
								</Tile>
							</SimpleForm>
						</>
					)}
				</Blade>
			</Teleport>
		</>
	)
}

export default defineComponent(CompanyValues)
