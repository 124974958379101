import type { Company } from "@/models"
import { useIndustries } from "@/stores/industriesStore"
import { shallowReactive, type InjectionKey } from "vue"
import { useService } from "vue-utils"

export const CompanyContextInjectionKey = Symbol("CompanyContext") as InjectionKey<CompanyContext>

export function useCompanyContext(): CompanyContext {
	return useService(CompanyContextInjectionKey)
}

export class CompanyContext {
	public industries = useIndustries()

	private data = shallowReactive({
		editingCompany: false,
	})

	constructor(public readonly company: Company) {
		this.company = shallowReactive(company)
	}

	get edit(): boolean {
		return this.data.editingCompany
	}
	set edit(state: boolean) {
		this.data.editingCompany = state
	}
}
