import Tile from "@/components/form/Tile"
import { proxyProp } from "@/utils/proxyProp"
import { Required, defineComponent, type ReactiveComponent } from "vue-utils"
import { useCompanyContext } from "../context"

const PrimaryContact: ReactiveComponent = () => {
	const context = useCompanyContext()
	const company = proxyProp(context, "company")

	return () => (
		<Tile>
			<label for="companyPrimaryContact" class="tile-heading">
				<Required label="Primary Contact" />
			</label>
			<input
				type="text"
				id="companyPrimaryContact"
				v-model={company.primaryContact}
				placeholder="Primary Contact"
				class="form-control"
				required
			/>
		</Tile>
	)
}

export default defineComponent(PrimaryContact)
