import { library, type IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import type { DefineComponent, SVGAttributes } from "vue"
import type { Component } from "vue-utils"

type ExtractProps<T> = T extends DefineComponent<infer P> ? P : never

type FontAwesomeIconProps = Omit<ExtractProps<typeof FontAwesomeIcon>, "icon"> & {
	icon: IconDefinition
}

const registeredIcons = new Set<string>()

const Icon: Component<FontAwesomeIconProps, SVGAttributes> = (props) => {
	if (!registeredIcons.has(props.icon.iconName)) {
		registeredIcons.add(props.icon.iconName)
		library.add(props.icon)
	}

	return <FontAwesomeIcon {...props} />
}

export default Icon
