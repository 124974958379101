import Icon from "@/components/Icon"
import { showErrorMessage } from "@/composition/useLoading"
import { submitSurvey } from "@/services/surveyService"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons"
import { Transition, ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { SurveySection, useSurveyContext } from "../context"

const SurveyOutro: ReactiveComponent = () => {
	const context = useSurveyContext()

	const survey = proxyProp(context, "survey")

	const submitting = ref(false)
	const completed = ref(false)

	async function complete(): Promise<void> {
		try {
			submitting.value = true

			await submitSurvey(survey)

			completed.value = true
		} catch (err) {
			console.error(err)
			await showErrorMessage(
				"Failed to complete",
				"Unable to complete this response. Please try again later or contact an administrator"
			)
		} finally {
			submitting.value = false
		}
	}

	return () => (
		<section>
			<div class="section-inner">
				<div class="position-relative" style={{ marginBottom: "10rem" }}>
					<Transition>
						{!completed.value && (
							<div class="position-absolute">
								<h1>Thanks!</h1>
								<p>Click the button below to complete the survey.</p>
							</div>
						)}
					</Transition>

					<Transition>
						{completed.value && (
							<div class="position-absolute">
								<h1>Survey Complete</h1>
								<p>Thank you for your time.</p>
							</div>
						)}
					</Transition>
				</div>

				<div class="mt-5 position-relative" style={{ padding: "4rem 0" }}>
					<Transition>
						{!submitting.value && !completed.value && (
							<button
								class="survey-button"
								onClick={() => {
									context.sectionTransitionName = "slideLeft"
									context.section = SurveySection.Values
								}}
							>
								<span class="icon">
									<Icon icon={faArrowLeft} />
								</span>
								<span class="text">Back</span>
							</button>
						)}
					</Transition>

					<Transition>
						{!completed.value && (
							<button
								class="survey-button"
								onClick={() => void complete()}
								disabled={submitting.value}
								style={{ left: "auto", right: "0" }}
							>
								<span class="text">Complete</span>
								<span class="icon">
									{submitting.value ? (
										<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
									) : (
										<Icon icon={faCheck} />
									)}
								</span>
							</button>
						)}
					</Transition>
				</div>
			</div>
		</section>
	)
}

export default defineComponent(SurveyOutro)
