import logo from "@/assets/logo.svg"

import { PublicContentLoading } from "@/components/loading/StateLoading"
import { type Survey } from "@/models"
import { getSurvey } from "@/services/surveyService"
import { LocalDate } from "@js-joda/core"
import { css } from "vite-css-in-js"
import { computed, reactive } from "vue"
import { useRouter } from "vue-router"
import { defineComponent, useLoadableRef, type ReactiveComponent } from "vue-utils"
import { SurveyError } from "./SurveyError"
import SurveySections from "./sections/"

const pageStyles = css`
	header {
		height: 6em;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--color-white);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 2;
		border-bottom: thin solid var(--color-tenet-darkgrey);
		box-shadow: 0 4px 20px 4px rgba(var(--color-tenet-darkgrey-rgb), 0.3);

		.logo {
			max-width: 200px;

			img {
				width: 100%;
			}
		}
	}

	main {
		padding: 6.5em 0 3.5em;
		min-height: 100vh;
		display: grid;
		place-content: center;
		font-size: 1.5em;
		position: absolute;
		inset: 0;
		overflow: hidden;

		&::before {
			content: "";
			position: absolute;
			inset: 0;
			background: radial-gradient(
				circle,
				rgba(var(--color-fill-rgb), 1) 0%,
				rgba(var(--color-fill-darker-rgb), 1) 100%
			);
			right: -100rem;
			top: -55rem;
		}
	}

	footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--color-white);
		height: 3em;
		font-weight: 400;
		z-index: 2;
		border-top: thin solid var(--color-tenet-darkgrey);
		box-shadow: 0 -4px 20px 4px rgba(var(--color-tenet-darkgrey-rgb), 0.3);
	}
`

const SurveyPage: ReactiveComponent = () => {
	const router = useRouter()
	const linkReference = computed(() => router.currentRoute.value.params.reference as string)

	const surveyRef = useLoadableRef(async () => {
		const survey = await getSurvey(linkReference.value)
		return reactive(survey)
	})

	function renderContent() {
		if (surveyRef.type !== "done") {
			return null
		}

		const survey = surveyRef.result as Survey

		return <SurveySections survey={survey} />
	}

	return () => (
		<div class={pageStyles}>
			<header>
				<div class="logo">
					<img src={logo} alt="Tenet" />
				</div>
			</header>
			<main>
				<PublicContentLoading stores={[surveyRef]} ErrorComponent={SurveyError}>
					{renderContent()}
				</PublicContentLoading>
			</main>
			<footer>&copy; Tenet Law {LocalDate.now().year()}</footer>
		</div>
	)
}

export default defineComponent(SurveyPage)
