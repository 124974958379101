import { Instant } from "@js-joda/core"
import {
	mapApiContract,
	mapApiMilestone,
	type ApiTypeOf,
	type Contract,
	type GenericEntity,
	type Milestone,
	type Nullable,
} from "."

export interface Survey extends GenericEntity {
	reference: string
	created: Instant
	sent: Nullable<Instant>
	submitted: Nullable<Instant>
	milestone: Milestone
	contract: Nullable<Contract>
}

export function mapApiSurvey(survey: ApiTypeOf<Survey>): Survey {
	return {
		...survey,
		created: Instant.parse(survey.created),
		sent: survey.sent ? Instant.parse(survey.sent) : null,
		submitted: survey.submitted ? Instant.parse(survey.submitted) : null,
		milestone: mapApiMilestone(survey.milestone),
		contract: survey.contract && mapApiContract(survey.contract),
	}
}
