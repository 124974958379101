import type { ApiTypeOf } from "./ApiType"
import type { GenericEntity, INamed, Id } from "./GenericEntity"
import { mapApiIndustry, type Industry } from "./Industry"

export interface Value extends GenericEntity, INamed {
	description: string
	active: boolean
	companyId: Id

	industries: Industry[]
}

export function mapApiValue(value: ApiTypeOf<Value>): Value {
	return {
		...value,
		industries: value.industries.map(mapApiIndustry),
	}
}
