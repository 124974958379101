import Icon from "@/components/Icon"
import { proxyProp } from "@/utils/proxyProp"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { SurveySection, useSurveyContext } from "../context"

const SurveyIntro: ReactiveComponent = () => {
	const context = useSurveyContext()

	const survey = proxyProp(context, "survey")
	const milestone = survey.milestone
	const contract = survey.contract!

	return () => (
		<section>
			<div class="section-inner">
				<h1>{milestone.name}</h1>
				<p>
					<strong>{contract.supplier!.name}</strong> has requested that you submit the following survey relating to its
					contract with <strong>{contract.client!.name}</strong> <em>({contract.name})</em>.
				</p>
				<p>
					Please answer each statement by moving the slider to rate the value.
					<br />
					You will then need to state how confident you are about your rating.
				</p>
				<div class="mt-5 position-relative" style={{ padding: "4rem 0" }}>
					<button
						class="survey-button"
						onClick={() => {
							context.sectionTransitionName = "slideRight"
							context.section = SurveySection.Values
						}}
					>
						<span class="text">Click here to begin</span>
						<span class="icon">
							<Icon icon={faArrowRight} />
						</span>
					</button>
				</div>
			</div>
		</section>
	)
}

export default defineComponent(SurveyIntro)
