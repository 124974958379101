import Icon from "@/components/Icon"
import BootstrapButton from "@/components/form/BootstrapButton"
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons"
import { defineComponent, requiredProp, type ReactiveComponent } from "vue-utils"

interface Props {
	logout(): () => void
}

const LogoutButton: ReactiveComponent<Props> = (props) => {
	return () => (
		<BootstrapButton color="danger" onClick={props.logout()}>
			<Icon icon={faArrowRightFromBracket} />
			<span>Log Out</span>
		</BootstrapButton>
	)
}

export default defineComponent(LogoutButton, {
	logout: requiredProp(Function),
})
