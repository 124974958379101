import backgroundImage from "@/assets/background.webp"
import logo from "@/assets/logo_white.svg"
import { css } from "vite-css-in-js"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import LoginButton from "./LoginButton"

const backgroundStyles = css`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	& > * {
		z-index: 1;
	}

	& > div:nth-child(1) {
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;
		position: absolute;
		inset: 0;
	}
`

const loginPanelStyles = css`
	width: 100%;
	background: var(--color-tenet-blue);
	color: var(--color-white);
	box-shadow: -10px 0 20px 10px rgba(var(--color-tenet-darkgrey-rgb), 0.2);
	padding: 8em clamp(35px, 7vw, 100px);
	position: absolute;
	inset: 0;
	display: flex;
	flex-direction: column;
	gap: 22%;

	.logo {
		padding: 0 1em;
		text-align: center;

		img {
			min-width: 170px;
			max-width: 250px;
		}
	}

	.login-text {
		display: flex;
		flex-wrap: wrap;
		//justify-content: center;
		gap: 1em;

		& > * {
			flex-basis: 100%;
		}

		h1 {
			font-weight: 300 !important;
		}

		p {
			font-size: 1.2em;
		}

		.btn {
			margin-top: 1em;
			//flex-basis: auto;
			font-size: 1.4em;
			text-transform: uppercase;
			background-color: transparent;
			border-color: var(--color-tenet-orange);
			padding: 0.3em 3em;
			box-shadow: 0 2px 20px 4px rgba(var(--color-tenet-black-rgb), 0.35);

			&:hover {
				background-color: #313a42;
				border-color: #bc500e;
			}
		}
	}

	@media only screen and (min-width: 600px) {
		width: clamp(390px, 45vw, 750px);
		left: auto;
	}
`

const LoginPage: ReactiveComponent = () => {
	return () => (
		<div class={backgroundStyles}>
			<div style={{ backgroundImage: `url(${backgroundImage})` }}></div>
			<div class={loginPanelStyles}>
				<div class="logo">
					<img src={logo} alt="Tenetech" />
				</div>
				<div class="login-text">
					<h1>Welcome to Tenetech</h1>
					<p>Please log in to the system by clicking the button below</p>
					<LoginButton />
				</div>
			</div>
		</div>
	)
}

export default defineComponent(LoginPage)
